import React from "react";
import GenericButton, {EButtonColor, EButtonIcons, EButtonStyle} from "../button/GenericButton";

interface IFormFooterProps {
    onClickUpdate: () => void,
    onClickDelete: () => void,
    isDisabledOnDelete?: boolean,
    isDisabledOnUpdate?: boolean,
    labelOnDelete?: string,
    labelOnUpdate?: string,
}

const FormFooter = (props: IFormFooterProps) => {
    return (
        <div className="flex justify-content-between flex-row ">
            <div>
                <GenericButton label={props.labelOnDelete ?? "Obrisi"} type="submit" icon={EButtonIcons.IKS}
                               onClick={props.onClickDelete}
                               disabled={props.isDisabledOnDelete ?? false}
                               color={EButtonColor.MILK_BLUE} styled={EButtonStyle.TRANSPOSE}/>
            </div>
            <div>
                <GenericButton label={props.labelOnUpdate ?? "Pohrani"} type="submit" icon={EButtonIcons.CHECK}
                               onClick={props.onClickUpdate}
                               disabled={props.isDisabledOnUpdate ?? false}
                               color={EButtonColor.LUMINUM_BLUE} styled={EButtonStyle.TRANSPOSE}/>
            </div>
        </div>
    );
}
export default FormFooter;