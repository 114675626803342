import {FormikErrors, useFormik} from "formik";
import {Calendar} from "primereact/calendar";
import {Divider} from "primereact/divider";
import {Dropdown} from "primereact/dropdown";
import {InputNumber} from "primereact/inputnumber";
import {InputText} from "primereact/inputtext";
import {MultiSelect} from "primereact/multiselect";
import {classNames} from "primereact/utils";
import {useEffect, useState} from "react";
import Employee, {makeEmployee, revertEmployee} from "../../../models/Employee";
import Holiday from "../../../models/Holiday";
import LdapAttribute from "../../../models/LdapAttribute";
import Project from "../../../models/Project";
import {getAllHolidays, getAllManagers, getAllProjects} from "../../../shared/api/GetFormData";
import {create} from "../../../shared/api/endpoints/CrudApi";
import {getAllValuesForAttribute} from "../../../shared/api/endpoints/CustomApi";
import DialogFooter from "../../../shared/components/generic/dialog/DialogFooter";
import {dateTemplate} from "../absence-request/AbsenceRequestAssistance";
import {checkEMail} from "../../../shared/utils/ValidationHelpers";
import {getLuminumEmail} from "../../../shared/utils/StringUtils";

interface IEmployeeFormProps {
    hide: () => void,
    initialValues: Employee
    addNewDataToTable: React.Dispatch<any>
}

function EmployeeForm(props: IEmployeeFormProps) {

    const [employee, setEmployee] = useState<Employee>(
        makeEmployee(props.initialValues ?? undefined)
    )
    const [holidays, setHolidays] = useState<Holiday[]>();
    const [selectedProjects, setSelectedProjects] = useState<Project[] | undefined>(props.initialValues ? props.initialValues.projectsWithGrant : undefined);
    const [allProjects, setAllProjects] = useState<Project[]>();
    const [selectedManagers, setSelectedManagers] = useState<Employee[] | undefined>(props.initialValues ? props.initialValues.employeeLdap?.managers : undefined);
    const [managers, setManagers] = useState<Employee[]>();
    const [descriptionLdap, setDescriptionLdap] = useState<LdapAttribute>();
    const [departmentNumberLdap, setDepartmentNumberLdap] = useState<LdapAttribute>();

    useEffect(() => {

        (async () => {
            setAllProjects(await getAllProjects());
        })();
        getAllHolidays().then((res) => {
            setHolidays(res);
        });
        (async () => {
            setManagers(await getAllManagers());
        })();
        (async () => {
            setDescriptionLdap(await getAllValuesForAttribute('description'));
        })();
        (async () => {
            setDepartmentNumberLdap(await getAllValuesForAttribute('departmentNumber'));
        })();

    }, [])

    useEffect(() => {
        if (formik.values.projectsWithGrant !== undefined) {
            formik.setFieldValue('projects', formik.values.projectsWithGrant);
        }
    }, [allProjects])

    const formik = useFormik({
        initialValues: employee!,
        validate: (values: Employee) => {
            let errors: FormikErrors<Employee> = {};

            if (!values.firstName || values.firstName === '') {
                errors.firstName = 'Ime je obavezno polje.';
            }

            if (!values.lastName || values.lastName === '') {
                errors.lastName = 'Prezime je obavezno polje.';
            }

            if (!values.email || values.email === '') {
                errors.email = 'Email je obavezno polje.'
            } else {
                checkEMail(values, errors);
            }

            if (!values.managers) {
                errors.managers = 'Mora biti odabran barem jedan nadređeni zaposlenik'
            }

            if (!values.descriptionAttribute) {
                errors.descriptionAttribute = 'Radni odnos je obavezno polje'
            }

            if (!values.departmentNumberAttribute) {
                errors.departmentNumberAttribute = 'Pozicija zaposlenika je obavezno polje'
            }

            return errors;
        },
        onSubmit:
            async (values: Employee) => {
                values = revertEmployee(values);
                await create(`admin/employee`, revertEmployee(makeEmployee(values))).then(res => props.addNewDataToTable(makeEmployee(res.data)));
                formik.resetForm();

                props.hide();

            },
    });

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };


    function createEmail() {
        if(formik.values.firstName !== undefined && formik.values.lastName !== undefined) {
            let firstName = formik.values.firstName ?? "";
            let lastName = formik.values.lastName ?? "";

            if (firstName.includes(' ')) {
                firstName = firstName.replaceAll(' ', '.');
            }
            if (lastName.includes(' ')) {
                lastName = lastName.replaceAll(' ', '.');
            }
            let email = getLuminumEmail(firstName, lastName);

            if (email.includes("š")) {
                email = email.replaceAll('š', 's');
            }
            if (email.includes("č") || email.includes("ć")) {
                email = email.replaceAll('č', 'c');
                email = email.replaceAll('ć', 'c');
            }
            if (email.includes("ž")) {
                email = email.replaceAll('ž', 'z');
            }

            return email.toLocaleLowerCase();
        }
    }

    useEffect(() => {
        if (formik.values.firstName !== undefined && formik.values.firstName !== ''
            && formik.values.lastName !== undefined && formik.values.lastName !== '') {
            formik.setFieldValue('email', createEmail());
        } else if(formik.values.firstName === '' || formik.values.lastName === ''){
            formik.setFieldValue('email', '');
        } else if(formik.values.firstName !== '' || formik.values.lastName !== ''){
            formik.setFieldValue('email', createEmail());
        }
    }, [formik.values.firstName, formik.values.lastName])

    return (
        <form>
            <div>
                <div style={{ border: "transparent" }}>
                    <div className="p-fluid grid" style={{ paddingTop: '20px' }}>

                        <div className="field col-6">
                            <span className="p-float-label">
                                <InputText id="firstName" name="firstName"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange} />
                                <label htmlFor="firstName"
                                    className={classNames({ 'p-error': isFormFieldValid('firstName') })}>Ime</label>
                            </span>
                            {getFormErrorMessage('firstName')}
                        </div>

                        <div className="field col-6">
                            <span className="p-float-label">
                                <InputText id="lastName" name="lastName"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange} />
                                <label htmlFor="lastName"
                                    className={classNames({ 'p-error': isFormFieldValid('lastName') })}>Prezime</label>
                            </span>
                            {getFormErrorMessage('lastName')}
                        </div>

                        <div className="field col-6">
                            <span className="p-float-label">
                                <InputText id="email" name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange} />
                                <label htmlFor="email"
                                    className={classNames({ 'p-error': isFormFieldValid('email') })}>Email</label>
                            </span>
                            {getFormErrorMessage('email')}
                        </div>

                        <div className="field col-6">
                            <span className="p-float-label">
                                <InputText id="phoneNumber" name="phoneNumber"
                                    value={formik.values.phoneNumber}

                                    onChange={formik.handleChange} />
                                <label htmlFor="phoneNumber"
                                    className={classNames({ 'p-error': isFormFieldValid('phoneNumber') })}>Broj telefona</label>
                            </span>
                        </div>

                        <div className="field col-6">
                            <span className="p-float-label">
                                    <Dropdown id="descriptionAttribute" value={formik.values.descriptionAttribute} options={descriptionLdap?.attributeValuesEnum}
                                        onChange={(e) => {
                                            formik.setFieldValue('descriptionAttribute', e.value);
                                        }}
                                        dataKey="name" optionLabel="name" />
                                <label htmlFor="descriptionAttribute"
                                    className={classNames({ 'p-error': isFormFieldValid('descriptionAttribute') })}>Radni odnos</label>
                            </span>
                            {getFormErrorMessage('descriptionAttribute')}
                        </div>

                        <div className="field col-6">
                            <span className="p-float-label">
                                    <Dropdown id="departmentNumberAttribute" value={formik.values.departmentNumberAttribute} options={departmentNumberLdap?.attributeValuesEnum}
                                        onChange={(e) => {
                                            formik.setFieldValue('departmentNumberAttribute', e.value);
                                        }}
                                        dataKey="name" optionLabel="name" />
                                <label htmlFor="departmentNumberAttribute"
                                    className={classNames({ 'p-error': isFormFieldValid('departmentNumberAttribute') })}>Pozicija zaposlenika</label>
                            </span>
                            {getFormErrorMessage('departmentNumberAttribute')}
                        </div>

                        <Divider />

                        <div className="field col-6">
                            <span className="p-float-label">
                                <InputNumber id="availableDays" name="availableDays"
                                             min={0}
                                             value={formik.values.currentYearVacationDays?.currentAvailableDays}
                                             onChange={(e) => formik.setFieldValue('availableDays', e.value)} />
                                <label
                                    htmlFor="availableDays">Dani godišnjeg {formik.values.currentYear ? ('za godinu ' + formik.values.currentYear) : ''}</label>
                            </span>
                            {getFormErrorMessage('availableDays')}

                        </div>

                        <Divider />

                        <div className="field col-6 md:col-6">
                            <span className="p-float-label">
                                <Calendar showButtonBar name="startDateToShowOnForm"
                                    value={formik.values.validFromToShowOnForm}
                                    onChange={(e) => {
                                        formik.setFieldValue('validFromToShowOnForm', e.value);
                                        formik.setFieldValue('valueFrom', formik.values.validFromToShowOnForm);
                                    }}
                                    dateFormat="dd.mm.yy." id="calendarFrom"
                                    dateTemplate={(e) => dateTemplate(e, holidays)}
                                    disabledDays={[0, 6]} />
                                <label htmlFor="calendarFrom">Zaposlen od</label>
                            </span>
                        </div>
                        <div className="field col-6 md:col-6">
                            <span className="p-float-label">
                                <Calendar showButtonBar name="endDateToShowOnForm" value={formik.values.validToToShowOnForm}
                                    onChange={(e) => {
                                        formik.setFieldValue('validToToShowOnForm', e.value);
                                        formik.setFieldValue('valueTo', formik.values.validToToShowOnForm);
                                    }}
                                    dateFormat="dd.mm.yy." id="calendarTo"
                                    dateTemplate={(e) => dateTemplate(e, holidays)}
                                    disabledDays={[0, 6]} />
                                <label htmlFor="calendarTo">Zaposlen do</label>
                            </span>
                        </div>
                        <Divider />
                        <div className="field col-12">
                            <span className="p-float-label">
                                <MultiSelect id="managers"
                                    value={selectedManagers}
                                    options={managers}
                                    filter
                                    onChange={(e) => {
                                        let all = formik.values.employeeLdap?.managers;
                                        all = [...e.value];
                                        setSelectedManagers(all);
                                        formik.setFieldValue("managers", all);
                                    }}
                                    dataKey="displayName"
                                    optionLabel="displayName" />
                                <label htmlFor="managers">Nadređeni zaposlenici</label>
                            </span>
                            {getFormErrorMessage('managers')}
                        </div>
                        <div className="field col-12 md:col-12">
                            <span className="p-float-label">
                                <MultiSelect id="projectsWithGrant"
                                    value={selectedProjects}
                                    options={allProjects} filter
                                    onChange={(e) => {
                                        let all = formik.values.projectsWithGrant;
                                        all = [...e.value];
                                        setSelectedProjects(all);
                                        formik.setFieldValue("projectsWithGrant", all);
                                    }}
                                    dataKey="name"
                                    optionLabel="name" />
                                <label htmlFor="projectsWithGrant">Projekti</label>
                            </span>
                        </div>
                    </div>
                </div>
                <DialogFooter hide={props.hide} onClick={formik.handleSubmit} />
            </div>
        </form>
    );
}

export default EmployeeForm;