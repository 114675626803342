import {convertFromDate, convertToDate} from "../shared/utils/DateUtils";
import EmployeeDatabase from "./EmployeeDatabase";
import EmployeeLdap from "./EmployeeLdap";
import VacationDays from "./VacationDays";
import Project, {makeProjects} from "./Project";
import {get} from "../shared/api/endpoints/CrudApi";
import LdapAttribute, {CLdapAttributes, makeEmployeeLdapAttributes} from "./LdapAttribute";
import _ from 'lodash';
import {getValidFormatOfFloatNumber} from "../shared/utils/StringUtils";

export default class Employee {
    id?: number;
    employeeNumber?: number;
    currentYearRemainingDays?: number;
    lastYearRemainingDays?: number;
    currentYearVacationDays?: VacationDays;
    lastYearVacationDays?: VacationDays;
    employeeDatabase?: EmployeeDatabase;
    employeeLdap?: EmployeeLdap;
    displayName?: string;
    validFrom?: string;
    validTo?: string | undefined | null;
    firstName?: string;
    lastName?: string;
    email?: string;
    managers?: Array<Employee>;
    validFromToShowOnForm?: Date;
    validToToShowOnForm?: Date;
    currentYear?: number;
    projectsWithGrant?: Project[];
    isActive?: boolean;
    totalProjectHours?: number;
    descriptionAttribute?: CLdapAttributes;
    departmentNumberAttribute?: CLdapAttributes;
    employeeTypeAttribute?: CLdapAttributes;
    phoneNumber?: string;
    totalProjectHoursToShowOnList?: string;


    constructor(id?: number, employeeNumber?: number, currentYearRemainingDays?: number, lastYearRemainingDays?: 
        number, currentYearVacationDays?: VacationDays, lastYearVacationDays?: VacationDays, 
        employeeDatabase?: EmployeeDatabase, employeeLdap?: EmployeeLdap, displayName?: string, 
        validFrom?: string, validTo?: string | undefined | null, firstName?: string, 
        lastName?: string, email?: string, managers?: Array<Employee>, validFromToShowOnForm?: Date, 
        validToToShowOnForm?: Date, year?: number, projectsWithGrant?: Project[], isActive?: boolean, 
        totalProjectHours?: number, descriptionAttribute?: LdapAttribute, employeeTypeAttribute?: LdapAttribute, 
        departmentNumberAttribute?: LdapAttribute,
        phoneNumber?: string) {
        this.id = id;
        this.employeeNumber = employeeNumber;
        this.currentYearRemainingDays = currentYearRemainingDays;
        this.lastYearRemainingDays = lastYearRemainingDays;
        this.currentYearVacationDays = currentYearVacationDays;
        this.lastYearVacationDays = lastYearVacationDays;
        this.employeeDatabase = employeeDatabase;
        this.employeeLdap = employeeLdap;
        this.displayName = displayName;
        this.validFrom = validFrom;
        this.validTo = validTo;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.managers = managers;
        this.validFromToShowOnForm = validFromToShowOnForm;
        this.validToToShowOnForm = validToToShowOnForm;
        this.currentYear = year;
        this.isActive = isActive;
        this.projectsWithGrant = projectsWithGrant;
        this.totalProjectHours = totalProjectHours;
        this.descriptionAttribute = descriptionAttribute;
        this.departmentNumberAttribute = departmentNumberAttribute;
        this.employeeTypeAttribute = employeeTypeAttribute;
        this.phoneNumber = phoneNumber;
    }
}

export function makeEmployee(employee: Employee | undefined) {
    if (employee === undefined) {
        let employee: Employee = new Employee();
        employee.validFrom = convertFromDate(new Date(Date.now()));
        employee.validFromToShowOnForm = convertToDate(employee.validFrom);

        return employee;
    }
    if (employee.employeeNumber) {
        employee.id = employee.employeeNumber;
    }

    makeEmployeeDatabase(employee);
    makeEmployeeLdap(employee);

    if (employee.currentYearVacationDays) {
        employee.currentYearVacationDays = makeVacationDays(employee.currentYearVacationDays);
        employee.currentYear = employee.currentYearVacationDays.year;
    } else {
        employee.currentYearVacationDays = getNewVacationDaysForYear(employee, new Date().getFullYear());
    }
    if (employee.lastYearVacationDays) {
        employee.lastYearVacationDays = makeVacationDays(employee.lastYearVacationDays);
    } else {
        employee.currentYearVacationDays = getNewVacationDaysForYear(employee, new Date().getFullYear() - 1);
    }

    employee = makeEmployeeLdapAttributes(employee);
    employee.displayName = `${employee.firstName!} ${employee.lastName!}`;
    employee.currentYear = employee.currentYearVacationDays?.year;

    employee.totalProjectHoursToShowOnList = getValidFormatOfFloatNumber(employee.totalProjectHours);

    return employee;
}

export function revertEmployee(employee: Employee) {
    if (employee.employeeDatabase) {
        employee.validFrom = convertFromDate(employee.validFromToShowOnForm);
        employee.validTo = convertFromDate(employee.validToToShowOnForm);
        employee.employeeDatabase = {
            id: employee.employeeDatabase.id,
            validFrom: employee.validFrom,
            validTo: employee.validTo,
            projectsWithGrant: employee.projectsWithGrant,
        } as EmployeeDatabase;
    }
    employee.employeeLdap = {
        displayName: employee.displayName,
        departmentNumber: employee.departmentNumberAttribute?.enumName,
        employeeNumber: employee.employeeNumber,
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        phoneNumber: employee.phoneNumber,
        managers: employee.managers,
        description: employee.descriptionAttribute?.enumName,
        uid: _.deburr(`${employee.firstName?.substring(0, 1)}${employee.lastName}`.toLowerCase()),
    } as EmployeeLdap;

    if (employee.currentYearVacationDays) {
        employee.currentYearVacationDays = {
            id: employee.currentYearVacationDays.id,
            year: employee.currentYearVacationDays.year,
            currentAvailableDays: employee.currentYearVacationDays.currentAvailableDays,
            daysOff: employee.currentYearVacationDays.daysOff,
        } as VacationDays;
    }
    if (employee.lastYearVacationDays) {
        employee.lastYearVacationDays = {
            id: employee.lastYearVacationDays.id,
            year: employee.lastYearVacationDays.year,
            currentAvailableDays: employee.lastYearVacationDays.currentAvailableDays,
            daysOff: employee.lastYearVacationDays.daysOff,
        } as VacationDays;
    }

    return employee;
}

export async function getEmployee() {
    let res = await get('user/info')
        .then((response) => {
                if (response.status === 200) {
                    return response;
                } else {
                    return undefined;
                }
            }
        )

    let data;
    if (res !== undefined) {
        data = await res.data;
        data = makeEmployee(data);
    }
    return data;
}

function makeEmployeeDatabase(employee: Employee) {
    if (employee.employeeDatabase) {
        employee.employeeDatabase = {
            id: employee.employeeDatabase.id,
            validFrom: employee.employeeDatabase.validFrom,
            validTo: employee.employeeDatabase.validTo,
            projectsWithGrant: employee.employeeDatabase.projectsWithGrant,
        } as EmployeeDatabase;
        if (employee.employeeDatabase.validFrom && employee.employeeDatabase.validFrom != '') {
            employee.validFrom = employee.employeeDatabase.validFrom;
            employee.validFromToShowOnForm = convertToDate(employee.employeeDatabase.validFrom);
        } else {
            employee.validFrom = new Date(Date.now()).toString();
            employee.validFromToShowOnForm = convertToDate(employee.validFrom);
        }
        if (employee.employeeDatabase.validTo && employee.employeeDatabase.validTo != '') {
            employee.validTo = employee.employeeDatabase.validTo;
            employee.validToToShowOnForm = convertToDate(employee.employeeDatabase.validTo);
        }
        if (employee.employeeDatabase.projectsWithGrant) {
            employee.projectsWithGrant = makeProjects(employee.employeeDatabase.projectsWithGrant);
        }
    } else {
        employee.employeeDatabase = {
            id: employee.employeeNumber,
            validFrom: employee.validFrom,
            validTo: employee.validTo,
            projectsWithGrant: employee.projectsWithGrant,
        } as EmployeeDatabase;
    }
}

function makeEmployeeLdap(employee: Employee) {
    if (employee.employeeLdap) {
        employee.employeeLdap = {
            displayName: employee.employeeLdap.displayName,
            employeeNumber: employee.employeeLdap.employeeNumber,
            firstName: employee.employeeLdap.firstName,
            lastName: employee.employeeLdap.lastName,
            email: employee.employeeLdap.email,
            phoneNumber: employee.employeeLdap.phoneNumber,
            managers: employee.employeeLdap.managers,
            departmentNumber: employee.employeeLdap.departmentNumber,
            employeeType: employee.employeeLdap.employeeType !== null && employee.employeeLdap.employeeType !== undefined ? employee.employeeLdap.employeeType : '',
            description: employee.employeeLdap.description !== null && employee.employeeLdap.description !== undefined ? employee.employeeLdap.description : '',
            uid: employee.employeeLdap.uid?.toLowerCase() ?? _.deburr(`${employee.firstName?.substring(0, 1)}${employee.lastName}`.toLowerCase()),
        } as EmployeeLdap;
    } else {
        employee.employeeLdap = {
            displayName: `${employee.firstName!} ${employee.lastName!}`,
            employeeNumber: employee.employeeNumber,
            firstName: employee.firstName,
            lastName: employee.lastName,
            email: employee.email,
            departmentNumber: undefined,
            phoneNumber: employee.phoneNumber,
            managers: employee.managers,
            employeeType: employee.departmentNumberAttribute?.enumName?.toLowerCase(),
            uid: _.deburr(`${employee.firstName?.substring(0, 1)}${employee.lastName}`.toLowerCase()),
            description: employee.descriptionAttribute?.enumName?.toLowerCase()
        } as EmployeeLdap;
    }
    employee.displayName = employee.employeeLdap.displayName;
    employee.employeeNumber = employee.employeeLdap.employeeNumber;
    employee.firstName = employee.employeeLdap.firstName;
    employee.lastName = employee.employeeLdap.lastName;
    employee.email = employee.employeeLdap.email;
    employee.phoneNumber = employee.employeeLdap.phoneNumber;
    employee.managers = employee.employeeLdap.managers;
    employee.isActive = employee.employeeDatabase?.validTo !== null && employee.employeeDatabase?.validTo !== undefined ? convertToDate(employee.employeeDatabase?.validTo)!.getTime() >= Date.now() : true;
}

function makeVacationDays(vacationDays: VacationDays) {
    return {
        id: vacationDays.id,
        year: vacationDays.year,
        currentAvailableDays: vacationDays.currentAvailableDays,
        daysOff: vacationDays.daysOff,
    } as VacationDays;
}

function getNewVacationDaysForYear(employee: Employee, year: number) {
    return {
        id: undefined,
        year: year,
        currentAvailableDays: 0,
        daysOff: 0,
    };
}