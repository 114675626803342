import React from "react";
import {Button} from 'primereact/button';
import './generic-button.scss'

export type ButtonModalYesProps = {
    label?: string,
    onClick?: any,
    icon?: EButtonIcons,
    disabled?: boolean,
    color?: EButtonColor,
    styled?: EButtonStyle,
    type?: 'submit' | 'reset' | 'button',
    isRounded?: boolean | undefined,
    isOnList?: boolean | undefined,
    id?: string,
    iconPositionedRight?: boolean
}

function GenericButton(props: ButtonModalYesProps) {
    return (
        <>
            <Button label={props.label} icon={props.icon?.toString() ?? undefined}
                    onClick={props.onClick ?? undefined}
                    disabled={props.disabled}
                    type={'button'}
                    id={props.id ?? undefined}
                    iconPos={props.iconPositionedRight ? "right":undefined}
                    className={' '
                        + (props.isRounded ? ' p-button-rounded ' : '')
                        + (props.color ? props.color : '')
                        + (props.styled ? props.styled : '')
                        + (props.isOnList ? 'mr-2' : '')
                        + (' text-align-center ')}
            />
        </>
    );
}

export default GenericButton;

export enum EButtonColor {
    MILK_BLUE = ' p-button-milk-blue ',
    LUMINUM_BLUE = ' p-button-luminum-blue ',
    DARK_BLUE = ' p-button-dark-blue '
}

export enum EButtonStyle {
    TRANSPOSE = "p-button-text",
    NORMAL = " ",
    OUTLINED = "p-button-outlined"
}

export enum EButtonIcons {
    //https://www.primefaces.org/primereact/icons/
    PLUS = "pi pi-plus",
    MINUS = "pi pi-minus",
    SEARCH = "pi pi-search",
    CHECK = "pi pi-check",
    INFO = "pi pi-info",
    UPDATE = "pi pi-pencil",
    IKS = "pi pi-times",
    EMPLOYEE = "pi pi-user",
    EMPLOYEES = "pi pi-users",
    PDF = "pi pi-file-pdf",
    CALENDAR_PLUS = "pi pi-calendar-plus",
    UNDO = "pi pi-undo",
    DOT_DOT_DOT_V = "pi pi-ellipsis-v",
    FILTER = "pi pi-filter-slash",
    LOGOUT = "pi pi-sign-out",
    MY_TIME_SHEET = "pi pi-calculator",
    BOOK = "pi pi-book",
    ID_CARD = "pi pi-id-card",
    ABSENCE_TIME = "pi pi-microsoft",
    MY_ABSENCE_TIME = "pi pi-briefcase",
    EDIT_USER = "pi pi-user-edit",
    ROBOTS_HEAD = "pi pi-prime",
    SETTINGS = "pi pi-wrench",
    CALENDAR = "pi pi-calendar",
    LIST = "pi pi-bars",
    MONEY_BILL = "pi pi-inbox",
    CLOCK = "pi pi-clock",
    SEND = "pi pi-send",
    SETTINGS_ROUND = "pi pi-cog",
    LINK = "pi pi-link",
    ANGLE_RIGHT = "pi pi-angle-right",
    ANGLE_LEFT = "pi pi-angle-left",
}