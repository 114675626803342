import HttpService from "../../services/HttpService";
import {toast} from "react-toastify";
import {makeLdapAttribute} from "../../../models/LdapAttribute";
import {edit} from "./CrudApi";

const apiURL = process.env.REACT_APP_API_URL;

function employeeDeactivation(id: number) {
    const response = edit('admin/employee/deactivation',id)
    return response;
}

function absenceRequestDeactivationUser(id: number | undefined) {
    if(id === undefined) {
        return;
    }
    const response = edit('ego/user/absenceRequest/cancellations',id)
    return response;
}

function absenceRequestCancellationAdmin(id: number | undefined) {
    if(id === undefined) {
        return;
    }
    const response = edit('ego/admin/absenceRequest/cancellations',id);
    return response;
}

function absenceRequestApprovingAdmin(id: number | undefined) {
    if(id === undefined) {
        return;
    }
    const response = edit('ego/admin/absenceRequest/approving',id)
    return response;
}

function absenceRequestDenyingAdmin(id: number | undefined) {
    if(id === undefined) {
        return;
    }
    const response = edit('ego/admin/absenceRequest/denying',id)
    return response;
}


async function getAllValuesForAttribute(attributeName: string) {

    const headers = {
        headers:
        {
            "Content-Type": "text/plain"
        }
    }

    let res = await HttpService.getAxiosClient().post(`${apiURL}/admin/employee/attributeValues`, attributeName, headers)
        .then((response) => {

            if (response.status === 200) {
                return makeLdapAttribute(response.data);
            } else {
                return undefined;
            }
        }
        )

    let data;
    if (res !== undefined) {
        data = await res;
    }
    return data;
}

export { employeeDeactivation, absenceRequestDeactivationUser, absenceRequestCancellationAdmin, getAllValuesForAttribute, absenceRequestApprovingAdmin, absenceRequestDenyingAdmin };