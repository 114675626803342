import React, {useState} from "react";
import Client, {makeClient, revertClient} from "../../../models/Client";
import {FormikErrors, useFormik} from "formik";
import {edit} from "../../../shared/api/endpoints/CrudApi";
import GenericButton, {EButtonIcons, EButtonStyle} from "../../../shared/components/generic/button/GenericButton";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import FormFooter from "../../../shared/components/generic/form-inside-table/FormFooter";

interface ClientTemplateProps {
    entity: Client;
    handleDataFromChild(e: any, action: string): void;
}

const ClientTemplate = (props: ClientTemplateProps) => {

    const [client, setClient] = useState(makeClient(props.entity) ?? undefined);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: client!,
        validate: (values: Client) => {
            let errors: FormikErrors<Client> = {};

            if (!values.name || values.name === '') {
                errors.name = 'Ime je obavezno polje.';
            }

            if (!values.identifier || values.identifier === '') {
                errors.identifier = 'Identifikator je obavezno polje.'
            }

            if (!values.countryCode || values.countryCode === '') {
                errors.countryCode = 'Obavezno polje.';
            }

            return errors;
        },
        onSubmit:
            async values => {
                values = revertClient(values);

                if (client.id) {
                    edit(`erv/admin/clients`, client.id, values).then(res => res ? props.handleDataFromChild(makeClient(values), 'edit') : undefined);
                }
            },
    });

    function deleteClient() {
        formik.values.isActive = false;
        edit(`erv/admin/clients`, client.id!, formik.values).then(res => res ? props.handleDataFromChild(makeClient(formik.values), 'edit') : undefined);
    }

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    function enableClient() {
        if (formik.values.id) {
            formik.values.isActive = true;
            edit(`erv/admin/clients`, formik.values.id, formik.values).then(res => res?.status === 200 ? props.handleDataFromChild(makeClient(formik.values), 'edit') : undefined);
        }
    }

    const clientForm = () => {
        return (
            <div className="expanded-div">
                <form>
                    <div>
                        <div style={{border: "transparent"}}>
                            <div className="p-fluid grid" style={{paddingTop: '20px'}}>

                                <div className="field col-3">
                                    <span className="p-float-label">
                                        <InputText id="countryCode" value={formik.values.countryCode} placeholder="Kodovi" className="w-full"
                                        disabled={!formik.values.isActive} onChange={formik.handleChange} />
                                        <label htmlFor="countryCode"
                                            className={classNames({'p-error': isFormFieldValid('countryCode')})}>Kod</label>
                                    </span>
                                    {getFormErrorMessage('countryCode')}

                                </div>
                                <div className="field col-4">
                                    <span className="p-float-label">
                                        <InputText id="identifier" name="identifier" value={formik.values.identifier} 
                                        disabled={!formik.values.isActive} onChange={formik.handleChange} />
                                        <label htmlFor="identifier"
                                            className={classNames({'p-error': isFormFieldValid('identifier')})}>Identifikator</label>
                                    </span>
                                    {getFormErrorMessage('identifier')}
                                </div>
                                <div className="field col-5">
                                    <span className="p-float-label">
                                        <InputText id="name" name="name" value={formik.values.name}
                                                disabled={!formik.values.isActive} onChange={formik.handleChange}/>
                                        <label htmlFor="name"
                                            className={classNames({'p-error': isFormFieldValid('name')})}>Naziv</label>
                                    </span>
                                    {getFormErrorMessage('name')}
                                </div>
                                <div className="field col-6">
                                    <span className="p-float-label">
                                        <InputText id="country" name="country" value={formik.values.country}
                                                disabled={!formik.values.isActive} onChange={formik.handleChange}/>
                                        <label htmlFor="country"
                                            className={classNames({'p-error': isFormFieldValid('country')})}>Država</label>
                                    </span>
                                    {getFormErrorMessage('country')}

                                </div>

                                <div className="field col-6">
                                    <span className="p-float-label">
                                        <InputText id="city" name="city" value={formik.values.city}
                                                disabled={!formik.values.isActive} onChange={formik.handleChange}/>
                                        <label htmlFor="city"
                                            className={classNames({'p-error': isFormFieldValid('city')})}>Grad</label>
                                    </span>
                                    {getFormErrorMessage('city')}

                                </div>

                                <div className="field col-6">
                                    <span className="p-float-label">
                                        <InputText id="streetName" name="streetName" value={formik.values.streetName}
                                                disabled={!formik.values.isActive} onChange={formik.handleChange}/>
                                        <label htmlFor="streetName"
                                            className={classNames({'p-error': isFormFieldValid('streetName')})}>Ulica</label>
                                    </span>
                                    {getFormErrorMessage('streetName')}

                                </div>

                                <div className="field col-3">
                                    <span className="p-float-label">
                                        <InputText id="streetNumber" name="streetNumber" value={formik.values.streetNumber}
                                                disabled={!formik.values.isActive}
                                                onChange={formik.handleChange}/>
                                        <label htmlFor="streetNumber"
                                            className={classNames({'p-error': isFormFieldValid('streetNumber')})}>Ulični broj</label>
                                    </span>
                                    {getFormErrorMessage('streetNumber')}

                                </div>

                                <div className="field col-3">
                                    <span className="p-float-label">
                                        <InputText id="zip" name="zip" value={formik.values.zip}
                                                onChange={formik.handleChange}
                                                disabled={!formik.values.isActive}/>
                                        <label htmlFor="zip" className={classNames({'p-error': isFormFieldValid('zip')})}>Zip kod</label>
                                    </span>
                                    {getFormErrorMessage('zip')}

                                </div>

                                {!formik.values.isActive ?
                                    <>
                                        <div className="field col-12 md:col-12">
                                            <div className="flex justify-content-center">
                                                Pritiskom na gumb možete označiti klijenta aktivnim
                                            </div>
                                        </div>
                                        <div className="field col-4 md:col-4 col-offset-4 md:col-offset-4">
                                            <div className="flex justify-content-center">
                                                <GenericButton onClick={enableClient} icon={EButtonIcons.PLUS}
                                                            styled={EButtonStyle.OUTLINED} label={"Aktiviraj"}/>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                        <FormFooter onClickUpdate={formik.handleSubmit} onClickDelete={deleteClient} labelOnDelete="Deaktiviraj"/>
                    </div>
                </form>
            </div>
        )
    }
    return (
        <div className="flex" style={{padding: "1rem 1rem 0 1rem"}}>
            {clientForm()}
        </div>
    );

}
export default ClientTemplate;