import {FormikErrors, useFormik} from "formik";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {useEffect, useState} from "react";
import Client from "../../../models/Client";
import ContactPerson, {makeContactPerson, revertContactPerson} from "../../../models/ContactPerson";
import {getAllActiveClients} from "../../../shared/api/GetFormData";
import {create} from "../../../shared/api/endpoints/CrudApi";
import DialogFooter from "../../../shared/components/generic/dialog/DialogFooter";
import {checkEMail, checkPhoneNumber} from "../../../shared/utils/ValidationHelpers";

interface IContactPersonFormProps {
    hide: () => void,
    initialValues: ContactPerson
    addNewDataToTable: React.Dispatch<any>
}
function ContactPersonForm(props: IContactPersonFormProps) {

    const [contactPerson, setContactPerson] = useState(makeContactPerson(props.initialValues ?? undefined))

    const [clients, setClients] = useState(Array<Client>);

    

    useEffect(() => {
        (async () => {
            setClients(await getAllActiveClients())
        })()
    }, []);

    const formik = useFormik({
        initialValues: contactPerson!,
        validate: (values: ContactPerson) => {
            let errors: FormikErrors<ContactPerson> = {};

            if (!values.firstName) {
                errors.firstName = 'Ime je obavezno polje.';
            }

            if (!values.lastName) {
                errors.lastName = 'Prezime je obavezno polje.'
            }

            if (!values.client) {
                errors.client = 'Klijent je obavezno polje.'
            }
            checkEMail(values, errors);
            checkPhoneNumber(values, errors);

            return errors;
        },
        onSubmit:
            async values => {

                values = revertContactPerson(values);
                values.id = undefined;
                await create(`erv/admin/contactPersons`, values).then(res => res.status === 201 ? props.addNewDataToTable(makeContactPerson(res.data)) : undefined);
                formik.resetForm();

                props.hide();

            },
    });

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    const setSelected = (e: Client) => {
        formik.setFieldValue('client', e);
        formik.errors.client = '';
    }


    return (
        <form>
            <div>
                <div style={{ border: "transparent" }}>
                    <div className="p-fluid grid" style={{ paddingTop: '20px' }}>
                        <div className="field col-4">
                            <span className="p-float-label">
                                <InputText id="firstName" name="firstName" value={formik.values.firstName} onChange={formik.handleChange}  />
                                <label htmlFor="firstName" className={classNames({ 'p-error': isFormFieldValid('firstName') })}>Ime</label>
                            </span>
                            {getFormErrorMessage('firstName')}
                        </div>

                        <div className="field col-4">
                            <span className="p-float-label">
                                <InputText id="lastName" name="lastName" value={formik.values.lastName} onChange={formik.handleChange}  />
                                <label htmlFor="lastName" className={classNames({ 'p-error': isFormFieldValid('lastName') })}>Prezime</label>
                            </span>
                            {getFormErrorMessage('lastName')}

                        </div>

                        <div className="field col-4">
                            <span className="p-float-label">
                                <Dropdown id="name" filter dataKey="name" name="name" value={formik.values?.client} options={clients} onChange={e => setSelected(e.value)} optionLabel="name" placeholder="Odaberi klijenta" />
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('client') })}>Klijent</label>
                            </span>
                            {getFormErrorMessage('client')}
                        </div>

                        <div className="field col-6">
                            <span className="p-float-label">
                                <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange}  />
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>Email</label>
                            </span>
                            {getFormErrorMessage('email')}

                        </div>

                        <div className="field col-6">
                            <span className="p-float-label">
                                <InputText id="phoneNumber" name="phoneNumber" value={formik.values.phoneNumber} onChange={formik.handleChange}  />
                                <label htmlFor="phoneNumber" className={classNames({ 'p-error': isFormFieldValid('phoneNumber') })}>Broj telefona</label>
                            </span>
                            {getFormErrorMessage('phoneNumber')}

                        </div>

                        <div className="field col-12">
                            <span className="p-float-label">
                                <InputText id="note" name="note" value={formik.values.note} onChange={formik.handleChange}  />
                                <label htmlFor="note" className={classNames({ 'p-error': isFormFieldValid('note') })}>Bilješka</label>
                            </span>
                            {getFormErrorMessage('note')}

                        </div>
                    </div>
                </div>
                <DialogFooter hide={props.hide} onClick={formik.handleSubmit} />
            </div>
        </form >
    );
}

export default ContactPersonForm;