import React from "react";
import { Dialog } from 'primereact/dialog';
import GenericButton, { EButtonColor, EButtonIcons } from "../button/GenericButton";
import { IChildrenProps } from "../../../interfaces/IChildrenProps";
import './generic-dialog.scss'

interface DialogWithTimePickerProps extends IChildrenProps {
    headerText: string,
    onHide: () => void,
    visible: boolean,
    onSubmit?: () => void,
    onCancel?: () => void,
    hasFooter?: boolean;
}

function GenericDialog(props: DialogWithTimePickerProps) {
    const footer = (
        (props.hasFooter != null && props.hasFooter) ?
        <div>
            <GenericButton label="Pohrani" onClick={props.onSubmit} icon={EButtonIcons.CHECK} color={EButtonColor.LUMINUM_BLUE}/>
            <GenericButton label="Odustani" onClick={props.onCancel} icon={EButtonIcons.IKS} color={EButtonColor.DARK_BLUE}/>
        </div>
            :
            <></>
    );

    return (
        <>
            <Dialog header={props.headerText} footer={footer} visible={props.visible} modal
                    onHide={props.onHide} >
                {props.children}
            </Dialog>
        </>
    );
}

export default GenericDialog;