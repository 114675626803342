import React, {useState} from "react";
import {Card} from "primereact/card";
import {EButtonIcons} from "../../shared/components/generic/button/GenericButton";
import {DataView} from "primereact/dataview";
import {EColors} from "../../styles/themes/luminum-theme/Constants";
import {Divider} from "primereact/divider";
import {useNavigate} from "react-router";
import eventEmitter from "../../shared/services/EventEmitter";

export interface DataViewItemProp {
    id: number;
    name: string;
    url: string;
    icon: EButtonIcons;
    item?: JSX.Element;
    button_onClick_url?: string;
    description?: string;
    button?: boolean;
    button_label?: string;
    isHovered?: boolean;
    state?: any;
}

export interface GenericPanelProps {
    data: DataViewItemProp[];
}

const GenericPanel = (props: GenericPanelProps) => {
    const [items, setItems] = useState<DataViewItemProp[]>(() => {
        props.data.forEach(d => d.isHovered = false);
        return props.data;
    });
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = (e: DataViewItemProp) => {
        if (e.id != undefined && items[e.id - 1] != undefined) {
            items[e.id - 1].isHovered = true;
        }
        setIsHover(true);
    };

    const handleMouseLeave = (e: DataViewItemProp) => {
        if (e.id != undefined && items[e.id - 1] != undefined) {
            items[e.id - 1].isHovered = false;
        }
        setIsHover(false);
    };

    let navigate = useNavigate();
    const cardHeader = (data: DataViewItemProp) => (
        <div style={{width: "100%", textAlign: "center"}}>
            {data.isHovered ?
                <>
                    <span style={{fontSize: "1.5rem", color: EColors.FOOTER_BLUE}}>{data.name}</span>
                    <Divider style={{height: '1px', background: EColors.DIVIDER_BLUE}}/>
                </>
                :
                <>
                    <span style={{fontSize: "1.5rem", color: EColors.FOOTER_BLUE, fontWeight: '550'}}>{data.name}</span>
                    <Divider style={{height: '1px', background: EColors.DIVIDER_BLUE}}/>
                </>
            }
        </div>)
    //TODO change this in future so it does not use event emitter!
    const navigateTo = (event: any, data: DataViewItemProp) => {
        eventEmitter.emit('change_sidebar_menu', data.url);
        navigate(data.url);
        event.stopPropagation();
    };
    const itemTemplate = (data: DataViewItemProp) => (
        <div className="" style={{border: "transparent", boxShadow: "none", display: "flex", cursor: "pointer"}}>
            <Card onMouseEnter={() => handleMouseEnter(data)} onMouseLeave={() => handleMouseLeave(data)}
                  onClick={(e: any) => navigateTo(e, data)} className="p-card-overview " header={cardHeader(data)}>
                <div>
                    {data.isHovered ?
                        <div
                            className="flex align-self-center justify-content-center align-content-center align-items-center">
                            <i className={data.icon ? (data.icon + ' layout-panel-icon ') : ''}
                               style={{opacity: "25%"}}></i>
                            {/*data.button ?
                            <div style={{opacity: '100%!important'}}>
                                    <GenericButton onClick={(event: Event) => navigateOnButton(event, data.url)}
                                                   styled={EButtonStyle.OUTLINED}
                                                   label={data.button_label} color={EButtonColor.LUMINUM_BLUE}/>
                                </div>
                                :
                             <></>*/}

                        </div>
                        :
                        <div
                            className="flex align-self-center justify-content-center align-content-center align-items-center">
                            <i className={data.icon ? (data.icon + ' layout-panel-icon ') : ''}></i>
                        </div>
                    }
                </div>
            </Card>
        </div>
    )

    return (
        <>
            <DataView value={items} itemTemplate={itemTemplate}></DataView>
        </>
    )
}

export default GenericPanel;