import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRouter from './router/AppRouter';
import HttpService from './shared/services/HttpService';
import KeycloakService from './shared/services/KeycloakService';

const renderApp = () => ReactDOM.createRoot(
    document.getElementById('root')!
).render(
    <React.StrictMode>
        <AppRouter />
    </React.StrictMode>
);

KeycloakService.initKeycloak(renderApp);
HttpService.configure();