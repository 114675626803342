import axios from "axios";

export async function CheckAddressHereApi(apiKey: string, streetName?: string, streetNumber?: string, city?: string, country?: string) {

    const res = axios.get(`https://geocode.search.hereapi.com/v1/geocode?q=${streetName}+${streetNumber}+${city}+${country}&apiKey=${apiKey}`)
        .then(response => {
            return response;
        }
        )

    let data = await (await res).data;

    return data;

}