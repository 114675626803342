import React, {useEffect, useState} from "react";
import {FormikErrors, useFormik} from "formik";
import {Calendar} from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";
import {InputTextarea} from "primereact/inputtextarea";
import TimeSheet, {makeTimeSheet, revertTimeSheet} from "../../../models/TimeSheet";
import Project from "../../../models/Project";
import TimeSheetType, {getAllTimeSheetTypes} from "../../../models/TimeSheetType";
import Holiday from "../../../models/Holiday";
import {getAllEmployeesOnProject, getAllHolidays, getAllProjects} from "../../../shared/api/GetFormData";
import {deleteById, edit} from "../../../shared/api/endpoints/CrudApi";
import {Dropdown} from "primereact/dropdown";
import Employee from "../../../models/Employee";
import {classNames} from "primereact/utils";
import moment from "moment";
import FormFooter from "../../../shared/components/generic/form-inside-table/FormFooter";
import { InputText } from "primereact/inputtext";

interface TimeSheetTemplateProps {
    entity: TimeSheet;
    handleDataFromChild(e: any, action: string): void;
}

function TimeSheetTemplate(props: TimeSheetTemplateProps) {
    const [timeSheet, setTimeSheet] = useState<TimeSheet>(makeTimeSheet(props.entity ?? undefined))
    const [project, setProject] = useState<Project>();
    const [projects, setProjects] = useState<Project[]>();

    const [type, setType] = useState<TimeSheetType>();
    const [types, setTypes] = useState<TimeSheetType[]>();

    const [employees, setEmployees] = useState<Employee[]>();

    const [holidays, setHolidays] = useState<Holiday[]>();
    const [isEmployeeDisabled, setIsEmployeeDisabled] = useState<boolean>(props.entity.project === undefined);

    useEffect(() => {
        getAllProjects().then((res: Project[]) => {
            setProjects(res);
        });
        getAllTimeSheetTypes().then((res: TimeSheetType[]) => {
            setTypes(res);
        });
        getAllHolidays().then((res: Holiday[]) => {
            setHolidays(res);
        });
        getAllEmployeesOnProject(timeSheet.project?.id).then((res: Employee[]) => {
            setEmployees(res);
        });

    }, [])

    const formik = useFormik({
        initialValues: timeSheet!,
        validate: (values: TimeSheet) => {
            let errors: FormikErrors<TimeSheet> = {};
            if (!values.dateToShowOnForm) {
                errors.dateToShowOnForm = "Datum je obavezno polje."
            }
            if (!values.project) {
                errors.project = "Projekt je obavezno polje."
            }
            if (!values.employee) {
                errors.employee = "Zaposlenik je obavezno polje."
            }
            if (!values.numberOfHours) {
                errors.numberOfHours = "Broj sati je obavezno polje."
            }
            return errors;
        },

        onSubmit:
            async values => {
                values = revertTimeSheet(values);

                if (values.id) {
                    await edit(`erv/admin/timeSheet`, values.id, values).then(res => res?.status === 200 ? props.handleDataFromChild(makeTimeSheet(values), 'edit') : undefined);
                }
            },
    });

    async function deleteTimeSheet() {
        if (formik.values.id) {
            await deleteById(`erv/admin/timeSheet`, formik.values.id).then(res => res?.status === 200 ? props.handleDataFromChild(formik.values, 'delete') : undefined);;
        }
    }

    // useEffect(() => {
    //     if (formik.values.project === undefined) {
    //         setIsEmployeeDisabled(true);
    //     } else {
    //         setEmployees(formik.values.project.employees?.map(
    //             (employee: Employee) => {
    //                 return makeEmployee(employee);
    //             }));

    //         setIsEmployeeDisabled(false);
    //     }
    // }, [formik.values.project, formik.values.employee]);

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    const handleValueChange = (e: any) => {
        const value = e.target.value;
        if (value % 0.5 !== 0) {
            e.target.value = Math.round(e.target.value / 0.5) * 0.5;
            formik.setFieldValue('numberOfHours', e.target.value);
        } else {
            formik.setFieldValue('numberOfHours', e.target.value);
        }
    };

    return (

        <div className="expanded-div">
            <form>
                <div>
                    <div style={{border: "transparent"}}>
                        <div className="p-fluid grid" style={{paddingTop: '20px'}}>
                            <div className="field col-3">
                            <span className="p-float-label">
                            <InputText id="project"  name="name"
                                        value={formik.values.project?.name}
                                        disabled={true}
                                        />
                            <label htmlFor="project"
                                    className={classNames({'p-error': isFormFieldValid('project')})}>Projekt</label>
                            </span>
                                {getFormErrorMessage('project')}
                            </div>
                            <div className="field col-3">
                                <span className="p-float-label">
                                    <Dropdown id="employee" optionLabel="displayName" name="employee"
                                              dataKey="displayName" filter
                                              value={formik.values.employee}
                                              options={employees}
                                              disabled={isEmployeeDisabled}
                                              onChange={(e) => {
                                                  formik.setFieldValue('employee', e.value);
                                              }}/>
                                    <label htmlFor="employee"
                                           className={classNames({'p-error': isFormFieldValid('employee')})}>Zaposlenik</label>
                                </span>
                                {getFormErrorMessage('employee')}
                            </div>
                            <div className="field col-2">
                                <span className="p-float-label">
                                    <Dropdown id="project" optionLabel="name" name="name"
                                              value={formik.values.type}
                                              options={types}
                                              onChange={(e) => {
                                                  setType(e.value);
                                                  formik.setFieldValue('type', e.value);
                                              }}/>
                                    <label htmlFor="project">Tip</label>
                                </span>
                                {getFormErrorMessage('type')}
                            </div>
                            <div className="field col-2">
                            <span className="p-float-label">
                                <Calendar showButtonBar name="dateToShowOnForm" value={formik.values.dateToShowOnForm}
                                            onChange={formik.handleChange}
                                            dateFormat="dd.mm.yy." id="calendarFrom"
                                            disabledDays={[0, 6]}
                                            minDate={moment().startOf('month').toDate()}
                                />
                                <label htmlFor="calendarFrom"
                                        className={classNames({'p-error': isFormFieldValid('dateToShowOnForm')})}>Datum</label>
                            </span>
                                {getFormErrorMessage('dateToShowOnForm')}
                            </div>
                            <div className="field col-1">
                            <span className="p-float-label">
                                <InputNumber id="numberOfHours"
                                             value={formik.values.numberOfHours}
                                             onValueChange={handleValueChange}
                                             maxFractionDigits={1} step={0.5} min={0} max={24}
                                />
                                <label htmlFor="numberOfHours"
                                       className={classNames({'p-error': isFormFieldValid('numberOfHours')})}>Broj sati</label>
                            </span>
                                {getFormErrorMessage('numberOfHours')}

                            </div>
                            <div className="field col-12 md:col-12">
                            <span className="p-float-label">
                                <InputTextarea id="activity" value={formik.values.activity}
                                               onChange={formik.handleChange}/>
                                <label htmlFor="activity">Opis aktivnosti</label>
                            </span>
                            </div>
                        </div>
                    </div>
                    <FormFooter onClickUpdate={formik.handleSubmit} onClickDelete={deleteTimeSheet}/>
                </div>
            </form>
        </div>
    );
}

export default TimeSheetTemplate;