import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import { IChildrenProps } from '../../shared/interfaces/IChildrenProps';

interface IMenuContext {
    activeMenu: string,
    setActiveMenu: Dispatch<SetStateAction<string>> | null
}

export const MenuContext = createContext<IMenuContext>({} as IMenuContext);

export const MenuProvider = (props: IChildrenProps) => {
    const [activeMenu, setActiveMenu] = useState('');

    const value = {
        activeMenu,
        setActiveMenu
    };

    return <MenuContext.Provider value={value}>
        {props.children}
    </MenuContext.Provider>;
};
