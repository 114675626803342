import { get } from "../shared/api/endpoints/CrudApi";

export default class JournalType {
    id?: number;
    enumName: string;
    name?: string;

    constructor(id: number,enumName: string, name: string) {
        this.id = id;
        this.enumName = enumName;
        this.name = name;
    }
}

export async function getAllJournalTypes() {
    let res = await get('erv/admin/journal-types') //promjenit endpoint
        .then((response: any) => {
                if (response.status === 200) {
                    response.data.journalTypes.map(
                        (journalType: JournalType) => {
                            return makeJournalType(journalType);
                        }
                    )
                    return response;
                } else {
                    return undefined;
                }
            }
        )
    let data;
    if (res !== undefined) {
        data = await res.data.journalTypes;
    }
    return data;
}

export const CJournalTypes: JournalType[] = [ //tu trebaju ic ti tipovi journala
    {name: "Ugovor", enumName: "CONTRACT"},
    {name: "Narudzbenica", enumName: "RECEIVING_ORDER"},
    {name: "Obicno", enumName: "USUALLY"},
    {name: "Dummy", enumName: "DUMMY"}
];


export function makeJournalType(journalType: JournalType) {
    return journalType;
}