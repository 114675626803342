import React, {useEffect, useState} from "react";
import {FormikErrors, useFormik} from "formik";
import {edit} from "../../../shared/api/endpoints/CrudApi";
import {InputText} from "primereact/inputtext";
import AbsenceRequest, {makeAbsenceRequest, revertAbsenceRequest} from "../../../models/AbsenceRequest";
import {classNames} from "primereact/utils";
import AbsenceType, {
    AbsenceType_SickLeave,
    AbsenceType_Vacation,
    getAllAbsenceTypes
} from "../../../models/AbsenceType";
import {dateTemplate} from "../../admin/absence-request/AbsenceRequestAssistance";
import {Dropdown} from "primereact/dropdown";
import {getAllHolidays} from "../../../shared/api/GetFormData";
import Holiday from "../../../models/Holiday";
import {Calendar} from "primereact/calendar";
import {InputTextarea} from "primereact/inputtextarea";
import {convertFromDate} from "../../../shared/utils/DateUtils";
import {absenceRequestDeactivationUser} from "../../../shared/api/endpoints/CustomApi";
import FormFooter from "../../../shared/components/generic/form-inside-table/FormFooter";
import {AbsenceRequestStatusType_CANCEL} from "../../../models/AbsenceRequestStatusType";

interface MyAbsenceRequestTemplateProps {
    entity: AbsenceRequest;
    handleDataFromChild(e: any, action: string): void;
}

function MyAbsenceRequestTemplate(props: MyAbsenceRequestTemplateProps) {
    const [absenceRequest, setAbsenceRequest] = useState<AbsenceRequest>(makeAbsenceRequest(props.entity));
    const [requestType, setRequestType] = useState<AbsenceType | undefined>(props.entity.absenceType);
    const [absenceTypes, setAbsenceTypes] = useState<AbsenceType[]>();
    const [holidays, setHolidays] = useState<Holiday[]>();
    const [dates,] = useState<Date[] | undefined>(props.entity.dateFromToShowOnForm && props.entity.dateToToShowOnForm ? [props.entity.dateFromToShowOnForm, props.entity.dateToToShowOnForm] : undefined);

    useEffect(() => {
        getAllAbsenceTypes().then((res: AbsenceType[]) => {
            setAbsenceTypes(res);
        });
        getAllHolidays().then((res) => {
            setHolidays(res);
        });

    }, [])

    useEffect(() => {
        formik.setFieldValue("absenceType", requestType);
    }, [requestType])


    useEffect(() => {
        if (formik.values.absenceType) {
            setRequestType(formik.values.absenceType);
        }
    }, [absenceTypes])

    function setDatesFromCalendar(dates: Date[]) {
        formik.values.dateFromToShowOnForm = dates[0];
        formik.values.dateToToShowOnForm = dates[1];

        formik.values.dateFrom = convertFromDate(dates[0]);
        formik.values.dateTo = convertFromDate(dates[1]);
    }

    const formik = useFormik({
        initialValues: absenceRequest!,
        validate: (values: AbsenceRequest) => {
            let errors: FormikErrors<AbsenceRequest> = {};

            if (!values.absenceType) {
                if (requestType) {
                    values.absenceType = requestType;
                } else {
                    errors.absenceType = 'Tip zahtjeva je obavezno polje.';
                }
            }

            if (!values.dateFrom) {
                errors.dateFrom = 'Datum od do je obavezno polje.';
            } else if (!values.dateTo) {
                errors.dateTo = 'Datum od do je obavezno polje.';
            }

            return errors;
        },

        onSubmit:
            async values => {
                if (requestType) {
                    values.absenceType = requestType;
                }
                values = revertAbsenceRequest(values);

                if (absenceRequest.id) {
                    await edit(`ego/user/absenceRequest`, absenceRequest.id, values).then(res => res?.status === 200 ? props.handleDataFromChild(values, 'edit') : undefined);
                }
            },
    });

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    async function deleteMyAbsenceRequest() {
        await absenceRequestDeactivationUser(absenceRequest.id)?.then(res => res?.status === 200 ? props.handleDataFromChild(makeAbsenceRequest(res.data), 'edit') : undefined);
    }

    return (
        <div className="expanded-div">
            <form>
                <div>
                    <div style={{border: "transparent"}}>
                        <div className="p-fluid grid" style={{paddingTop: '20px'}}>
                            <div className="field col-2">
                            <span className="p-float-label">
                                <Dropdown id="requestType" optionLabel="name" name="absenceTypeName"
                                          value={requestType}
                                          options={absenceTypes}
                                          readOnly={true}/>
                                    <label htmlFor="requestType">Tip zahtjeva</label>
                            </span>
                                {getFormErrorMessage('absenceType')}

                            </div>

                            <div className="field col-3 col-offset-2">
                                <span className="p-float-label">
                                    <InputText id="absenceRequestStatusType" className="inputType"
                                           readOnly={true} style={{textAlign: "center"}}
                                           value={formik.values.absenceRequestStatusType?.name}/>
                                    <label htmlFor="absenceRequestStatusType"
                                       className={classNames({'p-error': isFormFieldValid('calendar')})}>Status zahtjeva</label>
                                </span>
                            </div>
                            <div className="field col-3 col-offset-2">
                            <span className="p-float-label">
                                <Calendar showButtonBar name="calendar" value={dates}
                                          dateFormat="dd.mm.yy." selectionMode="range" id="calendar"
                                          dateTemplate={(e) => dateTemplate(e, holidays)}
                                          disabledDays={[0, 6]}
                                          readOnlyInput={true}
                                          minDate={new Date()}/>
                                <label htmlFor="calendar"
                                       className={classNames({'p-error': isFormFieldValid('calendar')})}>Datum od do</label>
                            </span>
                                {getFormErrorMessage('dateFrom')}
                                {getFormErrorMessage('dateTo')}

                            </div>

                            {formik.values.absenceRequestStatusType && formik.values.absenceRequestStatusType.enumName === AbsenceRequestStatusType_CANCEL.enumName ?
                                <>
                                <div className="field col-12">
                                        <span className="p-float-label">
                                            <InputTextarea id="textarea" value={formik.values.comment} rows={3}
                                                           onChange={formik.handleChange} name="comment"/>
                                            <label htmlFor="textarea">Poruka</label>
                                        </span>
                                </div>
                                <div className="field col-12">
                                    <div className="flex justify-content-center">
                                        {formik.values.absenceType ?
                                            formik.values.absenceType.id === AbsenceType_Vacation.id ?
                                                formik.values.absenceType?.name + ' je otkazan.'
                                                :
                                                formik.values.absenceType.id === AbsenceType_SickLeave.id ?
                                                    formik.values.absenceType?.name + ' je otkazano.'
                                                    :
                                                    formik.values.absenceType?.name + ' je otkazana.'
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>
                                </>
                                :
                                formik.values.absenceType && formik.values.absenceType.id === AbsenceType_Vacation.id ?
                                    <>
                                        <div className="field col-9">
                                        <span className="p-float-label">
                                            <InputTextarea id="textarea" value={formik.values.comment} rows={3}
                                                           onChange={formik.handleChange} name="comment"/>
                                            <label htmlFor="textarea">Poruka</label>
                                        </span>
                                        </div>
                                        <div className="field col-3">
                                            <InputText readOnly={true}
                                                       style={{
                                                           border: "transparent",
                                                           boxShadow: "none",
                                                           background: "transparent",
                                                           textAlign: "end"
                                                       }}
                                                       value={"Broj iskorištenih dana: " + (formik.values.usedDays?.toString().length === 1 ? ' ' : '') + formik.values.usedDays??0}/>

                                            {absenceRequest.employee && absenceRequest.employee.currentYearRemainingDays ?

                                                <InputText readOnly={true}
                                                       style={{
                                                           border: "transparent",
                                                           boxShadow: "none",
                                                           background: "transparent",
                                                           textAlign: "end"
                                                       }} className="flex"
                                                       value={"Ukupan broj dana godišnjeg: " + ((absenceRequest.employee.currentYearRemainingDays.toString().length === 1 ? ' ' : '') + absenceRequest.employee.currentYearRemainingDays)}/>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="field col-12">
                                        <span className="p-float-label">
                                            <InputTextarea id="textarea" value={formik.values.comment} rows={3}
                                                           onChange={formik.handleChange} name="comment"/>
                                            <label htmlFor="textarea">Poruka</label>
                                        </span>
                                        </div>
                                    </>
                            }

                        </div>
                    </div>
                    <FormFooter onClickUpdate={formik.handleSubmit}
                                onClickDelete={deleteMyAbsenceRequest}
                                isDisabledOnDelete={formik.values.absenceRequestStatusType && formik.values.absenceRequestStatusType.enumName === AbsenceRequestStatusType_CANCEL.enumName}
                                labelOnDelete="Otkaži"
                    />
                </div>
            </form>
        </div>
    );
}

export default MyAbsenceRequestTemplate;