import AbsenceType from "./AbsenceType";
import AbsenceRequestType from "./AbsenceType";
import AbsenceRequestStatusType, {
    AbsenceRequestStatusType_INITIAL,
    makeAbsenceRequestStatusType
} from "./AbsenceRequestStatusType";
import AbsenceRequestStatus from "./AbsenceRequestStatus";
import {convertFromDate, convertToDate} from "../shared/utils/DateUtils";
import Employee, {makeEmployee} from "./Employee";

export default class AbsenceRequest {
    id?: number;
    dateFrom?: string;
    dateTo?: string;
    year?: number;
    usedDays?: number;
    comment?: string;
    absenceRequestStatusType?: AbsenceRequestStatusType;
    absenceRequestStatusTypeOLD?: AbsenceRequestStatusType;
    absenceType?: AbsenceType;
    dateFromToShowOnForm?: Date;
    dateToToShowOnForm?: Date;
    absenceTypeToShowOnList?: string;
    absenceRequestStatuses?: AbsenceRequestStatus[];
    employee?: Employee;
    employeeDisplayName?: string;
    absenceRequestStatusTypeName?: string;
    absenceCreationDate?: string;
    absenceApprovalDate?: string;
    accountingSubmissionDate?: string;
    absenceCreationDateToShowOnForm?: Date;
    absenceApprovalDateToShowOnForm?: Date;
    accountingSubmissionDateToShowOnForm?: Date;


    constructor(id?: number, dateFrom?: string, dateTo?: string, year?: number, usedDays?: number, comment?: string, absenceRequestStatusType?: AbsenceRequestStatusType, absenceType?: AbsenceRequestType, dateFromToShowOnForm?: Date, dateToToShowOnForm?: Date, absenceTypeToShowOnList?: string, absenceRequestStatuses?: AbsenceRequestStatus[], employee?: Employee, employeeDisplayName?: string, absenceRequestStatusTypeName?: string) {
        this.id = id ?? undefined;
        this.dateFrom = dateFrom ?? '';
        this.dateTo = dateTo ?? '';
        this.year = year ?? undefined;
        this.usedDays = usedDays ?? 0;
        this.comment = comment ?? '';
        this.absenceRequestStatusType = absenceRequestStatusType ?? AbsenceRequestStatusType_INITIAL;
        this.absenceType = absenceType ?? undefined;
        this.dateFromToShowOnForm = dateFromToShowOnForm ?? undefined;
        this.dateToToShowOnForm = dateToToShowOnForm ?? undefined;
        this.absenceTypeToShowOnList = absenceTypeToShowOnList ?? undefined;
        this.absenceRequestStatuses = absenceRequestStatuses ?? undefined;
        this.employee = employee ?? undefined;
        this.employeeDisplayName = employeeDisplayName ?? undefined;
        this.absenceRequestStatusTypeName = absenceRequestStatusTypeName ?? undefined;
    }
}

export function makeAbsenceRequest(absenceRequest: AbsenceRequest | undefined) {
    if (absenceRequest === undefined) {
        return new AbsenceRequest();
    }
    absenceRequest.dateFromToShowOnForm = convertToDate(absenceRequest.dateFrom);
    absenceRequest.dateToToShowOnForm = convertToDate(absenceRequest.dateTo);
    absenceRequest.absenceCreationDateToShowOnForm = convertToDate(absenceRequest.absenceCreationDate);
    absenceRequest.absenceApprovalDateToShowOnForm = convertToDate(absenceRequest.absenceApprovalDate);
    absenceRequest.accountingSubmissionDateToShowOnForm = convertToDate(absenceRequest.accountingSubmissionDate);

    if (absenceRequest.absenceType) {
        absenceRequest.absenceTypeToShowOnList = absenceRequest.absenceType.name;
    }

    if (absenceRequest.employee) {
        absenceRequest.employee = makeEmployee(absenceRequest.employee);
        absenceRequest.employeeDisplayName = absenceRequest.employee.displayName;
    }

    if (absenceRequest.absenceRequestStatusType) {
        absenceRequest.absenceRequestStatusTypeOLD = makeAbsenceRequestStatusType(absenceRequest.absenceRequestStatusType);
        absenceRequest.absenceRequestStatusTypeName = absenceRequest.absenceRequestStatusType.name;
        absenceRequest.absenceRequestStatusType = makeAbsenceRequestStatusType(absenceRequest.absenceRequestStatusType);
    } else {
        absenceRequest.absenceRequestStatusTypeOLD = undefined;
    }
    return absenceRequest;
}

export function revertAbsenceRequest(absenceRequest: AbsenceRequest | undefined) {
    if (absenceRequest == undefined) {
        return new AbsenceRequest();
    }
    absenceRequest.dateTo = convertFromDate(absenceRequest.dateToToShowOnForm);
    absenceRequest.dateFrom = convertFromDate(absenceRequest.dateFromToShowOnForm);
    absenceRequest.absenceCreationDate = convertFromDate(absenceRequest.absenceCreationDateToShowOnForm);
    absenceRequest.absenceApprovalDate = convertFromDate(absenceRequest.absenceApprovalDateToShowOnForm);
    absenceRequest.accountingSubmissionDate = convertFromDate(absenceRequest.accountingSubmissionDateToShowOnForm);

    if (absenceRequest.dateFromToShowOnForm) {
        absenceRequest.year = absenceRequest.dateFromToShowOnForm.getFullYear();
    }

    if (absenceRequest.absenceRequestStatusTypeOLD == undefined && absenceRequest.absenceRequestStatusType
    || absenceRequest.absenceRequestStatusTypeOLD != absenceRequest.absenceRequestStatusType) {
    } else {
        absenceRequest.absenceRequestStatusType = undefined;
    }

    return absenceRequest;
}
