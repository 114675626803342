import {useEffect, useState} from "react";
import {getAllSummarizationData} from "../../../../shared/api/GetFormData";
import SummarizationTemplateYears from "./SummarizationTemplateYears";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import moment from "moment";
import SummarizationEmployee from "../../../../models/SummarizationEmployee";

const SummarizationList = () => {
    const [summarizationData, setSummarizationData] = useState<any | undefined>(undefined);
    const [loading, setloading] = useState(true);
    const [expandedRows, setExpandedRows] = useState<any>(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('' as any);

    useEffect(() => {
        getAllSummarizationData().then((res) => {
            setSummarizationData(res);
        })
        .finally(() => setloading(false))
    }, []);

    const formatDate = (dateString:any) => moment(dateString).format('DD.MM.YYYY.');


    return (
        <>
        <DataTable value={summarizationData}
                   loading={loading}
                   height="100%" 
                   rowExpansionTemplate={(e: SummarizationEmployee) => <SummarizationTemplateYears entity={e} handleDataFromChild={() => {}}  />}
                   expandedRows={expandedRows}
                   filterDisplay="menu"
                   globalFilterFields={["firstName", "lastName", "email"]}
                   globalFilter={globalFilterValue}
                   onRowToggle={(e) => {setExpandedRows(e.data)}} 
                   >
            <Column header="" style={{width: '0.5rem'}}/>
            <Column header="#" headerStyle={{ width: '0.5rem' }} body={(data, options) => options.rowIndex + 1}></Column>
            <Column expander headerStyle={{ width: '0.5%' }}></Column>
            <Column dataType="string" header="Ime" field="firstName" headerStyle={{ width: '1rem' }}></Column>
            <Column dataType="string" header="Prezime" field="lastName" headerStyle={{ width: '1rem' }}></Column>
            <Column dataType="string" header="Email" field="email" headerStyle={{ width: '1rem' }}></Column>
            <Column dataType="date" header="Datum zaposlenja" field="employeedFrom" body={(dateString) => formatDate(dateString.employeedFrom)} headerStyle={{ width: '1rem' }}></Column>
        </DataTable>
        </>
    );
}

export default SummarizationList;