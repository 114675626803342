import React from "react";
import ClassicExcelTemplate from "./ClassicExcelTemplate";

interface EricssonExportProps {
    hide: () => void;
    goBack: () => void;
}

function EricssonExport(props: EricssonExportProps) {
    return (

        <form>
            <div>
                <div style={{border: "transparent"}}>
                    <ClassicExcelTemplate hide={props.hide} goBack={props.goBack}
                                          url="admin/excel/ericsson/download"/>
                </div>
            </div>
        </form>
    );
}

export default EricssonExport;