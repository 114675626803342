import React from "react";
import ClassicExcelTemplateForUser from "./ClassicExcelTemplateForUser";

interface EricssonExportForUserProps {
    hide: () => void;
    goBack: () => void;
}

function EricssonExportForUser(props: EricssonExportForUserProps) {

    return (

        <form>
            <div>
                <ClassicExcelTemplateForUser hide={props.hide} goBack={props.goBack}
                                             url="user/excel/ericsson/download"/>
            </div>
        </form>
    );
}

export default EricssonExportForUser;