import GenericButton, {EButtonColor, EButtonIcons, EButtonStyle} from "../button/GenericButton";

interface IDialogFooterProps {
    hide: () => void,
    onClick?: () => void,
    isDisabledOnDelete?: boolean,
    isDisabledOnUpdate?: boolean,
    hasRightButtonOnly?: boolean,
    firstButtonLabel?: string;
    firstButtonIcon?: EButtonIcons;
    secondButtonLabel?: string,
    secondButtonIcon?: EButtonIcons;
    secondButtonHasIconOnRight?: boolean;

}

const DialogFooter = (props: IDialogFooterProps) => {
    return (

        <div className="flex justify-content-between" style={{paddingTop: '20px'}}>
            <div className="flex lg:col-3 md:col-4 sm:col-5">
                {props.hasRightButtonOnly ?
                    <></>
                    :
                    <GenericButton label={props.firstButtonLabel ?? "Odustani"} onClick={props.hide}
                                   icon={props.firstButtonIcon ?? EButtonIcons.IKS}
                                   disabled={props.isDisabledOnDelete ?? false}
                                   color={EButtonColor.DARK_BLUE} styled={EButtonStyle.OUTLINED}/>
                }
            </div>
            <div className="flex lg:col-3 md:col-4 sm:col-5 justify-content-end">
                <GenericButton label={props.secondButtonLabel ?? "Pohrani"} type="submit"
                               icon={props.secondButtonIcon ?? EButtonIcons.CHECK}
                               disabled={props.isDisabledOnUpdate ?? false}
                               onClick={props.onClick}
                               iconPositionedRight={props.secondButtonHasIconOnRight}
                               color={EButtonColor.LUMINUM_BLUE} styled={EButtonStyle.OUTLINED}/>
            </div>
        </div>
    );
}

export default DialogFooter;