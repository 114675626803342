import {Navigate, Route, Routes} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import KeycloakService from "../shared/services/KeycloakService";
import Overview from "../components/overview/Overview";
import MyInformation from "../components/my-informations/MyInformation";
import UserOverview from "../components/overview/UserOverview";
import JournalsList from "../components/admin/journals/JournalsList";
import EmployeeTabMenu from "../layout/tab-menus/EmployeeTabMenu";
import ClientList from "../components/admin/clients/ClientList";
import ConstantList from "../components/admin/settings/ConstantList";
import ProjectList from "../components/admin/projects/ProjectList";
import SummarizationList from "../components/admin/absence-request/summarization/SummarizationList";
import SummarizationTimeSheetTable
    from "../components/admin/time-sheet/summarizationtimesheet/SummarizationTimeSheetTable";
import MyAbsenceRequestList from "../components/user/my-absence-request/MyAbsenceRequestList";
import TimeSheetList from "../components/admin/time-sheet/TimeSheetList";
import MyTimeSheetList from "../components/user/my-time-sheet/MyTimeSheetList";
import HolidayList from "../components/holidays/HolidayList";
import ContactPersonList from "../components/admin/contact-persons/ContactPersonList";
import AbsenceRequestTabMenu from "../layout/tab-menus/AbsenceRequestTabMenu";

const MenuRouter = () => (
    
    <>
        <Routes>
            {/* ADMIN ROUTES */}
            {/* Show Admin routes only if user has admin role */}
            {KeycloakService.hasRole(['admin']) ?
                <>
                    <Route path="/" element={<Navigate to={`/admin/overview`}/>}/>
                    <Route path={`/admin/overview`} element={<Overview />}/>
                    <Route path={`/my_info`} element={<MyInformation />}/>
                    <Route path={`/summofabsence`} element={<SummarizationList />}/>
                    <Route path={`/absenceentry`} element={<AbsenceRequestTabMenu activeTab={1}/>}/>
                    <Route path={`/timesheetentry`} element={<TimeSheetList />}/>
                    <Route path={`/crm/journals`} element={<JournalsList/>}/>
                    <Route path={`/crm/clients`} element={<ClientList />}/>
                    <Route path={`/crm/contactpersons`} element={<ContactPersonList />}/>
                    <Route path={`/projects`} element={<ProjectList />}/>
                    <Route path={`/summoftimesheets`} element={<SummarizationTimeSheetTable />}/>
                    <Route path={`/settings/holidays`} element={<HolidayList />}/>
                    <Route path={`/settings/datatypes`} element={<ConstantList />}/>
                    <Route path={`/admin/workers`} element={<EmployeeTabMenu activeTab={0}/>}/>
                </>
                :
                <>
                    <Route path="/" element={<Navigate to={`/user/overview`}/>}/>
                </>
            } else {

            <>
                <Route path={`/user/overview`} element={<UserOverview />}/>
                <Route path={`/user/myabsencerequests`} element={<MyAbsenceRequestList/>}/>
                <Route path={`/user/mytimesheet`} element={<MyTimeSheetList/>}/>
                <Route path={`/user/my_info`} element={<MyInformation />}/>
            </>
            }
        </Routes>
        <ToastContainer autoClose={3000}/>
    </>
)

export default MenuRouter;