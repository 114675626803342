import {FormikErrors, useFormik} from "formik";
import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {classNames} from "primereact/utils";
import {useEffect, useState} from "react";
import AbsenceRequest, {makeAbsenceRequest, revertAbsenceRequest} from "../../../models/AbsenceRequest";
import AbsenceRequestStatusType, {getAllAbsenceRequestStatusTypes} from "../../../models/AbsenceRequestStatusType";
import AbsenceType, {getAllAbsenceTypes} from "../../../models/AbsenceType";
import Employee from "../../../models/Employee";
import Holiday from "../../../models/Holiday";
import {getAllEmployees, getAllHolidays} from "../../../shared/api/GetFormData";
import {create} from "../../../shared/api/endpoints/CrudApi";
import DialogFooter from "../../../shared/components/generic/dialog/DialogFooter";
import {convertFromDate, getTodaysDateAsString} from "../../../shared/utils/DateUtils";
import {dateTemplate, getDatesInRange, makeCustomDateFromDate} from "./AbsenceRequestAssistance";

interface IAbsenceRequestFormProps {
    hide: () => void,
    initialValues: AbsenceRequest
    addNewDataToTable: React.Dispatch<any>
}

function AbsenceRequestForm(props: IAbsenceRequestFormProps) {
    const [requestStatus, setRequestStatus] = useState<AbsenceRequestStatusType>();
    const [employees, setEmployees] = useState<Employee[]>();
    const [absenceRequestStatusTypes, setAbsenceRequestStatusTypes] = useState<AbsenceRequestStatusType[]>();
    const [absenceTypes, setAbsenceTypes] = useState<AbsenceType[]>();
    const [holidays, setHolidays] = useState<Holiday[]>();
    const [holidayOnForm, setHolidayOnForm] = useState<Date[] | undefined>();
    const [dates, setDates] = useState<Date[] | undefined>(props.initialValues && props.initialValues.dateFromToShowOnForm && props.initialValues.dateToToShowOnForm ? [props.initialValues.dateFromToShowOnForm, props.initialValues.dateToToShowOnForm] : undefined);
    const [absenceRequest, setAbsenceRequest] = useState(makeAbsenceRequest(props.initialValues ?? undefined))
    const [usedDays, setUsedDays] = useState<number>(0);

    useEffect(() => {
        getAllAbsenceTypes().then((res: AbsenceType[]) => {
            setAbsenceTypes(res);
        });
        getAllAbsenceRequestStatusTypes().then((res) => {
            setAbsenceRequestStatusTypes(res);
        });
        getAllEmployees().then((res) => {
            setEmployees(res);
        });
        getAllHolidays().then((res) => {
            setHolidays(res);
        }).then(() => {
            setHolidayOnForm(holidays?.reduce((acc: Date[], h: Holiday) => {
                if (h?.dateToShowOnForm !== undefined) {
                    acc.push(h.dateToShowOnForm);
                }
                return acc;
            }, []));
        });

    }, [])

    useEffect(() => {
        if (formik.values.absenceRequestStatusType) {
            setRequestStatus(formik.values.absenceRequestStatusType);
        }
    }, [absenceRequestStatusTypes])

    useEffect(() => {
        if (dates !== undefined && dates !== null && dates[1] !== undefined && dates[1] !== null) {
            let datesInRange = getDatesInRange(dates[0], dates[1]);
            let number = 0;

            formik.values.dateFromToShowOnForm = dates[0];
            formik.values.dateToToShowOnForm = dates[1];

            formik.values.dateFrom = convertFromDate(dates[0]);
            formik.values.dateTo = convertFromDate(dates[1]);

            for (let obj in datesInRange) {
                let customDate = makeCustomDateFromDate(datesInRange[obj]).show;
                if (!holidays?.map(x => x.date).includes(customDate)) {
                    if (datesInRange[obj].getDay() != 0 && datesInRange[obj].getDay() != 6) {
                        number++;
                    }
                }
            }
            setUsedDays(number);
        } else {
            setUsedDays(0);
        }
    }, dates);

    useEffect(() => {
        formik.setFieldValue('usedDays', usedDays)
    }, [usedDays])

    const formik = useFormik({
        initialValues: absenceRequest!,
        validate: (values: AbsenceRequest) => {
            let errors: FormikErrors<AbsenceRequest> = {};

            if (!values.absenceType) {
                errors.absenceType = 'Tip zahtjeva je obavezno polje.';
            }
            if (!values.employee) {
                errors.employee = 'Obavezno je izabrati zaposlenika.';
            }
            if (!values.dateFrom) {
                errors.dateFrom = 'Datum od do je obavezno polje.';
            } else if (!values.dateTo) {
                errors.dateTo = 'Datum od do je obavezno polje.';
            } 
            if(values.usedDays! > formik.values.employee?.currentYearRemainingDays! + formik.values.employee?.lastYearRemainingDays! && formik.values.absenceType?.id === 1){
                errors.dateFrom = 'Odabrani zaposleni ima ' + (formik.values.employee?.currentYearRemainingDays! + formik.values.employee?.lastYearRemainingDays!) + ' dana godišnjeg odmora na raspolaganju.' 
            } else if(values.usedDays! > 30 && values.absenceType?.enumName === 'VACATION'){
                errors.dateFrom = 'Za tip zahtjeva "Godišnji odmor" ne može se odabrati broj dana veći od 30.'
            } else if(values.usedDays! > 10 && values.absenceType?.enumName === 'EDUCATION'){
                errors.dateFrom = 'Za tip zahtjeva "Edukacija" ne može se odabrati broj dana veći od 10.'
            }

            return errors;
        },
        onSubmit:
            async values => {
                values = revertAbsenceRequest(values);

                values.usedDays = usedDays;
                values.id = undefined;
                values.absenceCreationDate = getTodaysDateAsString();
                await create(`ego/admin/absenceRequest`, values).then(res => res.status === 201 ? props.addNewDataToTable(makeAbsenceRequest(res.data)) : undefined);

                formik.resetForm();

                props.hide();

            },
    });

    useEffect(() => {
        setDates(undefined);
    }, [formik.values.absenceType])

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    return (

        <form>
            <div>
                <div style={{ border: "transparent" }}>
                    <div className="p-fluid grid" style={{ paddingTop: '20px' }}>
                        <div className="field col-4">
                            <span className="p-float-label">
                            <Dropdown id="absenceType" optionLabel="name" name="absenceTypeName"
                                value={formik.values.absenceType}
                                options={absenceTypes}
                                onChange={(e) => {
                                    formik.setFieldValue('absenceType', e.value);
                                }
                                } />
                            <label htmlFor="absenceType">Tip zahtjeva</label>
                                    
                            </span>
                            {getFormErrorMessage('absenceType')}

                        </div>

                        <div className="field col-4">
                            <span className="p-float-label">
                            <Dropdown id="employee" optionLabel="displayName" name="employee"
                                    dataKey="displayName" filter
                                    value={formik.values.employee}
                                    options={employees}
                                    onChange={(e) => {
                                        formik.setFieldValue('employee', e.value);
                                    }
                                    } />
                                <label htmlFor="employee">Zaposlenik</label>
                            </span>
                            {getFormErrorMessage('employee')}

                        </div>

                        <div className="col-8" />
                        <div className="field col-5">
                            <span className="p-float-label">
                                <Calendar showButtonBar name="calendar" value={dates} onChange={(e) => setDates(e.value as Date[])}
                                    dateFormat="dd.mm.yy." selectionMode="range" id="calendar"
                                    dateTemplate={(e) => dateTemplate(e, holidays)}
                                    disabledDays={[0, 6]} disabledDates={holidayOnForm ?? undefined}/>
                                <label htmlFor="calendar"
                                    className={classNames({ 'p-error': isFormFieldValid('calendar') })}>Datum od do</label>
                            </span>
                            {getFormErrorMessage('dateFrom')}
                            {getFormErrorMessage('dateTo')}

                        </div>

                        <div className="field col-3 col-offset-4">
                            <InputText style={{border: "transparent", boxShadow: "none"}}
                                       value={"Broj dana: " + usedDays} onChange={formik.handleChange}/>
                        </div>
                        <div className="field col-12">
                            <span className="p-float-label">
                                <InputTextarea id="textarea" value={formik.values.comment} rows={3}
                                    onChange={formik.handleChange} name="comment"/>
                                <label htmlFor="textarea">Poruka</label>
                            </span>
                        </div>
                    </div>
                </div>
                <DialogFooter hide={props.hide} onClick={formik.handleSubmit} />
            </div>
        </form>
    );
}

export default AbsenceRequestForm;