import {convertFromDate} from "../shared/utils/DateUtils";

export class TwoDatesDTO {
    dateFrom: string | undefined;
    dateTo: string | undefined;

    constructor(dateFrom: Date, dateTo: Date) {
        this.dateFrom = convertFromDate(dateFrom);
        this.dateTo = convertFromDate(dateTo);
    }
}
