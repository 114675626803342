import Keycloak from "keycloak-js";

const keycloakEnv = process.env.REACT_APP_KEYCLOAK_ENV;

const _kc = new Keycloak(`${process.env.PUBLIC_URL}/keycloak.${keycloakEnv}.json`);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback: (name: type) => returntype
 * 
 */
const initKeycloak = (onAuthenticatedCallback: () => void) => {
  _kc.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: `${window.location.origin}/${process.env.REACT_APP_URL_PREFIX}/silent-check-sso.html`,
    pkceMethod: 'S256',
    checkLoginIframe: false
  })
    .then((authenticated) => {
      if (!authenticated) {
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => { return _kc.token };

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback: () => void) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getEmail = () => _kc.tokenParsed?.email;

const hasRole = (roles: string[]) => roles.some((role: string) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getEmail
};

export default UserService;