import {FormikErrors, useFormik} from "formik";
import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {classNames} from "primereact/utils";
import {useEffect, useState} from "react";
import AbsenceRequest, {makeAbsenceRequest, revertAbsenceRequest} from "../../../models/AbsenceRequest";
import AbsenceRequestStatusType, {getAllAbsenceRequestStatusTypes} from "../../../models/AbsenceRequestStatusType";
import AbsenceType, {getAllAbsenceTypesForUser} from "../../../models/AbsenceType";
import Holiday from "../../../models/Holiday";
import {getAllHolidays} from "../../../shared/api/GetFormData";
import {create} from "../../../shared/api/endpoints/CrudApi";
import DialogFooter from "../../../shared/components/generic/dialog/DialogFooter";
import {convertFromDate, isWeekend} from "../../../shared/utils/DateUtils";
import {
    dateTemplate,
    getDatesInRange,
    makeCustomDateFromDate
} from "../../admin/absence-request/AbsenceRequestAssistance";
import Employee, {getEmployee} from "../../../models/Employee";
import moment from "moment";

interface IAbsenceRequestFormProps {
    hide: () => void,
    initialValues: AbsenceRequest
    addNewDataToTable: React.Dispatch<any>
}

function MyAbsenceRequestForm(props: IAbsenceRequestFormProps) {
    const [requestStatus, setRequestStatus] = useState<AbsenceRequestStatusType>();
    const [requestType, setRequestType] = useState<AbsenceType | undefined>(props.initialValues ? props.initialValues.absenceType : undefined);

    const [absenceRequestStatusTypes, setAbsenceRequestStatusTypes] = useState<AbsenceRequestStatusType[]>();
    const [absenceTypes, setAbsenceTypes] = useState<AbsenceType[]>();
    const [holidays, setHolidays] = useState<Holiday[]>();
    const [usedDays, setUsedDays] = useState<number>(0);
    const [dates, setDates] = useState<Date[] | undefined>(props.initialValues && props.initialValues.dateFromToShowOnForm && props.initialValues.dateToToShowOnForm ? [props.initialValues.dateFromToShowOnForm, props.initialValues.dateToToShowOnForm] : undefined);
    const [absenceRequest, setAbsenceRequest] = useState<AbsenceRequest>(makeAbsenceRequest(props.initialValues ?? undefined));
    const [employee, setEmployee] = useState<Employee | undefined>(new Employee());
    const [disabledDates, setDisabledDates] = useState<Date[] | undefined>();

    useEffect(() => {
        getAllAbsenceTypesForUser().then((res: AbsenceType[]) => {
            setAbsenceTypes(res);
        });
        getAllAbsenceRequestStatusTypes().then((res) => {
            setAbsenceRequestStatusTypes(res);
        });
        getEmployee().then((res) => {
            setEmployee(res);
        });
        getAllHolidays().then((res) => {
            setHolidays(res);
        }).then(() => {
            setDisabledDates(holidays?.reduce((acc: Date[], h: Holiday) => {
                if (h?.dateToShowOnForm !== undefined) {
                    acc.push(h.dateToShowOnForm);
                }
                return acc;
            }, []));
        });
    }, [])
    useEffect(() => {
        formik.setFieldValue("absenceType", requestType);
    }, [requestType])

    useEffect(() => {
        if (holidays) {
            setDisabledDates(getDisabledDates());
        }
    }, [holidays])


    useEffect(() => {
        if (formik.values.absenceType) {
            setRequestType(formik.values.absenceType);
        }
    }, [absenceTypes])

    useEffect(() => {
        if (formik.values.absenceRequestStatusType) {
            setRequestStatus(formik.values.absenceRequestStatusType);
        }
    }, [absenceRequestStatusTypes])

    useEffect(() => {
        formik.setFieldValue('usedDays', usedDays)
    }, [usedDays])

    function setDatesFromCalendar(dates: Date[]) {
        formik.values.dateFromToShowOnForm = dates[0];
        formik.values.dateToToShowOnForm = dates[1];

        formik.values.dateFrom = convertFromDate(dates[0]);
        formik.values.dateTo = convertFromDate(dates[1]);
    }

    useEffect(() => {
        if (dates !== undefined && dates !== null && dates[1] !== undefined && dates[1] !== null) {
            setDatesFromCalendar(dates);
            let number = 0;
            let datesInRange = getDatesInRange(dates[0], dates[1]);

            for (let obj in datesInRange) {
                let customDate = makeCustomDateFromDate(datesInRange[obj]).show;
                if (!holidays?.map(x => x.date).includes(customDate)) {
                    if (datesInRange[obj].getDay() != 0 && datesInRange[obj].getDay() != 6) {
                        number++;
                    }
                }
            }

            setUsedDays(number);
        } else {
            setUsedDays(0);
        }
    }, dates);

    const formik = useFormik({
        initialValues: absenceRequest!,
        validate: (values: AbsenceRequest) => {
            let errors: FormikErrors<AbsenceRequest> = {};

            if (!values.absenceType) {
                if (requestType) {
                    values.absenceType = requestType;
                } else {
                    errors.absenceType = 'Tip zahtjeva je obavezno polje.';
                }
            }

            if (!values.dateFrom) {
                errors.dateFrom = 'Datum od do je obavezno polje.';
            } else if (!values.dateTo) {
                errors.dateTo = 'Datum od do je obavezno polje.';
            }
            if (values.usedDays! > employee?.currentYearRemainingDays! + employee?.lastYearRemainingDays! && formik.values.absenceType?.id === 1) {
                errors.dateFrom = 'Odabrani zaposleni ima ' + (employee?.currentYearRemainingDays! + employee?.lastYearRemainingDays!) + ' dana godišnjeg odmora na raspolaganju.'
            } else if (values.usedDays! > 30 && values.absenceType?.enumName === 'VACATION') {
                errors.dateFrom = 'Za tip zahtjeva "Godišnji odmor" ne može se odabrati broj dana veći od 30.'
            } else if (values.usedDays! > 10 && values.absenceType?.enumName === 'EDUCATION') {
                errors.dateFrom = 'Za tip zahtjeva "Edukacija" ne može se odabrati broj dana veći od 10.'
            }

            return errors;
        },

        onSubmit:
            async values => {
                if (requestType) {
                    values.absenceType = requestType;
                }

                values = revertAbsenceRequest(values);
                values.usedDays = usedDays;


                values.id = undefined;
                await create(`ego/user/absenceRequest`, values).then(res => props.addNewDataToTable(makeAbsenceRequest(res.data)));

                formik.resetForm();

                props.hide();

            },
    });

    function isDateHoliday(date: Date): boolean {
        let flag: boolean = false;
        holidays?.forEach((h: any) => {
            let d1 = moment(h.dateToShowOnForm).format('DD-MM-YYYY');
            let d2 = moment(date).format('DD-MM-YYYY');
            if (d1 === d2) {
                flag = true;
            }
        })
        return flag;
    }

    function getDisabledDates() {
        let datesArray = [];

        let currentDate = moment();
        let currentMonth = currentDate.month();
        let currentYear = currentDate.year();

        let firstDay = new Date(currentYear, currentMonth, 1);

        let nextMonth = new Date(currentYear, currentMonth + 1, 1);
        let lastDay = new Date(moment(nextMonth).subtract(1).toDate());
        datesArray = [];
        for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
            if (!isDateHoliday(day) && !isWeekend(day)) {

                datesArray.push(new Date(day));

            }
        }
        if (new Date() >= datesArray[datesArray.length - 4]) {
            const datesArrayForRet: Date[] = [];
            const startDate = datesArray[datesArray.length - 4];
            while (datesArray[datesArray.length - 4].getMonth() === new Date().getMonth()) {
                datesArrayForRet.push(new Date(startDate));
                startDate.setDate(startDate.getDate() + 1)
            }
            return datesArrayForRet;
        }
        return undefined;
    }

    useEffect(() => {
        setDates(undefined);
    }, [formik.values.absenceType])

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };
    return (

        <form>
            <div>
                <div style={{border: "transparent"}}>
                    <div className="p-fluid grid" style={{paddingTop: '20px'}}>
                        <div className="field col-4">
                            <span className="p-float-label">
                            <Dropdown id="requestType" optionLabel="name" name="absenceTypeName"
                                      value={requestType}
                                      options={absenceTypes}
                                      onChange={(e) => {
                                          setRequestType(e.value);
                                          formik.setFieldValue('absenceType', e.value);
                                      }
                                      }/>
                            <label htmlFor="requestType">Tip zahtjeva</label>
                                </span>
                            {getFormErrorMessage('absenceType')}

                        </div>

                        <div className="col-8"/>
                        <div className="field col-5">
                            <span className="p-float-label">
                                <Calendar showButtonBar name="calendar" value={dates}
                                          onChange={(e) => setDates(e.value as Date[])}
                                          dateFormat="dd.mm.yy." selectionMode="range" id="calendar"
                                          dateTemplate={(e) => dateTemplate(e, holidays)}
                                          disabledDays={[0, 6]}
                                          disabledDates={disabledDates}/>
                                <label htmlFor="calendar"
                                       className={classNames({'p-error': isFormFieldValid('calendar')})}>Datum od do</label>
                            </span>
                            {getFormErrorMessage('dateFrom')}
                            {getFormErrorMessage('dateTo')}

                        </div>

                        <div className="field col-3 col-offset-4">
                            <InputText readOnly={true} style={{border: "transparent", boxShadow: "none"}}
                                       value={"Broj dana: " + usedDays} onChange={formik.handleChange}/>
                        </div>
                        <div className="field col-12">
                            <span className="p-float-label">
                                <InputTextarea id="textarea" value={formik.values.comment} rows={3}
                                               onChange={formik.handleChange} name="comment"
                                />
                                <label htmlFor="textarea">Poruka</label>
                            </span>
                        </div>
                    </div>
                </div>
                <DialogFooter hide={props.hide} onClick={formik.handleSubmit}
                              isDisabledOnUpdate={formik.values.absenceRequestStatusType?.enumName === "APPROVED" || formik.values.absenceRequestStatusType?.enumName === "DENIED"}/>
            </div>
        </form>
    );
}

export default MyAbsenceRequestForm;