import {useState} from "react";
import Project from "../../../models/Project";
import {getAllProjects} from "../../../shared/api/GetFormData";
import GenericDialog from "../../../shared/components/generic/dialog/GenericDialog";
import GenericTable, {TableOptions} from "../../../shared/components/generic/table/GenericTable";
import ProjectForm from "./ProjectForm";
import ProjectTemplate from "./ProjectTemplate";

const ProjectList = () => {

    const [visibilityNewRequest, setVisibilityNewRequest] = useState(false);
    const [initialValues, setInitialValues] = useState<any | undefined>(undefined);
    const [dataFromChildCreate, setDataFromChildCreate] = useState<any | undefined>(undefined);
    const [visibilityColumnSelectionModal, setVisibilityColumnSelectionModal] = useState<boolean>(false);
    const [initialValuesColumnSelection, setInitialValuesColumnSelection] = useState<any | undefined>(undefined);

    const onHideColumnSelectionModal = () => {
        if (visibilityColumnSelectionModal) {
            setVisibilityColumnSelectionModal(false);
        }
    };

    const onHideNewRequest = () => {
        if (visibilityNewRequest) {
            setVisibilityNewRequest(false);
        }
    };

    const clientsTableOptions = {
        options: {
            title: "Pregled projekata",
            toFetch: [getAllProjects()],
            columns: {
                headers: ["Naziv", "Krajnji korisnik", "Naručitelj", "Početak", "Tip", "Kraj", "Kontakt osoba"],
                fieldNames: ["name", "clientToShipName", "clientToBillName", "startDate", "type", "endDate", "contactPersonName"],
                fieldTypes: ["string", "string", "string", "date", "string", "date", "string"],
                searchByFieldNames: ["name", "clientToShipName", "clientToBillName", "startDate", "type", "endDate", "contactPersonName"],
            },
            hasHeader: false,
            isDataMutable: true,
            expansionTemplate: (params: {e: Project, handleDataFromChild: () => void}) => <ProjectTemplate entity={params.e} handleDataFromChild={params.handleDataFromChild}/>,
            modalOptions: {
                modals: [
                    <GenericDialog key="modal-new" headerText="Novi projekt" onHide={onHideNewRequest}
                                   visible={visibilityNewRequest}
                                   hasFooter={false}
                                   children={<ProjectForm hide={onHideNewRequest} initialValues={initialValues} addNewDataToTable={setDataFromChildCreate}
                                                          />}/>,

                ],
                hideVariables: [
                    visibilityNewRequest,
                ],
                hideSetters: [
                    setVisibilityNewRequest,
                ],
                hideFunctions: [
                    onHideNewRequest,
                ],
                initialValues: {
                    initialValues: initialValues,
                    setInitialValues: setInitialValues
                },
                newValue: dataFromChildCreate
            },
            columnSelection:{
                headers: ["Naziv", "Krajnji korisnik", "Naručitelj", "Početak"],
                fieldNames: ["name", "clientToShipName", "clientToBillName", "startDate"],
                hideVariables:visibilityColumnSelectionModal,
                hideSetters:setVisibilityColumnSelectionModal,
                hideFunctions:onHideColumnSelectionModal,
                initialValues:{
                    initialValues:initialValuesColumnSelection,
                    setInitialValues:setInitialValuesColumnSelection
                }
            }
        }
    } as TableOptions;

    return (
        <div>
            <GenericTable options={clientsTableOptions.options}></GenericTable>
        </div>
    );
}

export default ProjectList;