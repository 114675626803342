import {FormikErrors, useFormik} from "formik";
import {Calendar} from "primereact/calendar";
import React, {useEffect, useState} from "react";
import DialogFooter from "../../../shared/components/generic/dialog/DialogFooter";
import {classNames} from "primereact/utils";
import Project from "../../../models/Project";
import {convertFromDate} from "../../../shared/utils/DateUtils";
import {downloadFile} from "../../../shared/api/endpoints/CrudApi";
import Employee from "../../../models/Employee";
import {Divider} from "primereact/divider";
import {EButtonIcons} from "../../../shared/components/generic/button/GenericButton";
import {IClassicExcelTemplateProps, MainExcelInfo} from "./GenericExport";
import {getAllEmployees, getAllProjects} from "../../../shared/api/GetFormData";
import {MultiSelect} from "primereact/multiselect";


/**
 * Has many employees and many projects for selecting
 * @param props ClassicExcelTemplateProps
 * @constructor
 */
function ClassicExcelTemplate(props: IClassicExcelTemplateProps) {

    const [dateFrom, setDateFrom] = useState<any>(undefined);
    const [dateTo, setDateTo] = useState<any>(undefined);
    const [value,] = useState<MainExcelInfo>(MainExcelInfo.getBlank(props.columnValues));
    const [projects, setProjects] = useState<Project[]>();
    const [selectedProjects, setSelectedProjects] = useState<Employee[]>();
    const [employees, setEmployees] = useState<Employee[]>();
    const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>();

    useEffect(() => {
        getAllProjects().then((res) => {
            setProjects(res);
        });
        getAllEmployees().then((res) => {
            setEmployees(res);
        });
    }, []);

    function setDatesFrom(date: Date) {
        setDateFrom(date);
        formik.setFieldValue("dateFrom", convertFromDate(date));
        formik.setFieldValue("dateFromToShowOnForm", date);
    }

    function setDatesTo(date: Date) {
        setDateTo(date);
        formik.setFieldValue("dateTo", convertFromDate(date));
        formik.setFieldValue("dateToToShowOnForm", date);
    }

    function setProject(project: Project) {
        formik.setFieldValue("project", project);
    }

    const formik = useFormik({
        initialValues: value,
        validate: (values: MainExcelInfo) => {
            let errors: FormikErrors<MainExcelInfo> = {};
            if (!values.dateFrom) {
                errors.dateFrom = "Datum od je obavezno polje.";
            }
            if (!values.dateTo) {
                errors.dateTo = "Datum do je obavezno polje."
            }
            if (!values.projects) {
                errors.projects = "Obavezno je izabrati barem jedan projekt.";
            }
            if (!values.employees) {
                errors.employees = "Obavezno je izabrati barem jednog zaposlenika."
            }
            return errors;
        },

        onSubmit:
            async values => {
                values = MainExcelInfo.revertMainExcelInfo(values);
                await downloadFile(props.url, values);

                props.hide();
            },
    });

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    return (

        <form>
            <div>
                <div style={{border: "transparent"}}>
                    <div className="p-fluid grid" style={{paddingTop: '20px'}}>
                        <div className="field col-6">
                                <span className="p-float-label">
                                    <MultiSelect id="projects" value={selectedProjects}
                                                 options={projects}
                                                 onChange={(e) => {
                                                     let all = formik.values.projects;
                                                     all = [...e.value];
                                                     setSelectedProjects(all);
                                                     formik.setFieldValue("projects", all);
                                                 }} optionLabel="name" name="name"
                                                 dataKey="name" filter/>
                                           <label htmlFor="projects"
                                                  className={classNames({'p-error': isFormFieldValid('projects')})}>Projekti</label>
                                </span>
                        </div>
                        <div className="field col-6">
                                <span className="p-float-label">
                                    <MultiSelect id="employees" value={selectedEmployees}
                                                 options={employees}
                                                 onChange={(e) => {
                                                     let all = formik.values.employees;
                                                     all = [...e.value];
                                                     setSelectedEmployees(all);
                                                     formik.setFieldValue("employees", all);
                                                 }} filter dataKey="displayName"
                                                 optionLabel="displayName"/>
                                          <label htmlFor="employees"
                                                 className={classNames({'p-error': isFormFieldValid('employees')})}>Zaposlenik</label>
                                </span>
                        </div>
                        {/*<div className="field col-5 md:col-5 col-offset-1">
                                <span className="p-float-label">
                                    <MultiSelect id="project" optionLabel="name" name="name"
                                              dataKey="name" filter
                                              value={formik.values.projects}
                                              options={projects}
                                              onChange={(e) => {
                                                  setProject(e.value);
                                                  formik.setFieldValue('project', e.value);
                                              }}/>
                                    <label htmlFor="project"
                                           className={classNames({'p-error': isFormFieldValid('project')})}>Projekt</label>
                                </span>
                            {getFormErrorMessage('project')}
                        </div>
                        <div className="field  col-5 md:col-5 ">
                            <span className="p-float-label">
                                <MultiSelect id="employee" optionLabel="displayName" name="employee"
                                          dataKey="displayName" filter
                                          value={formik.values.employees}
                                          options={employees}
                                          onChange={(e) => {
                                              formik.setFieldValue('employee', e.value);
                                          }
                                          }/>
                                <label htmlFor="employee"
                                       className={classNames({'p-error': isFormFieldValid('employee')})}>Zaposlenik</label>
                            </span>
                            {getFormErrorMessage('employee')}
                        </div>*/}
                        <Divider/>
                        <div className="field col-5 md:col-5 col-offset-1">
                            <span className="p-float-label">
                                <Calendar name="dateFrom" id="dateFrom"
                                          dateFormat="dd.mm.yy."
                                          value={dateFrom}
                                          onChange={(e) => setDatesFrom(e.value as Date)}/>
                                <label htmlFor="dateFrom"
                                       className={classNames({'p-error': isFormFieldValid('dateFrom')})}>Datum od</label>
                            </span>
                            {getFormErrorMessage('dateFrom')}
                        </div>
                        <div className="field col-5 md:col-5">
                            <span className="p-float-label">
                                  <Calendar name="dateTo" id="dateTo"
                                            dateFormat="dd.mm.yy."
                                            value={dateTo}
                                            onChange={(e) => setDatesTo(e.value as Date)}/>
                                  <label htmlFor="dateTo"
                                         className={classNames({'p-error': isFormFieldValid('dateTo')})}>Datum do</label>
                            </span>
                            {getFormErrorMessage('dateTo')}
                        </div>
                    </div>
                </div>

                <DialogFooter
                    hide={props.goBack}
                    onClick={formik.handleSubmit}
                    firstButtonLabel="Nazad"
                    firstButtonIcon={EButtonIcons.ANGLE_LEFT}
                    secondButtonLabel="Download"/>
            </div>
        </form>
    );
}

export default ClassicExcelTemplate;