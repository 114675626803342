import Client, {makeClient} from "./Client";

export default class ContactPerson {
    id?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    note?: string;
    client?: Client;
    name?: string;
    clientId?: number;
    clientName?: string;
    isActive?: boolean;

    constructor(id?: number, firstName?: string, lastName?: string, email?: string, phoneNumber?: string, note?: string, clientId?: number, name?: string, client?: Client, clientName?: string, isActive?: boolean) {
        this.id = id ?? undefined;
        this.firstName = firstName ?? '';
        this.lastName = lastName ?? '';
        this.email = email ?? '';
        this.phoneNumber = phoneNumber ?? '';
        this.note = note ?? '';
        this.client = client ?? undefined;
        this.name = name ?? '';
        this.clientId = clientId ?? undefined;
        this.clientName = clientName ?? undefined;
        this.isActive = isActive ?? undefined;
    }
}

export function makeContactPerson(contactPerson: ContactPerson | undefined) {
    if (contactPerson === undefined) {
        return new ContactPerson();
    }

    if (contactPerson.firstName && contactPerson.lastName) {
        contactPerson.name = contactPerson.firstName + ' ' + contactPerson.lastName;
    }
    if (contactPerson.client) {
        contactPerson.client = makeClient(contactPerson.client);
        contactPerson.clientName = contactPerson.client.name;
    }

    return contactPerson;
}

export function revertContactPerson(contactPerson: ContactPerson | undefined) {
    if (contactPerson === undefined) {
        return new ContactPerson();
    }
    
    contactPerson.isActive = true;

    return contactPerson;
}