import {useState} from "react";
import ContactPerson from "../../../models/ContactPerson";
import {getAllContactPersons} from "../../../shared/api/GetFormData";
import GenericDialog from "../../../shared/components/generic/dialog/GenericDialog";
import GenericTable, {TableOptions} from "../../../shared/components/generic/table/GenericTable";
import ContactPersonForm from "./ContactPersonForm";
import ContactPersonTemplate from "./ContactPersonTemplate";

const ContactPersonList = () => {

    const [visibilityNewRequest, setVisibilityNewRequest] = useState(false);
    const [initialValues, setInitialValues] = useState<any | undefined>(undefined);
    const [dataFromChildCreate, setDataFromChildCreate] = useState<any | undefined>(undefined);
    const [visibilityColumnSelectionModal, setVisibilityColumnSelectionModal] = useState<boolean>(false);
    const [initialValuesColumnSelection, setInitialValuesColumnSelection] = useState<any | undefined>(undefined);

    const onHideColumnSelectionModal = () => {
        if (visibilityColumnSelectionModal) {
            setVisibilityColumnSelectionModal(false);
        }
    };

    const onHideNewRequest = () => {
        if (visibilityNewRequest) {
            setVisibilityNewRequest(false);
        }
    };

    const clientsTableOptions = {
        options: {
            title: "Pregled kontaktnih osoba",
            toFetch: [getAllContactPersons()],
            columns: {
                headers: ["Ime", "Prezime", "Klijent", "Email", "Aktivan", "Broj mobitela"],
                fieldNames: ["firstName", "lastName", "clientName", "email", "isActive", "phoneNumber"],
                fieldTypes: ["string", "string", "string", "string", "boolean", "string"],
                searchByFieldNames: ["firstName", "clientName", "lastName", "email"],
            },
            hasHeader: false,
            isDataMutable: true,
            expansionTemplate: (params: {e: ContactPerson, handleDataFromChild: () => void}) => <ContactPersonTemplate entity={params.e} handleDataFromChild={params.handleDataFromChild}/>,
            modalOptions: {
                modals: [
                    <GenericDialog key="modal-new" headerText="Nova kontaktna osoba" onHide={onHideNewRequest}
                                   visible={visibilityNewRequest}
                                   hasFooter={false}
                                   children={<ContactPersonForm hide={onHideNewRequest} initialValues={initialValues} addNewDataToTable={setDataFromChildCreate}
                                                                />}/>,
                ],
                hideVariables: [
                    visibilityNewRequest,
                ],
                hideSetters: [
                    setVisibilityNewRequest,
                ],
                hideFunctions: [
                    onHideNewRequest,
                ],
                initialValues: {
                    initialValues: initialValues,
                    setInitialValues: setInitialValues
                },
                newValue: dataFromChildCreate
            },
            columnSelection:{
                headers: ["Ime", "Prezime", "Klijent", "Email", "Aktivan"],
                fieldNames: ["firstName", "lastName", "clientName", "email", "isActive"],
                hideVariables:visibilityColumnSelectionModal,
                hideSetters:setVisibilityColumnSelectionModal,
                hideFunctions:onHideColumnSelectionModal,
                initialValues:{
                    initialValues:initialValuesColumnSelection,
                    setInitialValues:setInitialValuesColumnSelection
                }
            }
        }
    } as TableOptions;

    return (
        <div>
            <GenericTable options={clientsTableOptions.options}></GenericTable>
        </div>
    );
}

export default ContactPersonList;