import React from 'react';
import AppMenuitem from './AppMenuitem';
import {MenuProvider} from './context/menucontext';
import KeycloakService from "../shared/services/KeycloakService";
import {EButtonIcons} from "../shared/components/generic/button/GenericButton";


interface SideMenuItem {
    label: string;
    icon?: string;
    to?: string;
    items?: Array<SideMenuItem>;
    badge?: string;
    disabled?: boolean;
    visible?: boolean;
    command?: any;
}

const AppMenu = () => {

    const admin: Array<SideMenuItem> = [
        {
            label: "Admin",
            items: [
                {
                    label: 'Pregled', icon: EButtonIcons.ID_CARD, to: `/admin/overview`,
                },
                {
                    label: 'CRM',
                    icon: EButtonIcons.MONEY_BILL,
                    to: undefined,
                    items: [
                        {label: 'Narudžbenice', icon: EButtonIcons.MY_ABSENCE_TIME, to: `/crm/journals`},
                        {label: 'Klijenti', icon: EButtonIcons.EMPLOYEES, to: `/crm/clients`},
                        {label: 'Kont. osobe ', icon: EButtonIcons.LINK, to: `/crm/contactpersons`},
                    ]
                },
                {label: 'Projekti', icon: EButtonIcons.MY_ABSENCE_TIME, to: `/projects`},
                {
                    label: 'Radno vrijeme',
                    icon: EButtonIcons.CLOCK,
                    to: undefined,
                    items: [
                        {label: 'Evidencija', icon: EButtonIcons.LIST, to: `/summoftimesheets`},
                        {label: 'Unos', icon: EButtonIcons.UPDATE, to: `/timesheetentry`}
                    ]
                },
                {
                    label: 'Odsutstva',
                    icon: EButtonIcons.SEND,
                    to: undefined,
                    items: [
                        {label: 'Evidencija', icon: EButtonIcons.LIST, to: `/summofabsence`},
                        {label: 'Unos', icon: EButtonIcons.UPDATE, to: `/absenceentry`},
                    ]
                },
                {label: 'Knjiga djelatnika', icon: EButtonIcons.BOOK, to: `/admin/workers`,},
                {label: 'Moj profil', icon: 'pi pi-user ', to: `/my_info`},
                {
                    label: 'Postavke', icon: EButtonIcons.SETTINGS_ROUND, to: undefined,
                    items: [
                        {label: 'Blagdani', icon: EButtonIcons.CALENDAR, to: `/settings/holidays`},
                        {label: 'Tipovi podataka', icon: EButtonIcons.SEARCH, to: `/settings/datatypes`}]
                }
            ]
        }];

    const user: Array<SideMenuItem> = [
        {
            label: "User",
            items: [
                {label: 'Pregled', icon: EButtonIcons.ID_CARD, to: `/user/overview`},
                {label: 'Moji zahtjevi', icon: EButtonIcons.SEND, to: `/user/myabsencerequests`},
                {label: 'Moja evidencija', icon: EButtonIcons.CLOCK, to: `/user/mytimesheet`},
                {label: 'Moj profil', icon: EButtonIcons.ROBOTS_HEAD, to: `/user/my_info`},
            ]
        }];

    return (
        <MenuProvider>
            <ul className="layout-menu">
                {KeycloakService.hasRole(['admin']) ?
                    <>
                        {admin.map((item: SideMenuItem, i: number) =>
                            <AppMenuitem item={item} root={true} index={i} key={item.label}/>)}
                    </>
                    :
                    <>
                        {user.map((item: SideMenuItem, i: number) =>
                            <AppMenuitem item={item} root={true} index={i} key={item.label}/>)
                        }
                    </>
                }
            </ul>
        </MenuProvider>
    );
};

export default AppMenu;
