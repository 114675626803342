import {get} from "../shared/api/endpoints/CrudApi";

export default class AbsenceRequestType {
    id?: number;
    enumName: string;
    name?: string;
    roles?: string;

    constructor(id: number, enumName: string, name: string) {
        this.id = id;
        this.enumName = enumName;
        this.name = name;
    }
}

export const AbsenceType_Vacation = {
    id: 1,
    enumName: 'VACATION',
    name: "Godišnji odmor"
} as AbsenceRequestType;

export const AbsenceType_SickLeave = {
    id: 2,
    enumName: 'SICK_LEAVE',
    name: "Bolovanje"
} as AbsenceRequestType;

export const AbsenceType_Education = {
    id: 3,
    enumName: 'EDUCATION',
    name: "Edukacija"
} as AbsenceRequestType;

export async function getAllAbsenceTypesForUser() {
    return getAllAbsenceTypesFromBackend('user/ego/absenceType');
}

export async function getAllAbsenceTypes() {
    return getAllAbsenceTypesFromBackend('ego/absenceType');
}
export async function getAllAbsenceTypesFromBackend(path: string) {
    let res = await get(path)
        .then((response: any) => {
                if (response.status === 200) {
                    return response;
                } else {
                    return undefined;
                }
            }
        )
    let data;
    if (res !== undefined) {
        data = await res.data.absenceTypes;
    }
    return data;
}