import React, {useState} from "react";
import Employee from "../../../models/Employee";
import {getAllEmployees, getAllEmployeesByType} from "../../../shared/api/GetFormData";
import GenericDialog from "../../../shared/components/generic/dialog/GenericDialog";
import GenericTable, {TableOptions} from "../../../shared/components/generic/table/GenericTable";
import EmployeeForm from "./EmployeeForm";
import EmployeeTemplate from "./EmployeeTemplate";

interface EmployeeListProps {
    filter?: string;
}

const EmployeeList = (props: EmployeeListProps) => {

    const [visibilityNewRequest, setVisibilityNewRequest] = useState(false);
    const [initialValues, setInitialValues] = useState<any | undefined>(undefined);
    const [dataFromChildCreate, setDataFromChildCreate] = useState<any | undefined>(undefined);
    const [visibilityColumnSelectionModal, setVisibilityColumnSelectionModal] = useState<boolean>(false);
    const [initialValuesColumnSelection, setInitialValuesColumnSelection] = useState<any | undefined>(undefined);

    const onHideColumnSelectionModal = () => {
        if (visibilityColumnSelectionModal) {
            setVisibilityColumnSelectionModal(false);
        }
    };

    const onHideNewRequest = () => {
        if (visibilityNewRequest) {
            setVisibilityNewRequest(false);
        }
    };

    const fetchData = (filter?:string) => {
        switch(filter) {
            case "employee":
                return getAllEmployeesByType("employee");
            case "student":
                return getAllEmployeesByType("student");
            case "external":
                return getAllEmployeesByType("external");
            case "candidate":
                return getAllEmployeesByType("candidate");
            default:
                return getAllEmployees();

        }
    }

    const employeesTableOptions = {
        options: {
            title: "Pregled zaposlenika",
            toFetch: [fetchData(props.filter)],
            columns: {
                headers: ["Ime", "Prezime", "Korisničko ime", "Email", "Zaposlen od", "Sati u tekućem mj.", "Broj mobitela", "Radni odnos", "Pozicija zaposlenika", "Aktivan"],
                fieldNames: ["firstName", "lastName", "displayName", "email", "validFrom", "totalProjectHoursToShowOnList", "phoneNumber", "descriptionAttribute.name", "departmentNumberAttribute.name", "isActive"],
                fieldTypes: ["string", "string", "string", "string", "date", "numeric", "string", "string", "string", "boolean"],
                searchByFieldNames: ["firstName", "lastName", "email"],
            },
            hasHeader: false,
            isDataMutable: true,
            expansionTemplate: (params: {e: Employee, handleDataFromChild: () => void}) => <EmployeeTemplate entity={params.e} handleDataFromChild={params.handleDataFromChild}/>,
            modalOptions: {
                modals: [
                    <GenericDialog headerText="Novi zaposlenik" onHide={onHideNewRequest}
                        visible={visibilityNewRequest}
                        hasFooter={false} children={<EmployeeForm hide={onHideNewRequest} initialValues={initialValues} addNewDataToTable={setDataFromChildCreate} />} />,

                ],
                hideVariables: [
                    visibilityNewRequest,
                ],
                hideSetters: [
                    setVisibilityNewRequest,
                ],
                hideFunctions: [
                    onHideNewRequest,
                ],
                initialValues: {
                    initialValues: initialValues,
                    setInitialValues: setInitialValues
                },
                newValue: dataFromChildCreate
            },
            columnSelection: {
                headers: ["Ime", "Prezime", "Email", "Zaposlen od", "Sati u tekućem mj.", "Aktivan"],
                fieldNames: ["firstName", "lastName", "email", "validFrom", "totalProjectHours", "isActive"],
                hideVariables: visibilityColumnSelectionModal,
                hideSetters: setVisibilityColumnSelectionModal,
                hideFunctions: onHideColumnSelectionModal,
                initialValues: {
                    initialValues: initialValuesColumnSelection,
                    setInitialValues: setInitialValuesColumnSelection
                }
            },
            filter: props.filter
        },
    } as TableOptions;
    return (
        <div>
            <GenericTable options={employeesTableOptions.options} ></GenericTable>
        </div>
    );
}

export default EmployeeList;