import { edit, get } from "./CrudApi";

const userEndpoint = process.env.REACT_APP_USER_ENDPOINT;
const adminEndpoint = process.env.REACT_APP_ADMIN_ENDPOINT;
const egoEndpoint = process.env.REACT_APP_EGO_ENDPOINT;


function edit_user_ego(endpoint: string, id: number, objectToEdit: any) {
    return edit(`/${egoEndpoint}/${userEndpoint}/`+ endpoint, id, objectToEdit);
}

function resetAllHolidays(){
    return get(`${egoEndpoint}/holiday/official`);
}

export { edit_user_ego, resetAllHolidays };