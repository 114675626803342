import { FormikErrors, useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useState } from "react";
import Holiday from "../../models/Holiday";
import { create } from "../../shared/api/endpoints/CrudApi";
import DialogFooter from "../../shared/components/generic/dialog/DialogFooter";
import { convertFromDate } from "../../shared/utils/DateUtils";

interface IHolidayFormProps {
    hide: () => void,
    initialValues: Holiday
    addNewDataToTable: React.Dispatch<any>
}

function HolidayForm(props: IHolidayFormProps) {

    const [holiday, setHoliday] = useState({
        id: props.initialValues ? props.initialValues.id : 0,
        date: props.initialValues ? props.initialValues.date : '',
        name: props.initialValues ? props.initialValues.name : '',
        localName: props.initialValues ? props.initialValues.localName : '',
        dateToShowOnForm: props.initialValues ? props.initialValues.dateToShowOnForm : undefined
    })

    const formik = useFormik({
        initialValues: holiday!,
        validate: (values: Holiday) => {
            let errors: FormikErrors<Holiday> = {};

            if (!values.name) {
                errors.name = 'Ime je obavezno polje.';
            }

            if (!values.dateToShowOnForm) {
                errors.dateToShowOnForm = 'Datum blagdana je obavezno polje.';
            }

            return errors;
        },

        onSubmit:
            async values => {
                if (values.dateToShowOnForm) {
                    values.date = convertFromDate(values.dateToShowOnForm);
                }
                values.id = undefined;
                await create(`ego/holiday`, values).then(res => props.addNewDataToTable(res.data));
                formik.resetForm();

                props.hide();

            },
    });

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    return (
        <form>
            <div>
                <div style={{border: "transparent"}}>
                    <div className="p-fluid grid" style={{paddingTop: '20px'}}>
                        <div className="field col-6  md:col-6">
                            <span className="p-float-label">
                                <InputText id="name" name="name" value={formik.values.name}
                                           onChange={formik.handleChange} />
                                <label htmlFor="name"
                                       className={classNames({'p-error': isFormFieldValid('name')})}>Naziv</label>
                            </span>
                            {getFormErrorMessage('name')}
                        </div>
                        <div className="field col-6  md:col-6">
                            <span className="p-float-label">
                                <InputText id="localName" name="localName" value={formik.values.localName}
                                           onChange={formik.handleChange} />
                                <label htmlFor="localName">Lokalni naziv</label>
                            </span>
                        </div>
                        <div className="field col-6 md:col-6">
                            <span className="p-float-label">
                                <Calendar showButtonBar name="dateToShowOnForm" value={formik.values.dateToShowOnForm}
                                          onChange={formik.handleChange}
                                          dateFormat="dd.mm.yy." id="calendar"
                                          disabledDays={[0, 6]}/>
                                <label htmlFor="calendar">Odaberite datum početka</label>
                            </span>
                            {getFormErrorMessage('dateToShowOnForm')}
                        </div>
                    </div>
                </div>
                <DialogFooter hide={props.hide} onClick={formik.handleSubmit}/>
            </div>
        </form>
    );
}

export default HolidayForm;