import React from "react";
import GenericTabMenu, {TabMenuItem, TabMenuTab} from "../../shared/components/generic/tab-menu/GenericTabMenu";


const EmployeeTabMenu = (props: TabMenuTab) => {
    const tabItems = [
        {
            id: "0",
            label: "Svi",
            component: "EmployeeList"
        },
        {
            id: "1",
            label: "Zaposlenici",
            component: "EmployeeList-employee"
        },
        {
            id: "2",
            label: "Studenti",
            component: "EmployeeList-student"
        },
        {
            id: "3",
            label: "Vanjski",
            component: "EmployeeList-external"
        }
    ] as TabMenuItem[];

    return (
        <>
            <GenericTabMenu items={tabItems} activeTab={props.activeTab}/>
        </>
    )
}

export default EmployeeTabMenu;