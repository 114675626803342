import { get } from "./endpoints/CrudApi";

export async function getConfigApiKeys() {
    let res = await get('erv/admin/config')
        .then((response) => {
                if (response.status === 200) {
                    return response;
                } else {
                    return undefined;
                }
            }
        )
    let data;
    if (res !== undefined) {
        data = await res;
    }

    return data;
}