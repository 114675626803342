import {useState} from "react";
import Holiday from "../../models/Holiday";
import {getAllHolidays} from "../../shared/api/GetFormData";
import GenericDialog from "../../shared/components/generic/dialog/GenericDialog";
import GenericTable, {TableOptions} from "../../shared/components/generic/table/GenericTable";
import HolidayForm from "./HolidayForm";
import HolidayTemplate from "./HolidayTemplate";

const HolidayList = () => {

    const [visibilityNewRequest, setVisibilityNewRequest] = useState(false);
    const [initialValues, setInitialValues] = useState<any | undefined>(undefined);
    const [dataFromChildCreate, setDataFromChildCreate] = useState<any | undefined>(undefined);
    const [visibilityColumnSelectionModal, setVisibilityColumnSelectionModal] = useState<boolean>(false);
    const [initialValuesColumnSelection, setInitialValuesColumnSelection] = useState<any | undefined>(undefined);

    const onHideColumnSelectionModal = () => {
        if (visibilityColumnSelectionModal) {
            setVisibilityColumnSelectionModal(false);
        }
    };

    const onHideNewRequest = () => {
        if (visibilityNewRequest) {
            setVisibilityNewRequest(false);
        }
    };

    const clientsTableOptions = {
        options: {
            title: "Pregled blagdana",
            toFetch: [getAllHolidays()],
            columns: {
                headers: ["Naziv", "Datum"],
                fieldNames: ["localName", "date"],
                fieldTypes: ["string", "date"],
                searchByFieldNames: ["name", "date"],
            },
            hasHeader: false,
            isDataMutable: true,
            expansionTemplate: (params: { e: Holiday, handleDataFromChild: () => void }) => <HolidayTemplate
                entity={params.e} handleDataFromChild={params.handleDataFromChild}/>,
            modalOptions: {
                modals: [
                    <GenericDialog key="modal-new" headerText="Novi blagdan" onHide={onHideNewRequest}
                                   visible={visibilityNewRequest}
                                   hasFooter={false}
                                   children={<HolidayForm hide={onHideNewRequest} initialValues={initialValues}
                                                          addNewDataToTable={setDataFromChildCreate}/>}/>,
                ],
                hideVariables: [
                    visibilityNewRequest,
                ],
                hideSetters: [
                    setVisibilityNewRequest,
                ],
                hideFunctions: [
                    onHideNewRequest,
                ],
                initialValues: {
                    initialValues: initialValues,
                    setInitialValues: setInitialValues
                },
                newValue: dataFromChildCreate
            },
            columnSelection: {
                headers: ["Naziv", "Datum"],
                fieldNames: ["localName", "date"],
                hideVariables: visibilityColumnSelectionModal,
                hideSetters: setVisibilityColumnSelectionModal,
                hideFunctions: onHideColumnSelectionModal,
                initialValues: {
                    initialValues: initialValuesColumnSelection,
                    setInitialValues: setInitialValuesColumnSelection
                }
            }
        }
    } as TableOptions;

    return (
        <div>
            <GenericTable options={clientsTableOptions.options}></GenericTable>
        </div>
    );
}

export default HolidayList;