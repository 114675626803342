import {toast} from "react-toastify";
import HttpService from "../../services/HttpService";
import {AxiosError} from "axios";

const apiURL = process.env.REACT_APP_API_URL;

function get(api: string, headers?: {}) {
  const response = HttpService.getAxiosClient().get(`${apiURL}/${api}`, headers);
    toast.promise(
        response,
        {
            error: {
                icon: '😒',
                render({data}){
                    const casted = (data as AxiosError)
                    let castedData = (casted.response?.data as any);
                    if (castedData['message']){
                        return `${castedData['message']}`
                    } else {
                        return 'Dogodila se pogreška pri dohvaćanju resursa.'
                    }
                },
            }
        }
    )
  return response;
}

export function downloadFile(api: string, data: any) {
    const response = HttpService.getAxiosClient().post(`${apiURL}/${api}`, data,
        {
        responseType: 'blob',
    });

    toast.promise(
        response,
        {
            success: 'File downloaded successfully!',
            error: {
                icon: '😒',
                render({data}) {
                    const casted = data as AxiosError;
                    let castedData = casted.response?.data as any;
                    return castedData.message ? castedData.message : 'Error occurred while downloading the file.';
                },
            },
        }
    );
    let filename = "download.xlsx";

    response.then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }).catch(error => {
        console.error('Download error:', error);
    });

    return response;
}

function create(api: string, objectToCreate: any) {
  const response = HttpService.getAxiosClient().post(`${apiURL}/${api}`, objectToCreate);

  toast.promise(
    response,
    {
      pending: 'Učitavanje...',
      success: 'Uspješno!',
      error: {
        icon: '😒',
        render({data}){
          const casted = (data as AxiosError)
          let castedData = (casted.response?.data as any);
          if (castedData['message']){
            return `${castedData['message']}`
          } else {
            return 'Dogodila se pogreška, kontaktirajte administratora.'
          }
        },
      }
    }
  )

  return response;
}
function update(api: string, objectToUpdate: any) {
  const response = HttpService.getAxiosClient().put(`${apiURL}/${api}`, objectToUpdate);

  toast.promise(
    response,
    {
      pending: 'Učitavanje...',
      success: 'Uspješno!',
      error: {
        icon: '😒',
        render({data}){
          const casted = (data as AxiosError)
          let castedData = (casted.response?.data as any);
          if (castedData['message']){
            return `${castedData['message']}`
          } else {
            return 'Dogodila se pogreška, kontaktirajte administratora.'
          }
        },
      }
    }
  )

  return response;
}
export function post(api: string, dates: any) {
  const response = HttpService.getAxiosClient().post(`${apiURL}/${api}`, dates);

  toast.promise(
    response,
    {
      pending: 'Učitavanje...',
      success: 'Uspješno!',
      error: {
        icon: '😒',
        render({data}){
          const casted = (data as AxiosError)
          let castedData = (casted.response?.data as any);
          if (castedData['message']){
            return `${castedData['message']}`
          } else {
            return 'Dogodila se pogreška, kontaktirajte administratora.'
          }
        },
      }
    }
  )

  return response;
}

function getById(api: string, id: number) {
  const response = HttpService.getAxiosClient().get(`${apiURL}/${api}/${id}`);

  return response;
}

function edit(api: string, id: number, objectToEdit?: any) {
  const response = HttpService.getAxiosClient().put(`${apiURL}/${api}/${id}`, objectToEdit)
    .then((res) => {
      if (res.status === 200) {
        return res;
      } else {
        return undefined;
      }
    });

  toast.promise(
    response,
    {
      pending: 'Učitavanje...',
      success: 'Uspješno!',
      error: {
        icon: '😒',
        render({data}){
          const casted = (data as AxiosError)
          let castedData = (casted.response?.data as any);
          if (castedData['message']){
            return `${castedData['message']}`
          } else {
            return 'Dogodila se pogreška, kontaktirajte administratora.'
          }
        },
      }
    }
  )

  return response;
}

function deleteById(api: string, id: number) {
  const response = HttpService.getAxiosClient().delete(`${apiURL}/${api}/${id}`);

  toast.promise(
    response,
    {
      pending: 'Učitavanje...',
      success: 'Uspješno!',
      error: {
        icon: '😒',
        render({data}){
          const casted = (data as AxiosError)
          let castedData = (casted.response?.data as any);
          if (castedData['message']){
            return `${castedData['message']}`
          } else {
            return 'Dogodila se pogreška, kontaktirajte administratora.'
          }
        },
      }
    }
  )

  return response;
}

export { get, create, getById, edit, deleteById, update };