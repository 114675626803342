import Employee, {makeEmployee} from "../../models/Employee";
import Project, {makeProject} from "../../models/Project";
import TimeSheet, {makeTimeSheet} from "../../models/TimeSheet";
import {get, post} from "./endpoints/CrudApi";
import AbsenceRequest, {makeAbsenceRequest} from "../../models/AbsenceRequest";
import ContactPerson, {makeContactPerson} from "../../models/ContactPerson";
import Client, {makeClient} from "../../models/Client";
import Holiday, {makeHoliday} from "../../models/Holiday";
import {AxiosResponse} from "axios";
import Journal, {makeJournal} from "../../models/Journal";
import JournalProjectTimeSheet, {makeJournalProjectTimeSheet} from "../../models/JournalProjectTimeSheet";
import SummarizationEmployee, {makeSummarizationEmployee} from "../../models/SummarizationEmployee";
import SummarizationTimeSheet, {makeSummarizationTimeSheet} from "../../models/SummarizationTimeSheet";
import PivotTableDashboard, {makePivotTableDashboard} from "../../models/PivotTableDashboard";
import DashboardVisibilityType, {makeDashboardVisibilityType} from "../../models/DashboardVisibilityType";
import {TwoDatesDTO} from "../../models/TwoDatesDTO";

export async function getAllClients() {
    let res = await get('erv/admin/clients')
        .then((response) => {
            if (response.status === 200) {
                response.data.clients?.map(
                    (client: Client) => {
                        return makeClient(client);
                    }
                )
                return response;
            } else {
                return undefined;
            }
        }
        )
    let data;
    if (res !== undefined) {
        data = await res.data.clients;
    }
    return data;
}

export async function getAllActiveClients() {
    let res = await get('erv/admin/clients/active/true')
        .then((response) => {
            if (response.status === 200) {
                response.data.clients?.map(
                    (client: Client) => {
                        return makeClient(client);
                    }
                )
                return response;
            } else {
                return undefined;
            }
        }
        )
    let data;
    if (res !== undefined) {
        data = await res.data.clients;
    }
    return data;
}

export async function getAllContactPersons() {
    let res = await get('erv/admin/contactPersons')
        .then((response) => {
            if (response.status === 200) {
                response.data.contactPersons?.map(
                    (contactPerson: ContactPerson) => {
                        return makeContactPerson(contactPerson);
                    }
                )
                return response;
            } else {
                return undefined;
            }
        }
        )

    let data;
    if (res !== undefined) {
        data = await res?.data.contactPersons;
    }
    return data;
}

export async function getAllEmployees() {
    let res = await get('admin/employees')
        .then((response) => {
            if (response.status === 200) {
                response.data.employees?.map(
                    (employee: Employee) => {
                        return makeEmployee(employee);
                    }
                )
                return response;
            } else {
                return undefined;
            }
        }
        )

    let data;
    if (res !== undefined) {
        data = await res.data.employees;
    }
    return data;
    // TODO: Uncomment after implementing Keycloak into app
    // let filteredData = [];
    // if (KeycloakService.isLoggedIn() && KeycloakService.hasRole(["admin"])) {
    //   filteredData = data;
    // } else {
    //   filteredData = data.filter((employee: Employee) => { return employee.username === KeycloakService.getUsername() })
    // }
    // return filteredData;
    //return data
}

export async function getAllVacationDays() {
    let res = await get('vacation-days')
        .then((response) => {

            return response;
        }
        )

    let data = await res.data.vacationDays;
    return data;
}

export async function getAllAbsenceRequestsWithPath(path: string) {
    let res = await get(path)
        .then((response) => {
            if (response.status === 200) {
                response.data.absenceRequests?.map(
                    (absenceRequest: AbsenceRequest) => {
                        return makeAbsenceRequest(absenceRequest);
                    }
                )
                return response;
            } else {
                return undefined;
            }
        }
        )

    let data;
    if (res !== undefined) {
        data = await res.data.absenceRequests;
    }
    return data;
}
export async function getAllAbsenceRequests() {
    return getAllAbsenceRequestsWithPath('ego/admin/absenceRequest');
}
export async function getAllEmployeeAbsenceRequests() {
    return getAllAbsenceRequestsWithPath('ego/admin/absenceRequest/employee');
}
export async function getAllExternalAbsenceRequests() {
    return getAllAbsenceRequestsWithPath('ego/admin/absenceRequest/external');
}
export async function getAllStudentAbsenceRequests() {
    return getAllAbsenceRequestsWithPath('ego/admin/absenceRequest/student');
}

export async function getMyAbsenceRequests() {
    let res = await get('ego/user/absenceRequest')
        .then((response) => {
            if (response.status === 200) {
                response.data.absenceRequests?.map(
                    (absenceRequest: AbsenceRequest) => {
                        return makeAbsenceRequest(absenceRequest);
                    }
                )
                return response;
            } else {
                return undefined;
            }
        }
        )

    let data;
    if (res !== undefined) {
        data = await res.data.absenceRequests;
    }
    return data;
}

export async function getAllProjects() {
    let res = await get('erv/projects')
        .then((response: AxiosResponse) => {
            response.data.projects?.map(
                (project: Project) => {
                    return makeProject(project);
                }
            )
            return response;
        }
        )

    let data = await res.data.projects;
    return data;
}

export async function getAllTimeSheets(page?: number, size?: number) {
    let api = 'erv/admin/timeSheet';
    if(page !== undefined && size !== undefined) {
        api += '?page=' + (page ?? 0) + '&size='+ (size ?? 10);
    }
    let res = await get(api)
        .then((response: AxiosResponse) => {
            if (response.status === 200) {
                response.data.timeSheets?.content.map(
                    (timeSheet: TimeSheet) => {
                        return makeTimeSheet(timeSheet);
                    }
                )
                return response;
            }
            return undefined;
        }
        )

    if (res) {
        return await res.data.timeSheets;
    }

    return undefined;
}

export async function getMyTimeSheets(page?: number, size?: number) {
    let api = 'erv/user/timeSheet';
    if(page !== undefined && size !== undefined) {
        api += '?page=' + (page ?? 0) + '&size='+ (size ?? 10);
    }
    let res = await get(api)
        .then((response: AxiosResponse) => {
            if (response.status === 200) {
                response.data.timeSheets?.content.map(
                    (timeSheet: TimeSheet) => {
                        return makeTimeSheet(timeSheet);
                    }
                )
                return response;
            }
            return undefined;
        }
        )

    if (res) {
        return await res.data.timeSheets;
    }

    return undefined;
}

export async function getAllHolidays() {
    let res = await get('ego/holiday')
        .then((response) => {
            response.data.customHolidays?.map(
                (holiday: Holiday) => {
                    return makeHoliday(holiday);
                }
            )
            return response;
        }
        )

    let data;
    if (res !== undefined) {
        data = await res.data.customHolidays;
    }
    return data;
}

export async function getAllProjectsForUser() {
    let res = await get('erv/user/projects')
        .then((response: AxiosResponse) => {
            response.data.projects?.map(
                (project: Project) => {
                    return makeProject(project);
                }
            )
            return response;
        }
        )
    let data;
    if (res !== undefined) {
        data = await res.data.projects;
    }
    return data;
}

export async function getAllJournals() {
    let res = await get('erv/admin/journals')
        .then((response: AxiosResponse) => {
                response.data.journals?.map(
                    (journal: Journal) => {
                        return makeJournal(journal);
                    }
                )
                return response;
            }
        )
    let data;
    if (res !== undefined) {
        data = await res.data.journals;
    }
    return data;
}

export async function getAllManagers() {
    let res = await get('admin/managers')
        .then((response) => {
            if (response.status === 200) {
                response.data.employees?.map(
                    (employee: Employee) => {
                        return makeEmployee(employee);
                    }
                )
                return response;
            } else {
                return undefined;
            }
        }
        )

    let data;
    if (res !== undefined) {
        data = await res.data.employees;
    }
    return data;
}

export async function getAllJournalsNotConnectedToProject(projectId: number | undefined) {
    let res = await get('erv/admin/journals/not-connected-to-project/' + projectId)
        .then((response: AxiosResponse) => {
            response.data.journals?.map(
                (journal: Journal) => {
                    return makeJournal(journal);
                }
            )
            return response;
        }
    )
    let data;
    if (res !== undefined) {
        data = await res.data.journals;
    }
    return data;
}

export async function getJournalProjectTimesheetByProjectId(projectId: number | undefined) {
    let res = await get('erv/admin/journal-project/' + projectId)
        .then((response: AxiosResponse) => {
            response.data.journalProjects?.map(
                (journalProjectTimeSheet: JournalProjectTimeSheet) => {
                    return makeJournalProjectTimeSheet(journalProjectTimeSheet);
                }
            )
            return response;
        }
    )
    let data;
    if (res !== undefined) {
        data = await res.data.journalProjects;
    }
    return data;
}

export async function getAllEmployeesByType(type: string | undefined) {
    let res = await get('admin/employees/bytype/'+ type)
        .then((response) => {
            if (response.status === 200) {
                response.data.employees?.map(
                    (employee: Employee) => {
                        return makeEmployee(employee);
                    }
                )
                return response;
            } else {
                return undefined;
            }
        }
        )

    let data;
    if (res !== undefined) {
        data = await res.data.employees;
    }
    return data;
}

export async function getAllEmployeesOnProject(id: number|undefined) {
    let res = await get('admin/employees/project/'+ id)
        .then((response) => {
            if (response.status === 200) {
                response.data.employees?.map(
                    (employee: Employee) => {
                        return makeEmployee(employee);
                    }
                )
                return response;
            } else {
                return undefined;
            }
        }
        )

    let data;
    if (res !== undefined) {
        data = await res.data.employees;
    }
    return data;
}

export async function getAllSummarizationData() { //TODO: fix this
    let res = await get('admin/employees/summarization')
        .then((response) => {
            if (response.status === 200) {
                response.data.employees?.map(
                    (employee: SummarizationEmployee) => {
                        return makeSummarizationEmployee(employee);
                    }
                )
                return response;
            } else {
                return undefined;
            }
        }
        )

    let data;
    if (res !== undefined) {
        data = await res.data.employees;
    }
    return data;
}

export async function getSummarizationTimeSheetData() {
    let res = await get('erv/timesheet/summarization')
        .then((response) => {
            if (response.status === 200) {
                response.data?.map(
                    (summarizationTimeSheet: SummarizationTimeSheet) => {
                        return makeSummarizationTimeSheet(summarizationTimeSheet);
                    }
                )
                return response;
            } else {
                return undefined;
            }
        }
        )

    let data;
    if (res !== undefined) {
        data = await res.data;
    }
    return data;
}

export async function getSummarizationTimeSheetDataWithDates(dateFrom: Date, dateTo: Date) {
    const dates = new TwoDatesDTO(dateFrom, dateTo);

    let res = await post('erv/timesheet/admin/summarization', dates)
        .then((response) => {
            if (response.status === 200) {
                response.data?.map(
                    (summarizationTimeSheet: SummarizationTimeSheet) => {
                        return makeSummarizationTimeSheet(summarizationTimeSheet);
                    }
                )
                return response;
            } else {
                return undefined;
            }
        }
        )

    let data;
    if (res !== undefined) {
        data = await res.data;
    }
    return data;
}

export async function getPivotTableDashboardDataForOwner() {
    let res = await get('erv/timesheet/summarization/configuration')
        .then((response) => {
                if (response.status === 200) {
                    response.data?.map(
                        (pivotTableDashboard: PivotTableDashboard) => {
                            return makePivotTableDashboard(pivotTableDashboard);
                        }
                    )
                    return response;
                } else {
                    return undefined;
                }
            }
        )

    let data;
    if (res !== undefined) {
        data = await res.data;
    }
    return data;
}

export async function getPivotTableDashboardDataForOwnerAdmin() {
    let res = await get('erv/timesheet/summarization/configuration/owneradmin')
        .then((response) => {
                if (response.status === 200) {
                    response.data?.map(
                        (pivotTableDashboard: PivotTableDashboard) => {
                            return makePivotTableDashboard(pivotTableDashboard);
                        }
                    )
                    return response;
                } else {
                    return undefined;
                }
            }
        )

    let data;
    if (res !== undefined) {
        data = await res.data;
    }
    return data;
}

export async function getDashboardVisibilityTypes() {
    let res = await get('erv/timesheet/summarization/types')
        .then((response) => {
                if (response.status === 200) {
                    response.data?.map(
                        (dashboardVisibilityType: DashboardVisibilityType) => {
                            return makeDashboardVisibilityType(dashboardVisibilityType);
                        }
                    )
                    return response;
                } else {
                    return undefined;
                }
            }
        )

    let data;
    if (res !== undefined) {
        data = await res.data;
    }
    return data;
}