import {convertFromDate, convertToDate} from "../shared/utils/DateUtils";
import Employee, {makeEmployee, revertEmployee} from "./Employee";
import Project, {makeProject} from "./Project";
import TimeSheetType from "./TimeSheetType";
import {getValidFormatOfFloatNumber} from "../shared/utils/StringUtils";

export default class TimeSheet {
    id?: number;
    type?: TimeSheetType;
    date?: string;
    numberOfHours?: number;
    numberOfHoursoShowOnForm?: string;
    employee?: Employee;
    project?: Project;
    note?: string;
    activity?: string;
    dateToShowOnForm?: Date;
    projectName?: string;
    employeeName?: string;
    typeName?: string;
    endDate?: string;
    endDateToShowOnForm?: Date;

    constructor(id?: number, type?: TimeSheetType, date?: string, numberOfHours?: number, employee?: Employee, project?: Project, note?: string, activity?: string, dateToShowOnForm?: Date, typeName?: string, endDate?: string, endDateToShowOnForm?:Date) {
        this.id = id ?? undefined;
        this.type = type ?? undefined;
        this.date = date ?? undefined;
        this.numberOfHours = numberOfHours ?? undefined;
        this.employee = employee ?? undefined;
        this.project = project ?? undefined;
        this.note = note ?? undefined;
        this.activity = activity ?? undefined;
        this.dateToShowOnForm = dateToShowOnForm ?? undefined;
        this.endDate = endDate ?? undefined;
        this.endDateToShowOnForm = endDateToShowOnForm ?? undefined;
        this.typeName = typeName ?? undefined;
    }
}

export function makeTimeSheet(timeSheet: TimeSheet | undefined) {
    if(timeSheet == undefined) {
        return new TimeSheet();
    }
    if (timeSheet.date) {
        timeSheet.dateToShowOnForm = convertToDate(timeSheet.date);
    }

    if (timeSheet.project){
        timeSheet.project = makeProject(timeSheet.project);
        timeSheet.projectName = timeSheet.project.name;
    }

    if (timeSheet.employee){
        timeSheet.employee = makeEmployee(timeSheet.employee);
        timeSheet.employeeName = timeSheet.employee.displayName;
    }

    if (timeSheet.type) {
        timeSheet.typeName = timeSheet.type.name;
    }

    if (timeSheet.numberOfHours) {
        timeSheet.numberOfHoursoShowOnForm = getValidFormatOfFloatNumber(timeSheet.numberOfHours);
    }
    return timeSheet;
}

export function revertTimeSheet(timeSheet: TimeSheet | undefined) {
    if(timeSheet == undefined) {
        return new TimeSheet();
    }
    if (timeSheet.dateToShowOnForm) {
        timeSheet.date = convertFromDate(timeSheet.dateToShowOnForm);
    }
    if (timeSheet.employee) {
        timeSheet.employee = revertEmployee(timeSheet.employee);
    }
    return timeSheet;
}