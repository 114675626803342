
export function getLuminumEmail(firstName: string, lastName: string) {
    return `${firstName}.${lastName}@luminumict.hr`;
}

/**
 * This function is for getting valid format of:
 * XX,Y
 * One decimal digit. If number is undefined or 0, method will return 0,0
 * @param numberOfHours number to convert to string
 */
export function getValidFormatOfFloatNumber(numberOfHours: number|null|undefined): string {
    if(numberOfHours === undefined || numberOfHours === null || numberOfHours === 0){
        return '0,0';
    }
    return numberOfHours.toFixed(1).replace('.', ',');
}
