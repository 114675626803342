import {convertFromDate, convertToDate} from "../shared/utils/DateUtils";

export default class Holiday {
    id?: number;
    date?: string;
    name?: string;
    localName?: string;
    dateToShowOnForm?: Date;


    constructor(id?: number, date?: string, name?: string, localName?: string) {
        this.id = id ?? undefined;
        this.date = date ?? undefined;
        this.name = name ?? undefined;
        this.localName = localName ?? undefined;
    }
}

export function makeHoliday(holiday: Holiday | undefined) {
    if( holiday === undefined ){
        return new Holiday();
    }
    if(holiday.date) {
        holiday.dateToShowOnForm = convertToDate(holiday.date);
    }
    return holiday;
}

export function revertHoliday(holiday: Holiday | undefined) {
    if( holiday === undefined ){
        return new Holiday();
    }
    if (holiday.dateToShowOnForm) {
        holiday.date = convertFromDate(holiday.dateToShowOnForm);
    }
    return holiday;
}