import {FormikErrors, useFormik} from "formik";
import {Calendar} from "primereact/calendar";
import {Checkbox} from "primereact/checkbox";
import {Divider} from "primereact/divider";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {MultiSelect} from "primereact/multiselect";
import {classNames} from "primereact/utils";
import {useEffect, useState} from "react";
import Client from "../../../models/Client";
import ContactPerson, {makeContactPerson} from "../../../models/ContactPerson";
import Employee from "../../../models/Employee";
import {makeProject} from "../../../models/Project";
import {getAllActiveClients, getAllEmployees, getAllJournals} from "../../../shared/api/GetFormData";
import {create} from "../../../shared/api/endpoints/CrudApi";
import DialogFooter from "../../../shared/components/generic/dialog/DialogFooter";
import Journal from "../../../models/Journal";
import ProjectCommand, {revertProjectCommand} from "../../../models/ProjectCommand";
import {makeJournalProjectTimesheetFromProjectAndJournal} from "../../../models/JournalProjectTimeSheet";
import {convertFromDate, convertToDate} from "../../../shared/utils/DateUtils";

interface IProjectFormProps {
    hide: () => void,
    initialValues: ProjectCommand
    addNewDataToTable: React.Dispatch<any>
}

function ProjectForm(props: IProjectFormProps) {

    const [project, setProject] = useState<ProjectCommand>(makeProject(props.initialValues ?? undefined));
    const [selectedEmployees, setSelectedEmployees] = useState<Employee[] | undefined>(props.initialValues ? props.initialValues.employees : undefined);
    const [allEmployees, setAllEmployees] = useState<Employee[]>();
    const [clients, setClients] = useState<Client[]>();
    const [contactPersons, setContactPersons] = useState<ContactPerson[]>();
    const [dateNow, setDateNow] = useState<Date>(new Date());
    const [isContactPersonDisabled, setIsContactPersonDisabled] = useState<boolean>(props.initialValues ? props.initialValues.clientToShip === undefined : true);
    const [allJournals, setAllJournals] = useState<Journal[] | undefined>();
    const [journal, setJournal] = useState<Journal | undefined>();
    const [dates, setDates] = useState<Date[] | undefined>(undefined);
    const [datesDefault, setDatesDefault] = useState<Date[] | undefined  | any[]>(undefined);
    const [areDatesDisabled,setAreDatesDisabled] = useState<boolean>(false);
    const [journalFlag, setJournalFlag] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: project!,
        validate: (values: ProjectCommand) => {
            let errors: FormikErrors<ProjectCommand> = {};

            if (!values.name) {
                errors.name = 'Ime je obavezno polje.';
            }

            if (!values.clientToShip) {
                errors.clientToShip = 'Obavezno je odabrati klijenta.';
            }

            if (!values.clientToBill) {
                errors.clientToBill = 'Obavezno je odabrati klijenta.';
            }


            if (values.endDateToShowOnForm) {
                if (!values.startDateToShowOnForm) {
                    errors.endDateToShowOnForm = 'Datum kraja ne može biti definiran prije nego li se definira početak.';
                } else if (values.endDateToShowOnForm < values.startDateToShowOnForm) {
                    errors.endDateToShowOnForm = 'Datum kraja ne može biti prije datuma početka';
                }
            }

            return errors;
        },

        onSubmit:
            async values => {
                values = revertProjectCommand(values);
                await create(`erv/projects`, values).then(res => props.addNewDataToTable(makeProject(res.data)));
                formik.resetForm();
                props.hide();
            },
    });

    useEffect(() => {
        (async () => {
            setClients(await getAllActiveClients());
        })();

        (async () => {
            setAllEmployees(await getAllEmployees());
        })();
        (async () => {
            setAllJournals(await getAllJournals());
        })();
    }, [])

    useEffect(() => {
        if (project.clientToShip !== undefined) {
            formik.setFieldValue('clientToShip', (clients?.filter((client: Client) => client.id === project.clientToShip?.id)[0]));
        }

        (async () => {
            if (clients !== undefined) {
                setContactPersons(formik.values.clientToShip?.contactPersons?.map(
                    (contactPerson: ContactPerson) => {
                        return makeContactPerson(contactPerson);
                    }));
            }
        })();

    }, [clients])

    useEffect(() => {
        if (formik.values.employees !== undefined) {
            formik.setFieldValue('selectedEmployees', formik.values.employees);
        }
    }, [allEmployees])

    useEffect(() => {
        if (formik.values.clientToShip === undefined) {
            setIsContactPersonDisabled(true);
        } else {
            (async () => {
                if (clients !== undefined) {
                    setContactPersons(formik.values.clientToShip?.contactPersons?.map(
                        (contactPerson: ContactPerson) => {
                            return makeContactPerson(contactPerson);
                        }));
                }
            })();
            setIsContactPersonDisabled(false);
        }
    }, [formik.values.clientToShip])

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    const handleJournalChange = (e: any) => {
        setDates(undefined);
        if(e.value) {
            let journal = e.value;
            setJournal(journal);
            let journalProjectTimeSheet = makeJournalProjectTimesheetFromProjectAndJournal(project, journal);
            formik.setFieldValue('newJournalProjectLink', journalProjectTimeSheet);
            let validFromFormated = convertToDate(convertFromDate(journal.validFromToShowOnForm));
            let validToFormated = convertToDate(convertFromDate(journal.validToToShowOnForm));
            setDatesDefault([validFromFormated, validToFormated]);
        } else {
            setJournal(undefined);
            formik.setFieldValue('newJournalProjectLink', undefined);
            setDatesDefault(undefined);
        }
    }

    const handleCalendarChange = (e: any) => {
        setDates(e.value as Date[]);
        
        formik.setFieldValue('newJournalProjectLink.validFrom', convertFromDate(e.value[0]));
        formik.setFieldValue('newJournalProjectLink.validTo', convertFromDate(e.value[1]));
        
    }

    useEffect(() => {
        if (formik.values.newJournalProjectLink === undefined) {
            setJournalFlag(true);
        } 
        else {
            setJournalFlag(false);
        }
    }, [formik.values.newJournalProjectLink])



    useEffect(() => {
        if(datesDefault === undefined || datesDefault[1] === undefined){
            setAreDatesDisabled(true);
        } else {
            setAreDatesDisabled(false);
        }
    },[datesDefault])
    return (
        <form>
            <div>
                <div style={{border: "transparent"}}>
                    <div className="p-fluid grid" style={{paddingTop: '20px'}}>
                        <div className="field col-12 md:col-12">
                            <span className="p-float-label">
                                <InputText id="name" name="name" value={formik.values.name}
                                           onChange={formik.handleChange}/>
                                <label htmlFor="name"
                                       className={classNames({'p-error': isFormFieldValid('name')})}>Naziv</label>
                            </span>
                            {getFormErrorMessage('name')}
                        </div>
                        <Divider/>
                        <div className="field col-6 md:col-6">
                            <span className="p-float-label">
                                    <>
                                        <Dropdown id="clientToBill" filter value={formik.values.clientToBill} options={clients}
                                                  onChange={(e) => {
                                                      formik.setFieldValue('clientToBill', e.value);
                                                  }}
                                                  dataKey="name" optionLabel="name"/>
                                        <label htmlFor="clientToBill"
                                               className={classNames({'p-error': isFormFieldValid('clientToBill')})}>Naručitelj</label>
                                    </>
                            </span>
                            {getFormErrorMessage('clientToBill')}
                        </div>
                        <div className="field col-6 md:col-6">
                            <span className="p-float-label">
                                <InputText id="type" name="type" value={formik.values.type}
                                           onChange={formik.handleChange}/>
                                <label htmlFor="type">Tip</label>
                            </span>
                        </div>
                        <div className="field col-6 md:col-6">
                            <span className="p-float-label">
                                    <>
                                        <Dropdown id="clients" value={formik.values.clientToShip} options={clients}
                                                  onChange={(e) => {
                                                      formik.setFieldValue('clientToShip', e.value);
                                                  }} filter
                                                  dataKey="name" optionLabel="name"/>
                                        <label htmlFor="clients"
                                               className={classNames({'p-error': isFormFieldValid('clientToShip')})}>Krajnji
                                            korisnik</label>
                                    </>
                            </span>
                            {getFormErrorMessage('clientToShip')}
                        </div>

                        <div className="field col-6 md:col-6">
                            <span className="p-float-label">
                                    <>
                                        <Dropdown id="contactPerson" value={formik.values.contactPerson}
                                                  options={contactPersons}
                                                  onChange={(e) => {
                                                      formik.setFieldValue('contactPerson', e.value);
                                                  }} filter
                                                  disabled={isContactPersonDisabled}
                                                  name="contactPerson" dataKey="name" optionLabel="name"/>
                                        <label htmlFor="contactPerson"
                                               className={classNames({'p-error': isFormFieldValid('contactPerson')})}>
                                            Kontakt osoba</label>
                                    </>
                            </span>
                            {getFormErrorMessage('contactPerson')}
                        </div>

                        <div className="field col-6 md:col-6">
                            <span className="p-float-label">
                                <Calendar showButtonBar name="startDateToShowOnForm" value={formik.values.startDateToShowOnForm}
                                          onChange={formik.handleChange}
                                          dateFormat="dd.mm.yy." id="calendarFrom"
                                          disabledDays={[0, 6]}/>
                                <label htmlFor="calendarFrom">Odaberite datum početka</label>
                            </span>
                            {getFormErrorMessage('startDateToShowOnForm')}
                        </div>
                        <div className="field col-6 md:col-6">
                            <span className="p-float-label">
                                <Calendar showButtonBar name="endDateToShowOnForm" value={formik.values.endDateToShowOnForm}
                                          onChange={formik.handleChange}
                                          dateFormat="dd.mm.yy." id="calendarTo"
                                          disabledDays={[0, 6]}/>
                                <label htmlFor="calendarTo"
                                       className={classNames({'p-error': isFormFieldValid('endDateToShowOnForm')})}>Odaberite datum kraja</label>
                            </span>
                            {getFormErrorMessage('endDateToShowOnForm')}
                        </div>
                            <>
                                <div className="field col-12 md:col-12">
                                    <span className="p-float-label">
                                        <MultiSelect id="employees" value={selectedEmployees}
                                                     options={allEmployees}
                                                     onChange={(e) => {
                                                         let all = formik.values.employees;
                                                         all = [...e.value];
                                                         setSelectedEmployees(all);
                                                         formik.setFieldValue("employees", all);
                                                     }} filter
                                                     dataKey="displayName"
                                                     optionLabel="displayName"/>
                                        <label htmlFor="employees">Zaposlenici</label>
                                    </span>
                                </div>
                                <div className="field col-6 md:col-6">
                                <span className="p-float-label">
                                <Dropdown id="newJournalProjectLink" value={journal} showClear
                                            options={allJournals}
                                            onChange={(e) => { handleJournalChange(e) }}
                                            filter
                                            dataKey="name" 
                                            optionLabel="name"
                                            required={false}
                                />
                                <label htmlFor="newJournalProjectLink"
                                        className={classNames({'p-error': isFormFieldValid('newJournalProjectLink')})}>
                                    Narudžbenica</label>
                                </span>
                                </div>
                                <div className="field col-6 md:col-6">
                                <span className="p-float-label">
                                    <Calendar showButtonBar name="journalCalendar" value={dates ? dates : datesDefault}
                                              onChange={(e)=> handleCalendarChange(e)}
                                              selectionMode="range"
                                              dateFormat="dd.mm.yy." id="journalCalendar"
                                              minDate={datesDefault ? datesDefault[0] : undefined}
                                              maxDate={datesDefault ? datesDefault[1] : undefined}
                                              disabledDays={[0, 6]}
                                              disabled={areDatesDisabled}/>
                                    <label htmlFor="journalCalendar">Period narudžbenice</label>
                                </span>
                                {getFormErrorMessage('journalCalendar')}
                                </div>
                                <div className="field col-1 md:col-1">
                                    <Checkbox inputId="canAddMultipleTimeSheets" name="pizza"
                                              value="canAddMultipleTimeSheets" style={{marginLeft: "1rem"}}
                                              onChange={e => formik.setFieldValue("canAddMultipleTimeSheets", e.checked)}
                                              checked={formik.values.canAddMultipleTimeSheets ?? false}/>

                                </div>
                                <div className="field col-5 md:col-5">
                                    <div className="flex align-items-center">
                                        <label htmlFor="canAddMultipleTimeSheets" className="ml-2">Omogući dodavanje
                                            više evidencija rada za različite datume odjednom.</label>
                                    </div>
                                </div>
                            </>
                    </div>
                </div>
                <DialogFooter hide={props.hide} onClick={formik.handleSubmit}
                              isDisabledOnDelete={formik.values.endDateToShowOnForm ? formik.values.endDateToShowOnForm < dateNow : false}
                />
            </div>
        </form>
    );
}

export default ProjectForm;