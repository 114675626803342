import React from "react";
import Holiday from "../../../models/Holiday";

type CustomDate = {
    day: number;
    month: number;
    year: number;
    show: string;
}

export function getDatesInRange(startDate: Date, endDate: Date) {
    const date = new Date(startDate.getTime());
    const end = new Date(endDate.getTime());
    end.setDate(end.getDate() + 1);
    const dates = [];

    while (date < end) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return dates;
}

export function makeCustomDate(date: any) {
    const custom: CustomDate = {
        day: date.day,
        month: date.month,
        year: date.year,
        show: ""
    };
    return makeDate(custom, date.year);
}

export function makeCustomDateFromDate(date: Date) {
    const custom: CustomDate = {
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
        show: ""
    };
    return makeDate(custom, date.getFullYear());
}

export function makeDate(custom: CustomDate, year: number) {
    custom.month++;

    let day = '';
    if (custom.day < 10) {
        day = '0';
    }
    day += custom.day;

    let month = '';
    if (custom.month < 9) {
        month = '0';
    }
    month += custom.month;
    custom.show = day + '.' + month + '.' + year;
    return custom;
}

export function dateTemplate(date: any, holidays: Holiday[] | undefined) {
    if(holidays === undefined){
        return date.day;
    }
    let dateToCheck = makeCustomDate(date);

    if (holidays.map(x => x.date).includes(dateToCheck.show)) {
        if (date.day < 10) {
            return (
                <div style={{
                    backgroundColor: '#5D6671',
                    color: '#EAF4FF',
                    fontWeight: 'bold',
                    borderRadius: '50%',
                    padding: '5px 0 0 10px',
                    width: '2em',
                    height: '2em'
                }}>
                    {date.day}
                </div>
            );
        } else {
            return (
                <div style={{
                    backgroundColor: '#5D6671',
                    color: '#EAF4FF',
                    fontWeight: 'bold',
                    borderRadius: '50%',
                    padding: '5px 0 0 6px',
                    width: '2em',
                    height: '2em'
                }}>
                    {date.day}
                </div>
            );
        }
    } else {
        return date.day;
    }
}
