
export default class SummarizationTimeSheet {
    orderer?: string;
    client?: string;
    project_name?: string;
    journal?: string;
    date?: Date;
    hours?: number;
    year?: number;
    month?: number;
    day_of_week?: number;
    week?: number;
    quarter?: number;


    constructor(orderer?: string, client?: string, project_name?: string, journal?: string, date?: Date, hours?: number, year?: number, month?: number, day_of_week?: number, week?: number, quarter?: number) {
        this.orderer = orderer ?? undefined;
        this.client = client ?? undefined;
        this.project_name = project_name ?? undefined;
        this.journal = journal ?? undefined;
        this.date = date ?? undefined;
        this.hours = hours ?? undefined;
        this.year = year ?? undefined;
        this.month = month ?? undefined;
        this.day_of_week = day_of_week ?? undefined;
        this.week = week ?? undefined;
        this.quarter = quarter ?? undefined;
    }
}

export function makeSummarizationTimeSheet(summarizationTimeSheet: SummarizationTimeSheet | undefined) {
    if(summarizationTimeSheet === undefined) {
        return new SummarizationTimeSheet();
    }
    return summarizationTimeSheet;
}
