import React, {useEffect, useState} from 'react';
import 'react-pivottable/pivottable.css';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import {
    getDashboardVisibilityTypes,
    getPivotTableDashboardDataForOwnerAdmin,
    getSummarizationTimeSheetData,
    getSummarizationTimeSheetDataWithDates
} from '../../../../shared/api/GetFormData';
import SummarizationTimeSheet from '../../../../models/SummarizationTimeSheet';
import {Calendar} from "primereact/calendar";
import {Divider} from "primereact/divider";
import {create, deleteById, edit} from "../../../../shared/api/endpoints/CrudApi"
import PivotTableDashboard from "../../../../models/PivotTableDashboard";
import Employee, {getEmployee} from "../../../../models/Employee";
import GenericButton, {
    EButtonColor,
    EButtonIcons,
    EButtonStyle
} from "../../../../shared/components/generic/button/GenericButton";

import {Dropdown} from 'primereact/dropdown';
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";


const SummarizationTimeSheetTable = () => {
    const [response, setResponse] = useState<any[]>([]);
    const [state, setState] = useState<any>([]);
    const PlotlyRenderers = createPlotlyRenderers(Plot);
    const [dateFrom, setDateFrom] = useState<any>(undefined);
    const [dateTo, setDateTo] = useState<any>(undefined);
    const [employee, setEmployee] = useState<Employee>()
    const [dashboards, setDashboards] = useState<any[]>();
    const [selectedDashboard, setSelectedDashboard] = useState<any>();
    const [visible, setVisible] = useState<boolean>(false);
    const [name, setName] = useState<string | undefined>(undefined)
    const [initialState, setInitialState] = useState<any>([])
    const [dashboardVisibilityTypes, setDashboardVisibilityTypes] = useState<any[]>([])
    const [selectedDashboardVisibilityType, setSelectedDashboardVisibilityType] = useState<any>();


    function renameKey(obj: any, oldKey: any, newKey: any) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
    }

    useEffect(() => {
        getEmployee().then((res: Employee | undefined) => {
            res == undefined ? undefined : setEmployee(res)
        });
        getSummarizationTimeSheetData().then((res: SummarizationTimeSheet[]) => {
            res.forEach((e: SummarizationTimeSheet) => {
                renameKey(e, 'hours', 'Sati')
                renameKey(e, 'client', 'Klijent')
                renameKey(e, 'employee_name', 'Zaposlenik')
                renameKey(e, 'date', 'Datum')
                renameKey(e, 'project_name', 'Projekt')
                renameKey(e, 'journal', 'Narudžbenica')
                renameKey(e, 'orderer', 'Naručitelj')
                renameKey(e, 'day_of_week', 'Dan u tjednu')
                renameKey(e, 'month', 'Mjesec')
                renameKey(e, 'year', 'Godina')
                renameKey(e, 'quarter', 'Kvartal')
                renameKey(e, 'week', 'Tjedan')
            });
            setResponse(res)
            setState(res);
            setInitialState(res)
        });
        getPivotTableDashboardDataForOwnerAdmin().then((res) => { //ovisno jel admin ulogiran il user obicni
            res.forEach((d: any) => {
                d.pivotTableDashboardData = JSON.parse(d.pivotTableDashboardData)
            })
            setDashboards(res)
        });
        getDashboardVisibilityTypes().then((res) => {
            setDashboardVisibilityTypes(res);
        })
    }, []);

    const footerContent = (
        <div>
            <GenericButton label="Odustani" color={EButtonColor.MILK_BLUE} styled={EButtonStyle.TRANSPOSE}
                           icon={EButtonIcons.IKS} onClick={() => setVisible(false)}/>
            <GenericButton onClick={() => {
                pohraniOnClick();
            }
            } label={"Pohrani"}
                           color={EButtonColor.LUMINUM_BLUE} styled={EButtonStyle.TRANSPOSE} icon={EButtonIcons.CHECK}
            />
        </div>
    );

    const pohraniOnClick = async () => {
        let flag: boolean = await saveCurrentState();
        if (flag) {
            setVisible(false);
        }
    }

    useEffect(() => {

        let newArray = JSON.parse(JSON.stringify(response));

        setState((prevState: any) => ({
            ...prevState,
            data: [...newArray],
            newArray,
            unusedOrientationCutoff: Infinity
        }));
    }, [response]);

    useEffect(() => {
        if (selectedDashboard != undefined) {
            let config: any = selectedDashboard.pivotTableDashboardData;
            setState({...response, data: [...response], ...config, unusedOrientationCutoff: Infinity})
            setSelectedDashboardVisibilityType(dashboardVisibilityTypes.find(type => type.id === selectedDashboard.dashboardVisibilityTypeId));
            setName(selectedDashboard.name)
        }
    }, [selectedDashboard])

    let deletePivotTableDashboard = () => {
        deleteById('erv/timesheet/summarization/configuration', selectedDashboard.id)
        const newDashboards = dashboards?.filter(dashboard => dashboard.id !== selectedDashboard.id);
        setDashboards(newDashboards);
        setState(initialState);
    }

    let cleanDashboard = () => {
        setState(initialState);
        setDateFrom(undefined);
        setDateTo(undefined);
    }

    let noviDashboard = () => {
        setState(initialState);
        setDateFrom(undefined);
        setDateTo(undefined);
        setSelectedDashboard(undefined)
        setName(undefined);
        setSelectedDashboardVisibilityType(undefined)
    }

    async function saveCurrentState() {

        if (name === undefined || name === "" || selectedDashboardVisibilityType === undefined) {
            return false;
        }

        let dataToSave: any = {
            unusedOrientationCutoff: state.unusedOrientationCutoff,
            cols: state.cols,
            rows: state.rows,
            vals: state.vals,
            aggregatorName: state.aggregatorName,
            sorters: state.sorters,
            valueFilter: state.valueFilter,
            rowOrder: state.rowOrder,
            colOrder: state.colOrder,
            derivedAttributes: state.derivedAttributes,
            tableOptions: state.tableOptions,
            rendererName: state.rendererName,
            hiddenAttributes: state.hiddenAttributes,
            hiddenFromAggregators: state.hiddenFromAggregators,
            hiddenFromDragDrop: state.hiddenFromDragDrop,
            menuLimit: state.menuLimit
        }
        let dataToSend: PivotTableDashboard = new PivotTableDashboard(employee!.id!, JSON.stringify(dataToSave), name, selectedDashboardVisibilityType.id);
        if (dashboards!.find(obj => obj['name'] === name) != undefined) {
            let id = dashboards!.find(obj => obj['name'] === name).id;
            await edit('erv/timesheet/summarization/configuration', id, dataToSend)
                .then(res => res!.status == 200 ? undefined : null);
        } else {
            await create("erv/timesheet/summarization/configuration", dataToSend)
                .then(res => res.status == 200 ? undefined : null);
        }
        let dashboardsData = await getPivotTableDashboardDataForOwnerAdmin();
        dashboardsData.forEach((d: any) => {
            d.pivotTableDashboardData = JSON.parse(d.pivotTableDashboardData)
        })
        //ovako je postavljeno setupiranje dashboarda jer state se odma ne postavlja
        setDashboards(dashboardsData);
        setDashboards((data) => {
            setSelectedDashboard(data!.find(obj => obj['name'] === name));
            return data;
        });
        return true;
    }

    function generateNewDataForPivotTable() {
        getSummarizationTimeSheetDataWithDates(dateFrom, dateTo).then((res: SummarizationTimeSheet[]) => {
            res.forEach((e: SummarizationTimeSheet) => {
                renameKey(e, 'hours', 'Sati')
                renameKey(e, 'client', 'Klijent')
                renameKey(e, 'employee_name', 'Zaposlenik')
                renameKey(e, 'date', 'Datum')
                renameKey(e, 'project_name', 'Projekt')
                renameKey(e, 'journal', 'Narudžbenica')
                renameKey(e, 'orderer', 'Naručitelj')
                renameKey(e, 'day_of_week', 'Dan u tjednu')
                renameKey(e, 'month', 'Mjesec')
                renameKey(e, 'year', 'Godina')
                renameKey(e, 'quarter', 'Kvartal')
                renameKey(e, 'week', 'Tjedan')
            });
            setResponse(res);
            setInitialState(res);
        });
    }

    const renderers = Object.assign({}, TableRenderers, PlotlyRenderers);


    return (
        <div className='mx-3 flex-column justify-content-center align-items-center'>
            <div className="p-fluid grid pt-5">
                <div className="col-2 md:col-2">
                  <span className="p-float-label">
                      <Calendar showButtonBar name="calendarFrom" id="calendarFrom"
                                dateFormat="dd.mm.yy."
                                value={dateFrom}
                                onChange={(e) => setDateFrom(e.value)}/>
                      <label htmlFor="calendarFrom" className="pr-2">Datum od</label>
                  </span>
                </div>
                <div className="col-2 md:col-2">
                  <span className="p-float-label">
                      <Calendar showButtonBar name="calendarTo" id="calendarTo"
                                dateFormat="dd.mm.yy."
                                value={dateTo}
                                onChange={(e) => setDateTo(e.value)}/>
                      <label htmlFor="calendarTo" className="pr-2">Datum do</label>
                  </span>
                </div>
                <div className="col-2 md:col-2">
                <span className="p-float-label">
                    <>
                        <Dropdown value={selectedDashboard} onChange={(e) => {
                            setSelectedDashboard(e.value)
                        }}
                                  options={dashboards} optionLabel="name"
                                  id={"selectedDashboard"}
                        />
                        <label htmlFor="selectedDashboard">Odaberi prikaz</label>
                    </>
                </span>
                </div>
                <div className="col-1 md:col-1">
                    <GenericButton label="Pretraži" onClick={() => generateNewDataForPivotTable()}
                                   styled={EButtonStyle.OUTLINED} color={EButtonColor.LUMINUM_BLUE}
                                   icon={EButtonIcons.SEARCH}
                    />
                </div>
                <div className="col-1 md:col-1"/>
                <div className="col-1 md:col-1">
                    <GenericButton label="Pohrani" color={EButtonColor.LUMINUM_BLUE} styled={EButtonStyle.TRANSPOSE}
                                   onClick={() => setVisible(true)} icon={EButtonIcons.CHECK}/>
                </div>
                <div className="col-1 md:col-1">
                    <GenericButton label="Novi" color={EButtonColor.LUMINUM_BLUE} styled={EButtonStyle.TRANSPOSE}
                                   icon={EButtonIcons.PLUS} onClick={noviDashboard}/>
                </div>
                <div className="col-1 md:col-1">
                    <GenericButton label="Reset" color={EButtonColor.MILK_BLUE} styled={EButtonStyle.TRANSPOSE}
                                   icon={EButtonIcons.UNDO} onClick={cleanDashboard}/>
                </div>
                <div className="col-1 md:col-1">
                    <GenericButton label={"Obriši"} onClick={deletePivotTableDashboard} color={EButtonColor.MILK_BLUE}
                                   styled={EButtonStyle.TRANSPOSE} icon={EButtonIcons.IKS}/>
                </div>
            </div>

            <div className={"justify-content-center"}>
                <PivotTableUI
                    data={state}
                    unusedOrientationCutoff={Infinity}
                    onChange={(s: any) => {
                        setState(s)
                    }}
                    renderers={renderers}
                    {...state}
                />
            </div>

            <Dialog header="Spremi dashboard" visible={visible} style={{width: '50vw'}} onHide={() => setVisible(false)}
                    footer={footerContent}>
                <Divider/>
                <Divider/>
                <Divider/>
                <Divider/>
                <div className={"p-fluid grid"}>
                    <div className="field col-3 md:col-3">
                        <span className="p-float-label">
                            <>
                                <InputText id={"ime"} value={name} onChange={(e) => setName(e.target.value)}
                                           required={true}/>
                                <label htmlFor="ime">Ime</label>
                            </>
                        </span>
                        {name === undefined || name === "" ?
                            <small className="p-error">Potrebno je upisati ime prikaza</small> : <></>}
                    </div>
                    <div className="field col-5 md:col-5">
                        <span className="p-float-label">
                            <>
                                <Dropdown value={selectedDashboardVisibilityType} onChange={(e) => {
                                    setSelectedDashboardVisibilityType(e.value)
                                }}
                                          options={dashboardVisibilityTypes} optionLabel="name"
                                          className="w-full md:w-20rem" id={"visibilityType"}/>
                                <label htmlFor="visibilityType">Odaberi vidljivost prikaza</label>
                            </>
                        </span>
                        {selectedDashboardVisibilityType === undefined ?
                            <small className="p-error">Potrebno je odabrati vidljivost prikaza</small> : <></>}
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default SummarizationTimeSheetTable;
