import React, {useState} from "react";
import DialogFooter from "../../../shared/components/generic/dialog/DialogFooter";
import {Checkbox} from "primereact/checkbox";
import {EButtonIcons} from "../../../shared/components/generic/button/GenericButton";
import ClassicExcelTemplateForUser from "./ClassicExcelTemplateForUser";
import Project, {revertProject} from "../../../models/Project";
import Employee, {revertEmployee} from "../../../models/Employee";
import KeycloakService from "../../../shared/services/KeycloakService";
import ClassicExcelTemplate from "./ClassicExcelTemplate";

interface GenericExportProp {
    hide: () => void;
    goBack: () => void;
    values: GenericExcelProp[];
    url: string;
}

export interface GenericExcelProp {
    key: string;
    value: string;
}

export interface DataForGenericExcel {
    [key: string]: {
        label: string;
        checked: boolean;
    }
}

export class MainExcelInfo {
    dateFrom?: string;
    dateTo?: string;
    projects?: Project[];
    employees?: Employee[];
    data?: DataForGenericExcel;

    constructor(dateFrom?: string, dateTo?: string, project?: Project[], employee?: Employee[], columnValues?: DataForGenericExcel) {
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
        this.projects = project;
        this.employees = employee;
        this.data = columnValues;
    }

    static getBlank(columnValues?: DataForGenericExcel) {
        return new MainExcelInfo(undefined, undefined, undefined, undefined, columnValues);
    }

    static revertMainExcelInfo(mainExcelInfo: MainExcelInfo) {
        if (mainExcelInfo.projects) {
            mainExcelInfo.projects = mainExcelInfo.projects.map(x => revertProject(x));
        }
        if (mainExcelInfo.employees) {
            mainExcelInfo.employees = mainExcelInfo.employees.map(x => revertEmployee(x));
        }
        return mainExcelInfo;
    }
}

export class MainExcelInfoForEricssonTemplate {
    dateFrom?: string;
    dateTo?: string;
    project?: Project;
    employee?: Employee;
    data?: DataForGenericExcel;

    constructor(dateFrom?: string, dateTo?: string, project?: Project, employee?: Employee, columnValues?: DataForGenericExcel) {
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
        this.project = project;
        this.employee = employee;
        this.data = columnValues;
    }

    static getBlank(columnValues?: DataForGenericExcel) {
        return new MainExcelInfoForEricssonTemplate(undefined, undefined, undefined, undefined, columnValues);
    }

    static revertMainExcelInfo(mainExcelInfo: MainExcelInfoForEricssonTemplate) {
        if (mainExcelInfo.project) {
            mainExcelInfo.project = revertProject(mainExcelInfo.project);
        }
        if (mainExcelInfo.employee) {
            mainExcelInfo.employee = revertEmployee(mainExcelInfo.employee);
        }
        return mainExcelInfo;
    }
}

export interface IClassicExcelTemplateProps {
    hide: () => void;
    goBack: () => void;
    url: string;
    columnValues?: DataForGenericExcel;
}

function GenericExport(props: GenericExportProp) {
    const [data, setData] = useState<DataForGenericExcel>(createInitialExcelData(props.values));
    const [noneIsChecked, setNoneIsChecked] = useState<boolean | undefined>(undefined);
    const [areColumnsSelected, setAreColumnsSelected] = useState<boolean>(false);

    function selectColumns() {
        const anyChecked = Object.values(data).some(value => value.checked);

        if (!anyChecked) {
            setNoneIsChecked(true);
        } else {
            setNoneIsChecked(false);
            setAreColumnsSelected(true)
        }
    }

    function createInitialExcelData(values: GenericExcelProp[]): DataForGenericExcel {
        const initialData: DataForGenericExcel = {};
        values.forEach(item => {
            initialData[item.key] = {
                label: item.value,
                checked: false
            };
        });
        return initialData;
    }

    function onValueChange(key: string, value: { label: string; checked: boolean }, e: any) {
        setData({
            ...data,
            [key]: {...value, checked: e.target.checked}
        })

        if (noneIsChecked && e.target.checked) {
            setNoneIsChecked(false);
        }
    }

    function goBack() {
        setAreColumnsSelected(false);
    }

    return (
        <div>
            {!areColumnsSelected ?
                <>
                    <div style={{fontSize: "normal", paddingBottom: '2rem'}}>
                        <span>Odaberi kolone koje će biti prikazane u excel-u.</span>
                    </div>
                    <div>
                        {Object.entries(data).map(([key, value]) => (
                            <>
                                <div className="field flex align-items-center col-6 col-offset-4 gap-0">
                                    <Checkbox
                                        inputId={key}
                                        name={key}
                                        onChange={e => onValueChange(key, value, e)}
                                        checked={value.checked}
                                    />
                                    <label htmlFor={key} className="ml-3 md:h-1rem">{value.label}</label>
                                </div>
                            </>
                        ))}
                    </div>
                    <div>
                        {noneIsChecked === true ?
                            <div className="flex justify-content-center" style={{paddingTop: '2rem'}}>
                                <span>Mora biti izabrano barem jedno polje.</span>
                            </div>
                            :
                            <></>
                        }
                    </div>
                    <DialogFooter
                        hide={props.goBack}
                        onClick={() => selectColumns()}
                        firstButtonLabel="Nazad"
                        firstButtonIcon={EButtonIcons.ANGLE_LEFT}
                        secondButtonLabel="Sljedeće"
                        secondButtonIcon={EButtonIcons.ANGLE_RIGHT}
                        secondButtonHasIconOnRight={true}
                    />
                </>
                :
                <>
                    { KeycloakService.hasRole(['admin']) ?
                        <form>
                            <ClassicExcelTemplate goBack={() => goBack()} hide={props.hide}
                                                         url={props.url}
                                                         columnValues={data}
                            ></ClassicExcelTemplate>
                        </form>
                        :
                        <form>
                            <ClassicExcelTemplateForUser goBack={() => goBack()} hide={props.hide}
                                                         url={props.url}
                                                         columnValues={data}
                            ></ClassicExcelTemplateForUser>
                        </form>}
                </>
            }
        </div>
    );
}

export default GenericExport;