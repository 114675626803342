import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {Divider} from "primereact/divider";
import Employee, {getEmployee, makeEmployee, revertEmployee} from "../../models/Employee";
import {Knob} from 'primereact/knob';
import {edit} from "../../shared/api/endpoints/CrudApi";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import KeycloakService from "../../shared/services/KeycloakService";

const MyInformation = () => {
    const [employee, setEmployee] = useState<Employee>();

    useEffect(() => {
        getEmployee().then((employee: Employee | undefined) => {
            if (employee) {
                setEmployee(makeEmployee(employee));
                formik.setValues(employee);
            }
        })
    }, [])

    const formik = useFormik({
        initialValues: new Employee(),

        onSubmit:
            async (values: Employee) => {
                values = revertEmployee(values);

                if (values.employeeNumber) {
                    await edit(`admin/employee`, values.employeeNumber, values);
                }
                formik.resetForm();
            },
    });

    function getManagers() {
        return formik.values.employeeLdap?.managers?.map((manager) => {
            return manager.displayName
        }).join(', ');
    }

    return (
        <form>
            <div>
                {formik.values ?
                    <div className="p-4">
                        <div style={{border: "transparent"}} className="grid">
                            <div className="p-fluid grid pt-4 col-5 my-info-form">
                                <div className="field col-6 md:col-6">
                                    <span className="p-float-label"
                                          style={{border: "transparent", boxShadow: "transparent"}}>
                                        <InputText id="firstName" name="firstName"
                                                   value={formik.values.firstName}
                                                   readOnly={true}
                                                   />
                                        <label htmlFor="firstName">Ime</label>
                                    </span>
                                </div>

                                <div className="field col-6 md:col-6">
                                    <span className="p-float-label">
                                        <InputText id="lastName" name="lastName"
                                                   value={formik.values.lastName}
                                                   readOnly={true}
                                                   />
                                        <label htmlFor="lastName">Prezime</label>
                                    </span>

                                </div>

                                <Divider/>

                                <div className="field col-12 md:col-12">
                                    <span className="p-float-label">
                                        <InputText id="email" name="email" value={formik.values.email} readOnly={true}
                                                   />
                                        <label htmlFor="email">Email</label>
                                    </span>
                                </div>

                                <Divider/>
                                {formik.values.lastYearVacationDays ?

                                    <>
                                        <div className="col-6 md:col-6">
                                        <span className="p-float-label">
                                            <InputNumber id="lastYearAvailableDays" name="lastYearAvailableDays"
                                                         readOnly={true}
                                                         value={formik.values.lastYearVacationDays.currentAvailableDays}/>
                                            <label
                                                htmlFor="lastYearAvailableDays">Dani godišnjeg {formik.values.currentYear ? ('u ' + (formik.values.currentYear - 1) + '.') : ''}</label>
                                        </span>
                                        </div>

                                        <div className="col-6 md:col-6">
                                            <span className="p-float-label">
                                                <InputNumber id="lastYearRemainingDays" name="lastYearRemainingDays"
                                                             readOnly={true}
                                                             value={formik.values.lastYearRemainingDays}/>
                                                <label
                                                    htmlFor="lastYearRemainingDays">Neiskorišteni dani {formik.values.currentYear ? ('u ' + (formik.values.currentYear - 1) + '.') : ''}</label>
                                            </span>
                                        </div>
                                        <Divider/>
                                    </>
                                    :
                                    <></>
                                }
                                {formik.values.currentYearVacationDays ?

                                    <>
                                        <div className="col-4 md:col-4">
                                        <span className="p-float-label">
                                            <InputNumber id="currentYearVacationDays.currentAvailableDays" name="currentYearVacationDays.currentAvailableDays"
                                                         readOnly={true}
                                                         value={formik.values.currentYearVacationDays.currentAvailableDays}/>
                                            <label
                                                htmlFor="lastYearAvailableDays">Dani godišnjeg {formik.values.currentYear ? ('u ' + (formik.values.currentYear) + '.') : ''}</label>
                                        </span>

                                        </div>
                                        <div className="col-4 md:col-4">
                                        <span className="p-float-label">
                                            <InputNumber id="currentYearVacationDays.daysOff" name="currentYearVacationDays.daysOff"
                                                         readOnly={true}
                                                         value={formik.values.currentYearVacationDays.daysOff}/>
                                            <label
                                                htmlFor="currentYearVacationDays.daysOff">Bonus dani {formik.values.currentYear ? ('u ' + (formik.values.currentYear) + '.') : ''}</label>
                                        </span>

                                        </div>
                                        <div className="col-4 md:col-4">
                                            <span className="p-float-label">
                                                <InputNumber id="lastYearRemainingDays" name="lastYearRemainingDays"
                                                             readOnly={true}
                                                             value={formik.values.currentYearRemainingDays}/>
                                                <label
                                                    htmlFor="lastYearRemainingDays">Neiskorišteni dani</label>
                                            </span>
                                        </div>
                                        <Divider/>
                                    </>
                                    :
                                    <></>
                                }

                                <div className="field col-6 md:col-6">
                                    <span className="p-float-label">
                                        <InputText name="startDateToShowOnForm"
                                                   value={formik.values.validFrom}
                                                   readOnly={true} id="calendarFrom"/>
                                        <label htmlFor="calendarFrom">Zaposlen od</label>
                                    </span>
                                </div>
                                <div className="field col-6 md:col-6">
                                    <span className="p-float-label">
                                        <InputText name="endDateToShowOnForm"
                                                   value={formik.values.validTo == null ? '' : formik.values.validTo}
                                                   readOnly={true} id={"calendarTo"}/>
                                        <label htmlFor="calendarTo">Zaposlen do</label>
                                    </span>
                                </div>
                                <Divider/>

                                <div className="field col-12 md: col-12">
                                    <span className="p-float-label">
                                        <InputText id="managers" name="managers" readOnly={true}
                                                   value={getManagers()} />
                                        <label htmlFor="managers">Nadređeni zaposlenici</label>
                                    </span>
                                </div>
                                <Divider/>
                                {/*<div className="field col-12 md:col-12">
                                <span className="p-float-label">
                                <InputText id="projectsWithGrant" name="projectsWithGrant" readOnly={true}
                                                   value={formik.values.employeeDatabase?.projectsWithGrant?.map((project) => {
                                                       return ' ' + project.name
                                                   })} />
                                    <label htmlFor="projectsWithGrant">Projekti</label>
                                </span>
                                </div>*/}
                                <div className="col-12 md:col-12">
                                    <DataTable value={employee?.employeeDatabase?.projectsWithGrant} >
                                        <Column field="name" header="Projekti"></Column>
                                    </DataTable>
                                </div>
                            </div>
                            {formik.values.currentYearVacationDays != undefined && formik.values.currentYearRemainingDays != undefined && formik.values.currentYearVacationDays.currentAvailableDays != undefined && formik.values.currentYearVacationDays.daysOff != undefined &&
                            formik.values.lastYearVacationDays != undefined && formik.values.lastYearRemainingDays != undefined && formik.values.lastYearVacationDays.currentAvailableDays != undefined && formik.values.lastYearVacationDays.daysOff != undefined ?
                                <>
                                    <div
                                        className="col-7 flex justify-content-evenly align-items-center my-info-form-graphs pt-4 flex-column ">
                                        <div className="flex justify-content-center flex-column m-2">
                                            <Knob
                                                value={
                                                    (((formik.values.currentYearVacationDays.currentAvailableDays + formik.values.currentYearVacationDays.daysOff - formik.values.currentYearRemainingDays) * 100 / (formik.values.currentYearVacationDays.currentAvailableDays + formik.values.currentYearVacationDays.daysOff)) <= 100 ?
                                                        Math.ceil((formik.values.currentYearVacationDays.currentAvailableDays + formik.values.currentYearVacationDays.daysOff - formik.values.currentYearRemainingDays) * 100 / (formik.values.currentYearVacationDays.currentAvailableDays + formik.values.currentYearVacationDays.daysOff)) : 100)}
                                                valueTemplate={'{value}%'}
                                                valueColor="#308EFE"
                                                size={200}
                                                id="usedHolidaysGraphic"></Knob>
                                            <label
                                                htmlFor="usedHolidaysGraphic">Iskorišteni
                                                dani {formik.values.currentYear ? ('za godinu ' + formik.values.currentYear) : ''}</label>
                                        </div>
                                        <div className="flex justify-content-center flex-column m-2">
                                            {KeycloakService.hasRole(['admin']) ?
                                                <>
                                                </>
                                                :
                                                Math.ceil((formik.values.currentYearVacationDays.currentAvailableDays + formik.values.currentYearVacationDays.daysOff - formik.values.currentYearRemainingDays) * 100 / (formik.values.currentYearVacationDays.currentAvailableDays + formik.values.currentYearVacationDays.daysOff)) > 80
                                                ?
                                                    <>
                                                        <span>{'Ajoj'}</span>
                                                        <Divider />
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                            }
                                        </div>
                                    </div>

                                </>
                                :
                                <></>
                            }
                        </div>
                    </div>
                    : <></>}
            </div>
        </form>
    );
}

export default MyInformation;