import {FormikErrors, useFormik} from "formik";
import {Divider} from "primereact/divider";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {ProgressBar} from 'primereact/progressbar';
import {TabPanel, TabView} from 'primereact/tabview';
import {classNames} from "primereact/utils";
import {useEffect, useState} from "react";
import Client, {
    convertFromEUViesApiToClient,
    convertFromSudregApiToClient,
    makeClient,
    revertClient
} from "../../../models/Client";
import {CheckAddressHereApi} from "../../../shared/api/CheckAddressHereApi";
import {getClientInfoSudregApi} from "../../../shared/api/GetClientInfoSudregApi";
import getClientVIESCode from "../../../shared/api/GetClientVIESCode";
import {getConfigApiKeys} from "../../../shared/api/GetConfigApiKeys";
import {create} from "../../../shared/api/endpoints/CrudApi";
import GenericButton, {
    EButtonColor,
    EButtonIcons,
    EButtonStyle
} from "../../../shared/components/generic/button/GenericButton";
import DialogFooter from "../../../shared/components/generic/dialog/DialogFooter";
import {getAllVIESCountryCodes, validateClientVIESFormat} from "./ClientVIESValidation";

interface IClientFormProps {
    hide: () => void,
    initialValues: Client,
    addNewDataToTable: React.Dispatch<any>
}

function ClientForm(props: IClientFormProps) {

    const [client, setClient] = useState(makeClient(props.initialValues) ?? undefined)
    const [searchOIB, setSearchOIB] = useState<string | undefined>(undefined);
    const [clientFound, setClientFound] = useState<boolean>();
    const [showAddress, setShowAddress] = useState<boolean>(false);
    const [addresses, setAddresses] = useState<Array<any>>([]);
    const [selectedAddress, setSelectedAddress] = useState<any | undefined>(undefined);
    const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
    const [searchVIES, setSearchVIES] = useState<string | undefined>(undefined);
    const [selectedCountryCodeVIES, setSelectedCountryCodeVIES] = useState<string | undefined>(undefined);
    const [isSearchVIESDisabled, setIsSearchVIESDisabled] = useState<boolean>(true)
    const [isValidVIES, setIsValidVIES] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>(undefined);
    const [clientFromApi, setClientFromApi] = useState<Client | undefined>(new Client);
    const [isCountryCodeVIESDisabled, setIsCountryCodeVIESDisabled] = useState<boolean>(false);
    const [isSearchOIBDisabled, setIsSearchOIBDisabled] = useState<boolean>(false);

    useEffect(() => {
        // Handle enable/disable of VIES search field on automatic vies search
        if (selectedCountryCodeVIES === undefined) {
            setIsSearchVIESDisabled(true);
        } else {
            setIsSearchVIESDisabled(false);
        }

        // Handle enable/disable of VIES search field on manual vies search
        if (formik.values.countryCode === undefined || formik.values.countryCode === '') {
            setIsSearchVIESDisabled(true);
        } else {
            setIsSearchVIESDisabled(false);
        }

    }, [selectedCountryCodeVIES]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: client!,
        validate: (values: Client) => {
            let errors: FormikErrors<Client> = {};

            if (!values.name || values.name === '') {
                errors.name = 'Ime je obavezno polje.';
            }

            if (!values.identifier || values.identifier === '') {
                errors.identifier = 'Identifikator je obavezno polje.'
            }

            if (!values.countryCode || values.countryCode === '') {
                errors.countryCode = 'Obavezno polje.';
            }

            return errors;
        },
        onSubmit:
            async values => {
                values = revertClient(values);

                values.id = undefined;
                await create(`erv/admin/clients`, values).then(res => props.addNewDataToTable(makeClient(res.data)));
                formik.resetForm();

                props.hide();

            },
    });

    // Pick up errors when value of searchVIES(on automatic vies) or identifier(on manual input) value changes
    useEffect(() => {
        selectedCountryCodeVIES ?
            searchVIES !== undefined && searchVIES !== '' ?
                setErrors(validateClientVIESFormat(selectedCountryCodeVIES!, searchVIES!))
                :
                setErrors(validateClientVIESFormat(formik.values.countryCode!, formik.values.identifier!))
            :
            setErrors(undefined);
    }, [searchVIES, formik.values.identifier]);

    // Set validVIES to true or false based on changes in errors object
    useEffect(() => {
        errors?.viesError === undefined && searchVIES !== undefined && searchVIES !== '' ?
            setIsValidVIES(true)
            :
            setIsValidVIES(false);
    }, [errors]);

    // When VIES is valid call api and set all the necessary variables
    useEffect(() => {
        (async () => {
            if (isValidVIES) {
                setShowProgressBar(true);
                setIsSearchVIESDisabled(true);
                setIsCountryCodeVIESDisabled(true);

                setClientFromApi(convertFromEUViesApiToClient(await getClientVIESCode(selectedCountryCodeVIES, searchVIES!, await getConfigApiKeys().then((res: any) => { return res?.data.cloudmersiveApiKey }))))
            }
        })()
    }, [isValidVIES]);

    // Catch response from VIES api and fill the form or throw error
    useEffect(() => {
        if (showProgressBar && clientFromApi) {
            formik.setValues(clientFromApi!);
            setClientFound(true);
            setShowProgressBar(false);
            setIsSearchVIESDisabled(false);
            setIsCountryCodeVIESDisabled(false);

        } else if (errors !== undefined) {
            setErrors({
                "viesError": "Klijent nije pronađen"
            });
            setShowProgressBar(false);
            setIsSearchVIESDisabled(false);
            setIsCountryCodeVIESDisabled(false);
            setClientFound(false);
        }

    }, [clientFromApi])

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    function showErrorClientNotFound() {
        return searchOIB && searchOIB?.length === 11 ?
        !clientFound && !isSearchOIBDisabled && <small className="p-error">Klijent sa traženim OIB-om nije pronađen</small>
        :
        searchOIB !== undefined && searchOIB !== '' && <small className="p-error">OIB mora imati točno 11 znakova</small>;

    }

    //
    // FIXME: Most of this logic can be combined and/or converted to useEffect hooks like VIES search was done
    //
    // Search OIB handler for automatic client search by OIB
    const handleSearchOibChange = async (e: any) => {
        setSearchOIB(e.target.value);
        setShowProgressBar(true);
        let sudregClient: Client | undefined = new Client;

        if (e.target.value.length === 11) {
            setIsSearchOIBDisabled(true);
            sudregClient = convertFromSudregApiToClient(await getClientInfoSudregApi(e.target.value!, await getConfigApiKeys().then((res: any) => { return res?.data.sudregApiKey })))
            setShowProgressBar(false);
            if (sudregClient) {
                setClientFound(true);
                formik.setValues(makeClient(sudregClient));
                setIsSearchOIBDisabled(false);
            } else {
                setClientFound(false);
                setIsSearchOIBDisabled(false);
            }
            const addressFromHere = await CheckAddressHereApi(await getConfigApiKeys().then((res: any) => { return res?.data.hereApiKey }), sudregClient?.streetName, sudregClient?.streetNumber, sudregClient?.city, sudregClient?.country);
            formik.setFieldValue('zip', addressFromHere?.items[0]?.address?.postalCode);
        } else {
            formik.setValues(makeClient(sudregClient))

            setClientFound(false);
            setShowProgressBar(false);
            setIsSearchOIBDisabled(false);
        }
    };

    const toggleCheckAddressButtonOnChange = (event: any) => {
        if ((event.target.name === 'streetName' || event.target.name === 'streetNumber') && (formik.values.streetName && formik.values.streetNumber)) {
            showCheckAddressButton();
        }
    };

    const toggleShowClientAddress = async () => {
        const addressesFromHere = await CheckAddressHereApi(await getConfigApiKeys().then((res: any) => { return res?.data.hereApiKey }), formik.values.streetName, formik.values.streetNumber, formik.values.city, formik.values.country).then(res => { return res })
        setAddresses(addressesFromHere?.items.map((address: any) => { return address }));
        setShowAddress(true);
        setSelectedAddress(undefined);
    }

    const progressBar = () => {
        return (
            showProgressBar &&
            <ProgressBar mode="indeterminate" style={{ height: '6px', marginTop: '5px' }} color="#22C55E"></ProgressBar>
        )
    }

    // Showing button for checking address over Here api
    const showCheckAddressButton = () => {
        if ((clientFound === false || clientFound === undefined) && formik.values.streetName !== '' && formik.values.streetNumber !== '') {
            return (
                <div className="col-5">
                    <GenericButton label="Provjera adrese" icon={EButtonIcons.INFO} onClick={toggleShowClientAddress}
                        color={EButtonColor.LUMINUM_BLUE}
                        styled={EButtonStyle.NORMAL} />
                </div>
                        
            )
        }
    }

    const showClientAddress = () => {
        if (showAddress && (clientFound === false || clientFound === undefined) && formik.values.streetName !== '' && formik.values.streetNumber !== '') {
            return (
                <>
                    <div className="flex justify-content-end col-7">
                        <Dropdown value={selectedAddress} onChange={(e) => handleAddressChange(e)} options={addresses} optionLabel="title"
                            editable filter placeholder="Adrese" className="w-full" emptyMessage="Nije pronađen nijedan rezultat" />
                    </div>
                </>
            )
        } else return <></>
    }

    // Here api address change
    const handleAddressChange = (e: any) => {
        setSelectedAddress(e.value);

        formik.setFieldValue('country', e.value!.address.countryName);
        formik.setFieldValue('city', e.value!.address.city);
        formik.setFieldValue('streetName', e.value!.address.street);
        formik.setFieldValue('streetNumber', e.value!.address.houseNumber);
        formik.setFieldValue('zip', e.value!.address.postalCode);
    }

    const handleSelectedCountryCodeVIESChange = (e: any) => {

        setSelectedCountryCodeVIES(e.value);
        formik.setFieldValue('countryCode', e.value);
        setSearchVIES('')
    }

    const handleSearchVIESChange = (e: any) => {

        setSearchVIES(e.target.value)
    }

    function showErrorClientVIESNotFound() {
        return !isValidVIES ?
            <small className="p-error">{errors?.viesError}</small>
            :
            <></>;
    }

    // Cleanup when user changes tab
    const handleTabChange = () => {
        formik.handleReset(props.initialValues);
        setSelectedCountryCodeVIES(undefined);
        setSearchVIES(undefined);
        setSearchOIB(undefined);
        setClientFound(false);
    }

    // Client form used inside tabs
    const clientForm = () => {
        return (
            <form onChange={toggleCheckAddressButtonOnChange}>
                <div>
                    <div style={{ border: "transparent" }}>
                        <div className="p-fluid grid" style={{ paddingTop: '20px' }}>

                            <div className="field col-3">
                                <span className="p-float-label">
                                    <Dropdown value={formik.values.countryCode} onChange={(e) => handleSelectedCountryCodeVIESChange(e)} options={getAllVIESCountryCodes()} optionLabel=""
                                        editable filter placeholder="Kodovi" className="w-full" emptyMessage="Nije pronađen nijedan rezultat" />
                                    <label htmlFor="countryCode"
                                        className={classNames({ 'p-error': isFormFieldValid('countryCode') })}>Kod</label>
                                </span>
                                {getFormErrorMessage('countryCode')}

                            </div>
                            <div className="field col-4">
                                <span className="p-float-label">
                                    <InputText id="identifier" name="identifier" value={formik.values.identifier}
                                        onChange={formik.handleChange} disabled={!formik.values.isActive || isSearchVIESDisabled}
                                        />
                                    <label htmlFor="identifier"
                                        className={classNames({ 'p-error': isFormFieldValid('identifier') })}>Identifikator</label>
                                </span>
                                {getFormErrorMessage('identifier')}
                                {showErrorClientVIESNotFound()}
                            </div>
                            <div className="field col-5">
                                <span className="p-float-label">
                                    <InputText id="name" name="name" value={formik.values.name}
                                        disabled={!formik.values.isActive}
                                        onChange={formik.handleChange} />
                                    <label htmlFor="name"
                                        className={classNames({ 'p-error': isFormFieldValid('name') })}>Naziv</label>
                                </span>
                                {getFormErrorMessage('name')}
                            </div>
                            <div className="field col-6">
                                <span className="p-float-label">
                                    <InputText id="country" name="country" value={formik.values.country}
                                        disabled={!formik.values.isActive}
                                        onChange={formik.handleChange} />
                                    <label htmlFor="country"
                                        className={classNames({ 'p-error': isFormFieldValid('country') })}>Država</label>
                                </span>
                                {getFormErrorMessage('country')}

                            </div>

                            <div className="field col-6">
                                <span className="p-float-label">
                                    <InputText id="city" name="city" value={formik.values.city}
                                        disabled={!formik.values.isActive}
                                        onChange={formik.handleChange} />
                                    <label htmlFor="city"
                                        className={classNames({ 'p-error': isFormFieldValid('city') })}>Grad</label>
                                </span>
                                {getFormErrorMessage('city')}

                            </div>

                            <div className="field col-6">
                                <span className="p-float-label">
                                    <InputText id="streetName" name="streetName" value={formik.values.streetName}
                                        disabled={!formik.values.isActive}
                                        onChange={formik.handleChange} />
                                    <label htmlFor="streetName"
                                        className={classNames({ 'p-error': isFormFieldValid('streetName') })}>Ulica</label>
                                </span>
                                {getFormErrorMessage('streetName')}

                            </div>

                            <div className="field col-3">
                                <span className="p-float-label">
                                    <InputText id="streetNumber" name="streetNumber" value={formik.values.streetNumber}
                                        disabled={!formik.values.isActive}
                                        onChange={formik.handleChange} />
                                    <label htmlFor="streetNumber"
                                        className={classNames({ 'p-error': isFormFieldValid('streetNumber') })}>Ulični broj</label>
                                </span>
                                {getFormErrorMessage('streetNumber')}

                            </div>

                            <div className="field col-3">
                                <span className="p-float-label">
                                    <InputText id="zip" name="zip" value={formik.values.zip} onChange={formik.handleChange}
                                        disabled={!formik.values.isActive} />
                                    <label htmlFor="zip" className={classNames({ 'p-error': isFormFieldValid('zip') })}>Zip kod</label>
                                </span>
                                {getFormErrorMessage('zip')}

                            </div>
                        </div>
                    </div>
                    <Divider layout="horizontal" />

                    <div className="flex">
                        {showCheckAddressButton()}
                        {showClientAddress()}

                    </div>

                    <DialogFooter hide={props.hide} onClick={formik.handleSubmit}
                                  isDisabledOnDelete={!formik.values?.isActive}
                                  isDisabledOnUpdate={!formik.values?.isActive}/>
                </div>
            </form>
        )
    }
    return (
            <TabView onBeforeTabChange={() => handleTabChange()}>
                <TabPanel header="Sudski registar">
                    <span>Pretraživanje sudskog registra po OIB-u:</span>
                    {progressBar()}

                    <Divider />

                    <div className="field col-12">
                        <span className="p-float-label">
                        <InputText
                                id="searchOIB"
                                name="searchOIB"
                                value={searchOIB}
                                onChange={(e) => handleSearchOibChange(e)}
                                keyfilter="num"
                                disabled={isSearchOIBDisabled}
                                />

                            <label htmlFor="searchOIB"
                                className={classNames({ 'p-error': isFormFieldValid('searchOIB') })}>Pretraži po OIB-u</label>
                        </span>
                        {showErrorClientNotFound()}

                    </div>
                    <Divider type="solid" layout="horizontal" style={{ borderBottom: "1px solid #c7cdd1" }} />
                    {clientFound ?
                        clientForm()
                        :
                        <></>
                    }
                </TabPanel>
                <TabPanel header="VIES broj">
                    <span>Pretraživanje po VIES broju:</span>
                    {progressBar()}

                    <Divider />

                    <div className="p-fluid grid">
                        <div className="field col-3">
                            <span className="p-float-label">
                                <Dropdown value={selectedCountryCodeVIES} onChange={(e) => handleSelectedCountryCodeVIESChange(e)} options={getAllVIESCountryCodes()} optionLabel=""
                                    editable filter className="w-full" emptyMessage="Nije pronađen nijedan rezultat" disabled={isCountryCodeVIESDisabled} />
                                <label htmlFor="selectedCountryCodeVIES"
                                    className={classNames({ 'p-error': isFormFieldValid('selectedCountryCodeVIES') })}>Kod</label>
                            </span>

                        </div>
                        <div className="field col-9">
                            <span className="p-float-label">
                                <InputText
                                    id="searchVIES"
                                    name="searchVIES"
                                    value={searchVIES}
                                    onChange={(e) => handleSearchVIESChange(e)}
                                    disabled={isSearchVIESDisabled} />
                                <label htmlFor="searchVIES"
                                    className={classNames({ 'p-error': isFormFieldValid('searchVIES') })}>Pretraži po VIES-u</label>
                            </span>
                            {showErrorClientVIESNotFound()}

                        </div>
                    </div>
                    <Divider type="solid" layout="horizontal" style={{ borderBottom: "1px solid #c7cdd1" }} />
                    {clientFound ?
                        clientForm()
                        :
                        <></>
                    }
                </TabPanel>
                <TabPanel header="Ručno popunjavanje forme" >
                    <>
                        {clientForm()}
                    </>
                </TabPanel>
            </TabView>
    );
}

export default ClientForm;