import React from "react";
import GenericTabMenu, {TabMenuItem, TabMenuTab} from "../../shared/components/generic/tab-menu/GenericTabMenu";


const AbsenceRequestTabMenu = (props: TabMenuTab) => {
    const tabItems = [
        {
            id: "0",
            label: "Svi",
            component: "AbsenceRequestList"
        },
        {
            id: "0",
            label: "Zaposlenici",
            component: "EmployeeAbsenceRequestList"
        },
        {
            id: "1",
            label: "Vanjski",
            component: "ExternalAbsenceRequestList"
        },
        {
            id: "1",
            label: "Studenti",
            component: "StudentAbsenceRequestList"
        }
    ] as TabMenuItem[];

    return (
        <>
            <GenericTabMenu items={tabItems} activeTab={props.activeTab}/>
        </>
    )
}

export default AbsenceRequestTabMenu;