import React, {useState} from "react";
import {
    getAllAbsenceRequests,
    getAllEmployeeAbsenceRequests,
    getAllExternalAbsenceRequests,
    getAllStudentAbsenceRequests
} from "../../../shared/api/GetFormData";
import GenericDialog from "../../../shared/components/generic/dialog/GenericDialog";
import GenericTable, {TableOptions} from "../../../shared/components/generic/table/GenericTable";
import AbsenceRequestForm from "./AbsenceRequestForm";
import AbsenceRequestTemplate from "./AbsenceRequestTemplate";
import AbsenceRequest from "../../../models/AbsenceRequest";

const AbsenceRequestList = (props: {filter: string}) => {

    const [visibilityNewRequest, setVisibilityNewRequest] = useState(false);
    const [initialValues, setInitialValues] = useState<any | undefined>(undefined);
    const [dataFromChildCreate, setDataFromChildCreate] = useState<any | undefined>(undefined);
    const [visibilityColumnSelectionModal, setVisibilityColumnSelectionModal] = useState<boolean>(false);
    const [initialValuesColumnSelection, setInitialValuesColumnSelection] = useState<any | undefined>(undefined);

    const onHideColumnSelectionModal = () => {
        if (visibilityColumnSelectionModal) {
            setVisibilityColumnSelectionModal(false);
        }
    };

    const onHideNewRequest = () => {
        if (visibilityNewRequest) {
            setVisibilityNewRequest(false);
        }
    };

    const fetchData = (filter?:string) => {
        switch(filter) {
            case "external":
                return getAllExternalAbsenceRequests();
            case "student":
                return getAllStudentAbsenceRequests();
            case "employee":
                return getAllEmployeeAbsenceRequests();
            default:
                return getAllAbsenceRequests();

        }
    }

    const clientsTableOptions = {
        options: {
            title: "Pregled zahtjeva odsustva",
            toFetch: [fetchData(props.filter)],
            columns: {
                headers: ["Zaposlenik", "Datum od", "Datum do", "Broj dana", "Tip", "Status"],
                fieldNames: ["employeeDisplayName", "dateFrom", "dateTo", "usedDays", "absenceTypeToShowOnList", "absenceRequestStatusTypeName"],
                fieldTypes: ["string", "date", "date", "numeric", "string", "string"],
                searchByFieldNames: ["employeeDisplayName", "dateFrom", "dateTo", "usedDays", "absenceTypeToShowOnList", "absenceRequestStatusTypeName"],
            },
            isDataMutable: true,
            expansionTemplate: (params: { e: AbsenceRequest, handleDataFromChild: () => void }) =>
                <AbsenceRequestTemplate entity={params.e} handleDataFromChild={params.handleDataFromChild}/>,
            modalOptions: {
                modals: [
                    <GenericDialog key="modal-new" headerText="Novo odsutstvo" onHide={onHideNewRequest}
                                   visible={visibilityNewRequest}
                                   hasFooter={false}
                                   children={<AbsenceRequestForm hide={onHideNewRequest} initialValues={initialValues}
                                                                 addNewDataToTable={setDataFromChildCreate}
                                   />}/>,
                ],
                hideVariables: [
                    visibilityNewRequest,
                ],
                hideSetters: [
                    setVisibilityNewRequest,
                ],
                hideFunctions: [
                    onHideNewRequest,
                ],
                initialValues: {
                    initialValues: initialValues,
                    setInitialValues: setInitialValues
                },
                newValue: dataFromChildCreate
            },
            columnSelection:{
                headers: ["Zaposlenik", "Datum od", "Datum do", "Broj dana", "Tip", "Status"],
                fieldNames: ["employeeDisplayName", "dateFrom", "dateTo", "usedDays", "absenceTypeToShowOnList", "absenceRequestStatusTypeName"],
                hideVariables:visibilityColumnSelectionModal,
                hideSetters:setVisibilityColumnSelectionModal,
                hideFunctions:onHideColumnSelectionModal,
                initialValues:{
                    initialValues:initialValuesColumnSelection,
                    setInitialValues:setInitialValuesColumnSelection
                }
            }
        }
    } as TableOptions;

    return (
        <div>
            <GenericTable options={clientsTableOptions.options}></GenericTable>
        </div>
    );
}

export default AbsenceRequestList;