import React, {useEffect, useState} from "react";
import {FormikErrors, useFormik} from "formik";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import Holiday, {makeHoliday, revertHoliday} from "../../models/Holiday";
import {Calendar} from "primereact/calendar";
import {deleteById, edit} from "../../shared/api/endpoints/CrudApi";
import FormFooter from "../../shared/components/generic/form-inside-table/FormFooter";

interface HolidayTemplateProps {
    entity: Holiday;
    handleDataFromChild(e: any, action: string): void;
}

function HolidayTemplate(props: HolidayTemplateProps) {

    const [holiday, setHoliday] = useState<Holiday>(makeHoliday(props.entity))

    const formik = useFormik({
        initialValues: holiday!,
        validate: (values: Holiday) => {
            let errors: FormikErrors<Holiday> = {};

            if (!values.name) {
                errors.name = 'Ime je obavezno polje.';
            }

            if (!values.dateToShowOnForm) {
                errors.dateToShowOnForm = 'Datum blagdana je obavezno polje.';
            }

            return errors;
        },

        onSubmit:
            async values => {
                values = revertHoliday(values);
                await edit(`ego/holiday`, holiday.id ?? -1, values).then(res => res?.status === 200 ? props.handleDataFromChild(values, 'edit') : undefined);
            },
    });

    async function deleteHoliday() {
        await deleteById(`ego/holiday`, holiday.id ?? -1).then(res => res ? props.handleDataFromChild(formik.values, 'delete') : undefined);
    }

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    return (
        <div className="expanded-div">
            <form>
                <div>
                    <div style={{border: "transparent"}}>
                        <div className="p-fluid grid" style={{paddingTop: '20px'}}>
                            <div className="field col-4  md:col-4">
                            <span className="p-float-label">
                                <InputText id="name" name="name" value={formik.values.name}
                                           onChange={formik.handleChange}/>
                                <label htmlFor="name"
                                       className={classNames({'p-error': isFormFieldValid('name')})}>Naziv</label>
                            </span>
                                {getFormErrorMessage('name')}
                            </div>
                            <div className="field col-4  md:col-4">
                            <span className="p-float-label">
                                <InputText id="localName" name="localName" value={formik.values.localName}
                                           onChange={formik.handleChange}/>
                                <label htmlFor="localName">Lokalni naziv</label>
                            </span>
                            </div>
                            <div className="field col-4 md:col-4">
                            <span className="p-float-label">
                                <Calendar showButtonBar name="dateToShowOnForm" value={formik.values.dateToShowOnForm}
                                          onChange={formik.handleChange}
                                          dateFormat="dd.mm.yy." id="calendar"
                                          disabledDays={[0, 6]}/>
                                <label htmlFor="calendar">Odaberite datum početka</label>
                            </span>
                                {getFormErrorMessage('dateToShowOnForm')}
                            </div>
                        </div>
                    </div>
                    <FormFooter onClickUpdate={formik.handleSubmit} onClickDelete={deleteHoliday}/>
                </div>
            </form>
        </div>
    );
}

export default HolidayTemplate;