import React, {useEffect, useState} from "react";
import {FormikErrors, useFormik} from "formik";
import {edit} from "../../../shared/api/endpoints/CrudApi";
import {InputText} from "primereact/inputtext";
import Employee, {makeEmployee, revertEmployee} from "../../../models/Employee";
import {employeeDeactivation, getAllValuesForAttribute} from "../../../shared/api/endpoints/CustomApi";
import {Calendar} from "primereact/calendar";
import {dateTemplate} from "../absence-request/AbsenceRequestAssistance";
import {InputNumber} from "primereact/inputnumber";
import {Divider} from "primereact/divider";
import {MultiSelect} from "primereact/multiselect";
import Project from "../../../models/Project";
import {getAllHolidays, getAllManagers, getAllProjects} from "../../../shared/api/GetFormData";
import GenericButton, {EButtonIcons, EButtonStyle} from "../../../shared/components/generic/button/GenericButton";
import Holiday from "../../../models/Holiday";
import {classNames} from "primereact/utils";
import LdapAttribute from "../../../models/LdapAttribute";
import {Dropdown} from "primereact/dropdown";
import FormFooter from "../../../shared/components/generic/form-inside-table/FormFooter";
import moment from "moment";
import {checkEMail} from "../../../shared/utils/ValidationHelpers";

interface EmployeeTemplateProps {
    entity: Employee;
    handleDataFromChild(e: any, action: string): void;
}

function EmployeeTemplate(props: EmployeeTemplateProps) {

    const [employee, setEmployee] = useState<Employee>(
        makeEmployee(props.entity ?? undefined)
    )
    const [holidays, setHolidays] = useState<Holiday[]>();
    const [selectedProjects, setSelectedProjects] = useState<Project[] | undefined>(props.entity ? props.entity.projectsWithGrant : undefined);
    const [allProjects, setAllProjects] = useState<Project[]>();
    const [selectedManagers, setSelectedManagers] = useState<Employee[] | undefined>(props.entity ? props.entity.employeeLdap?.managers : undefined);
    const [managers, setManagers] = useState<Employee[]>();
    const [descriptionLdap, setDescriptionLdap] = useState<LdapAttribute>();
    const [departmentNumberAttribute, setEmployeeTypeLdap] = useState<LdapAttribute>();
    const [vacationDaysNotSet, setVacationDaysNotSet] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            setAllProjects(await getAllProjects());
        })();
        getAllHolidays().then((res) => {
            setHolidays(res);
        });
        (async () => {
            setManagers(await getAllManagers());
        })();
        (async () => {
            setDescriptionLdap(await getAllValuesForAttribute('description'));
        })();
        (async () => {
            setEmployeeTypeLdap(await getAllValuesForAttribute('departmentNumber'));
        })();

    }, [])

    useEffect(() => {
        if (formik.values.projectsWithGrant !== undefined) {
            formik.setFieldValue('projects', formik.values.projectsWithGrant);
        }
    }, [allProjects])

    useEffect(() => {
        if (employee.currentYearRemainingDays === 0 && employee.currentYearVacationDays?.currentAvailableDays === 0) {
            setVacationDaysNotSet(true);
        }
    }, [])

    const formik = useFormik({
        initialValues: employee!,
        validate: (values: Employee) => {
            let errors: FormikErrors<Employee> = {};

            if (!values.firstName || values.firstName === '') {
                errors.firstName = 'Ime je obavezno polje.';
            }

            if (!values.lastName || values.lastName === '') {
                errors.lastName = 'Prezime je obavezno polje.';
            }

            if (!values.email || values.email === '') {
                errors.email = 'Email je obavezno polje.'
            } else {
                checkEMail(values, errors);
            }

            if (!values.managers) {
                errors.managers = 'Mora biti odabran barem jedan nadređeni zaposlenik'
            }

            if (!values.descriptionAttribute) {
                errors.descriptionAttribute = 'Radno mjesto je obavezno polje'
            }

            if (!values.departmentNumberAttribute) {
                errors.departmentNumberAttribute = 'Tip zaposlenika je obavezno polje'
            }

            return errors;
        },
        onSubmit:
            async (values: Employee) => {
                values = revertEmployee(values);

                if(vacationDaysNotSet && formik.values.currentYearVacationDays?.currentAvailableDays !== 0){
                    values.currentYearRemainingDays = values.currentYearVacationDays?.currentAvailableDays;
                }

                if (values.employeeNumber) {
                    await edit(`admin/employee`, values.employeeNumber, makeEmployee(values))
                        .then(res => res ? props.handleDataFromChild(makeEmployee(res.data), 'edit') : undefined);
                }
            },
    });

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    function enableEmployee() {
        if (employee.employeeNumber) {
            formik.values.isActive = true;
            formik.values.employeeDatabase!.validTo = null;
            formik.values.validToToShowOnForm = undefined;
            formik.values.validTo = undefined;

            edit(`admin/employee`, employee.employeeNumber, makeEmployee(formik.values)).then(res => res ? props.handleDataFromChild(makeEmployee(formik.values), 'edit') : undefined);
        }
    }

    async function deleteEmployee() {
        formik.values.isActive = false;
        formik.values.employeeDatabase!.validTo = moment().toString();
        formik.values.validToToShowOnForm = moment().toDate();
        formik.values.validTo = moment().toString();
        await employeeDeactivation(formik.values.employeeNumber!).then(res => res ? props.handleDataFromChild(makeEmployee(formik.values), 'edit') : undefined);
    }

    return (
        <div className="expanded-div">
            <form>
                <div>
                    <div style={{border: "transparent"}}>
                        <div className="p-fluid grid" style={{paddingTop: '20px'}}>

                            <div className="field col-6">
                            <span className="p-float-label">
                                <InputText id="firstName" name="firstName"
                                           value={formik.values.firstName}
                                           onChange={formik.handleChange}/>
                                <label htmlFor="firstName"
                                       className={classNames({'p-error': isFormFieldValid('firstName')})}>Ime</label>
                            </span>
                                {getFormErrorMessage('firstName')}
                            </div>

                            <div className="field col-6">
                            <span className="p-float-label">
                                <InputText id="lastName" name="lastName"
                                           value={formik.values.lastName}
                                           onChange={formik.handleChange}/>
                                <label htmlFor="lastName"
                                       className={classNames({'p-error': isFormFieldValid('lastName')})}>Prezime</label>
                            </span>
                                {getFormErrorMessage('lastName')}
                            </div>

                            <div className="field col-6">
                            <span className="p-float-label">
                                <InputText id="email" name="email"
                                           value={formik.values.email}
                                           onChange={formik.handleChange}/>
                                <label htmlFor="email"
                                       className={classNames({'p-error': isFormFieldValid('email')})}>Email</label>
                            </span>
                                {getFormErrorMessage('email')}
                            </div>

                            <div className="field col-6">
                            <span className="p-float-label">
                                <InputText id="phoneNumber" name="phoneNumber"
                                           value={formik.values.employeeLdap?.phoneNumber}
                                           onChange={formik.handleChange}/>
                                <label htmlFor="phoneNumber"
                                       className={classNames({'p-error': isFormFieldValid('phoneNumber')})}>Broj mobitela</label>
                            </span>
                            </div>

                            <div className="field col-6">
                            <span className="p-float-label">
                                <Dropdown id="descriptionAttribute" value={formik.values.descriptionAttribute}
                                          options={descriptionLdap?.attributeValuesEnum}
                                          onChange={(e) => {
                                              formik.setFieldValue('descriptionAttribute', e.value);
                                          }}
                                          dataKey="name" optionLabel="name"/>
                                <label htmlFor="descriptionAttribute"
                                       className={classNames({'p-error': isFormFieldValid('descriptionAttribute')})}>Radni odnos</label>
                            </span>
                                {getFormErrorMessage('descriptionAttribute')}
                            </div>

                            <div className="field col-6">
                            <span className="p-float-label">
                                <Dropdown id="departmentNumberAttribute" value={formik.values.departmentNumberAttribute}
                                          options={departmentNumberAttribute?.attributeValuesEnum}
                                          onChange={(e) => {
                                              formik.setFieldValue('departmentNumberAttribute', e.value);
                                          }}
                                          dataKey="name" optionLabel="name"/>

                                <label htmlFor="departmentNumberAttribute"
                                       className={classNames({'p-error': isFormFieldValid('departmentNumberAttribute')})}>Pozicija zaposlenika</label>
                            </span>
                                {/* getFormErrorMessage('departmentNumberAttribute') */}
                            </div>

                            {formik.values.currentYearVacationDays && formik.values.lastYearVacationDays ?
                                    <>
                                        <Divider/>

                                        <div className="field col-3">
                                            <span className="p-float-label">
                                                <InputNumber id="currentYearVacationDays.currentAvailableDays" name="currentYearVacationDays.currentAvailableDays"
                                                             min={0}
                                                             value={formik.values.currentYearVacationDays.currentAvailableDays}
                                                             onChange={(e) => formik.setFieldValue('currentYearVacationDays.currentAvailableDays', e.value)}/>
                                                <label
                                                    htmlFor="currentYearVacationDays.currentAvailableDays">Dani godišnjeg {formik.values.currentYear ? ('za ' + formik.values.currentYear + '.') : ''}</label>
                                            </span>
                                            {/*getFormErrorMessage('currentYearVacationDays.currentAvailableDays')*/}
                                        </div>
                                        <div className="field col-3">
                                            <span className="p-float-label">
                                                <InputNumber id="currentYearVacationDays.daysOff" name="currentYearVacationDays.daysOff"
                                                             min={0}
                                                             value={formik.values.currentYearVacationDays.daysOff}
                                                             onChange={(e) => {
                                                                 formik.setFieldValue('currentYearVacationDays.daysOff', e.value);
                                                             }}/>
                                                <label
                                                    htmlFor="currentYearVacationDays.daysOff">Slobodni dani {formik.values.currentYear ? ('u ' + formik.values.currentYear + '.') : ''}</label>
                                            </span>
                                            {/*getFormErrorMessage('currentYearVacationDays.daysOff')*/}
                                        </div>
                                        <div className="field col-3">
                                            <span className="p-float-label border-none">
                                                <InputNumber id="currentYearRemainingDays" name="currentYearRemainingDays"
                                                             readOnly={true}
                                                             value={formik.values.currentYearRemainingDays}/>
                                                <label
                                                    htmlFor="currentYearRemainingDays">Preostali dani {formik.values.currentYear ? ('za ' + formik.values.currentYear + '.') : ''}</label>
                                            </span>
                                            {/*getFormErrorMessage('currentYearVacationDays.daysOff')*/}
                                        </div>
                                        <div className="field col-3">
                                            <span className="p-float-label">
                                                <InputNumber id="lastYearVacationDays.daysOff" name="lastYearVacationDays.daysOff"
                                                             min={0}
                                                             value={formik.values.lastYearVacationDays.daysOff}
                                                             onChange={(e) => formik.setFieldValue('lastYearVacationDays.daysOff', e.value)}/>
                                                <label
                                                    htmlFor="lastYearVacationDays.daysOff">Slobodni dani {formik.values.currentYear ? ('u ' + (formik.values.currentYear - 1) + '.') : ''}</label>
                                            </span>
                                            {/*getFormErrorMessage('lastYearVacationDays.daysOff')*/}
                                        </div>

                                    </>
                                    :
                                    <></>
                            }
                            <Divider/>

                            <div className="field col-6 md:col-6">
                            <span className="p-float-label">
                                <Calendar showButtonBar name="startDateToShowOnForm"
                                          value={formik.values.validFromToShowOnForm}
                                          onChange={(e) => {
                                              formik.setFieldValue('validFromToShowOnForm', e.value);
                                              formik.setFieldValue('validFrom', e.value);
                                          }}
                                          dateFormat="dd.mm.yy." id="calendarFrom"
                                          dateTemplate={(e) => dateTemplate(e, holidays)}
                                          disabledDays={[0, 6]}/>
                                <label htmlFor="calendarFrom">Zaposlen od</label>
                            </span>
                            </div>
                            <div className="field col-6 md:col-6">
                            <span className="p-float-label">
                                <Calendar showButtonBar name="endDateToShowOnForm" value={formik.values.validToToShowOnForm}
                                          onChange={(e) => {
                                              formik.setFieldValue('validToToShowOnForm', e.value);
                                              formik.setFieldValue('validTo', e.value);
                                          }}
                                          dateFormat="dd.mm.yy." id="calendarTo"
                                          dateTemplate={(e) => dateTemplate(e, holidays)}
                                          disabledDays={[0, 6]}/>
                                <label htmlFor="calendarTo">Zaposlen do</label>
                            </span>
                            </div>
                            <Divider/>

                            <div className="field col-12">
                            <span className="p-float-label">
                                <MultiSelect id="managers"
                                             value={selectedManagers}
                                             options={managers}
                                             filter
                                             onChange={(e) => {
                                                 let all = formik.values.employeeLdap?.managers;
                                                 all = [...e.value];
                                                 setSelectedManagers(all);
                                                 formik.setFieldValue("managers", all);
                                             }}
                                             dataKey="displayName"
                                             optionLabel="displayName"/>
                                <label htmlFor="managers">Nadređeni zaposlenici</label>
                            </span>
                                {getFormErrorMessage('managers')}
                            </div>

                            <div className="field col-12 md:col-12">
                            <span className="p-float-label">
                                <MultiSelect id="projectsWithGrant"
                                             value={selectedProjects}
                                             options={allProjects} filter
                                             onChange={(e) => {
                                                 let all = formik.values.projectsWithGrant;
                                                 all = [...e.value];
                                                 setSelectedProjects(all);
                                                 formik.setFieldValue("projectsWithGrant", all);
                                             }}
                                             dataKey="name"
                                             optionLabel="name"/>
                                <label htmlFor="projectsWithGrant">Projekti</label>
                            </span>
                            </div>
                            {!formik.values.isActive ?
                                <>
                                    <div className="field col-12 md:col-12">
                                        <div className="flex justify-content-center">
                                            Pritiskom na gumb možete označiti zaposlenika aktivnim
                                        </div>
                                    </div>
                                    <div className="field col-4 md:col-4 col-offset-4 md:col-offset-4">
                                        <div className="flex justify-content-center">
                                            <GenericButton onClick={enableEmployee} icon={EButtonIcons.PLUS}
                                                           styled={EButtonStyle.OUTLINED} label={"Aktiviraj"}/>
                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                            }
                        </div>
                    </div>
                    <FormFooter onClickUpdate={formik.handleSubmit} onClickDelete={() => deleteEmployee()}/>
                </div>
            </form>
        </div>
    );
}

export default EmployeeTemplate;