import moment from "moment";
import {getAllHolidays} from "../api/GetFormData";
import Holiday from "../../models/Holiday";

export const DATE_FORMAT_STRING = "DD.MM.YYYY";

export function convertToDate(stringToConvert: string | undefined | null) {
    if (stringToConvert === undefined) {
        return undefined;
    }
    if(stringToConvert === null) {
        return undefined;
    }
    let date = moment(stringToConvert, DATE_FORMAT_STRING);
    return date.toDate();
}

export function convertFromDate(dateToConvert: Date | undefined | null) {
    if (dateToConvert === undefined) {
        return undefined;
    }
    if (dateToConvert === null) {
        return undefined;
    }
    let date = moment(dateToConvert).format(DATE_FORMAT_STRING);
    if (date === "Invalid date") {
        return undefined;
    }
    return date;
}

export function isTodayDateInBetween(startDate: Date, endDate: Date): boolean {
    return moment().isBetween(startDate, endDate);
}

export function isWeekend(date: Date): boolean {
    let dayOfWeek = date.getDay();
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    return isWeekend;
}

export async function isDateHoliday(date: Date): Promise<boolean> {
    let holidays: Holiday[] = await getAllHolidays();
    let flag: boolean = false;
    holidays.forEach((h) => {
        if (h.dateToShowOnForm === date) {
            flag = true;
        }
    })
    return flag;
}

export function getTodaysDateAsString(): string | undefined {
    return convertFromDate(new Date(Date.now()));
}

export function getTodaysDate(): Date | undefined {
    return new Date(Date.now());
}

export function isDateBeforeToday(date: Date): boolean {
    return moment(getTodaysDate()).isAfter(date) || moment(getTodaysDate()).isSame(date);
}