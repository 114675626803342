import React from 'react';

const AppFooter = () => {
    return (
        <div className="flex flex-column sm:flex-row justify-content-between layout-footer align-items-center">
            <span></span>
            <span className="font-medium ml-2">© LuminumICT 2022</span>
            <span className="font-medium ml-2" style={{paddingRight:'1.5rem'}}>{process.env.REACT_APP_VERSION}</span>
        </div>
    );
};

export default AppFooter;
