import React, {Dispatch, SetStateAction, useState} from "react";
import {Dialog} from 'primereact/dialog';
import {Checkbox} from "primereact/checkbox";
import GenericButton, {EButtonColor, EButtonStyle} from "../button/GenericButton";

interface ColumnSelectionProps {
    visibility: boolean,
    onHide: () => void,
    allColumns: { field: string, header: string }[],
    visibleColumns: { field: string, header: string }[],
    setVisibleColumns: Dispatch<SetStateAction<{ field: string; header: string; }[]>>
    onColumnToggle: (event: any) => void
}

type ColumnType = {
    name: string;
    checked: boolean;
}

function ColumSelectionDialog(props: ColumnSelectionProps) {
    const [columns, setColumns] = useState<ColumnType[]>(props.allColumns.map(x => {
        return {name: x.field, checked: true};
    }));
    const [visibleColumns, setVisibleColumns] = useState(props.visibleColumns.map(x => x.field));
    const [isDisabled, setIsDisabled] = useState(false);

    const table = () => {
        let a = columns.map((column, index) => {
            let name = props.allColumns[index].header;
            let key = index + name;

            return (
                <div key={key} className="flex align-items-center p-2">
                    <Checkbox inputId={key} name="column" value={column} onChange={onCategoryChange}
                              checked={visibleColumns.includes(column.name)}/>
                    <label htmlFor={key} className="ml-2">{name}</label>
                </div>
            );
        });
        return (
            <>
                {a}
            </>);
    }

    const onCategoryChange = (ej: any) => {
        if (visibleColumns.length == 0 && ej.checked) {
            setIsDisabled(false);
        }
        let e: ColumnType = {name: ej.value.name, checked: ej.checked};
        let _selected = [...visibleColumns];

        if (e.checked) {
            _selected.push(e.name);
        } else {
            _selected = _selected.filter(category => category !== e.name);
            if (_selected.length === 0) {
                setIsDisabled(true);
            }
        }

        setVisibleColumns(_selected);
    }

    function onChange() {

        props.onColumnToggle(
            {
                value: visibleColumns.map(x => {
                    return {field: x}
                })
            }
        );
        props.onHide();
    }

    function footer() {
        return (
            <>
                {isDisabled ?
                    <p style={{color: EButtonColor.DARK_BLUE}}>
                        Mora biti izabrana barem jedna stavka.
                    </p>
                    :
                    <></>
                }
                <GenericButton label="Odaberi" id="columnSelection" disabled={isDisabled} styled={EButtonStyle.OUTLINED}
                               onClick={onChange}/>
            </>
        )
    }

    return (
        <>
            <Dialog header="Odaberite stupce"
                    visible={props.visibility}
                    modal
                    footer={footer}
                    onHide={props.onHide}>
                {table()}
            </Dialog>
        </>
    );
}

export default ColumSelectionDialog;