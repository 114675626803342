import {AxiosResponse} from "axios";
import {useEffect, useState} from "react";
import AbsenceRequest from "../../../models/AbsenceRequest";
import {getMyAbsenceRequests} from "../../../shared/api/GetFormData";
import {get} from "../../../shared/api/endpoints/CrudApi";
import GenericDialog from "../../../shared/components/generic/dialog/GenericDialog";
import GenericTable, {TableOptions} from "../../../shared/components/generic/table/GenericTable";
import MyAbsenceRequestForm from "./MyAbsenceRequestForm";
import MyAbsenceRequestTemplate from "./MyAbsenceRequestTemplate";
import Employee, {makeEmployee} from "../../../models/Employee";

const MyAbsenceRequestList = () => {

    const [visibilityNewRequest, setVisibilityNewRequest] = useState(false);
    const [initialValues, setInitialValues] = useState<any | undefined>(undefined);
    const [employee, setEmployee] = useState<Employee>();
    const [dataFromChildCreate, setDataFromChildCreate] = useState<any | undefined>(undefined);
    const [visibilityColumnSelectionModal, setVisibilityColumnSelectionModal] = useState<boolean>(false);
    const [initialValuesColumnSelection, setInitialValuesColumnSelection] = useState<any | undefined>(undefined);

    const onHideColumnSelectionModal = () => {
        if (visibilityColumnSelectionModal) {
            setVisibilityColumnSelectionModal(false);
        }
    };

    useEffect(() => {
        get('user/info').then((res: AxiosResponse) => {
            setEmployee(makeEmployee(res.data));
        });
    }, []);

    const onHideNewRequest = () => {
        if (visibilityNewRequest) {
            setVisibilityNewRequest(false);
        }
    };

    const clientsTableOptions = {
        options: {
            title: "Pregled mojih zahtjeva odsustva",
            toFetch: [getMyAbsenceRequests()],
            columns: {
                headers: ["Datum od", "Datum do", "Broj dana", "Tip", "Status"],
                fieldNames: ["dateFrom", "dateTo", "usedDays", "absenceTypeToShowOnList", "absenceRequestStatusTypeName"],
                fieldTypes: ["date", "date", "numeric", "string", "string"],
                searchByFieldNames: ["dateFrom", "dateTo", "usedDays", "absenceTypeToShowOnList", "absenceRequestStatusTypeName"],
            },
            hasHeader: false,
            isDataMutable: true,
            expansionTemplate: (params: {e: AbsenceRequest, handleDataFromChild: () => void}) => <MyAbsenceRequestTemplate entity={params.e} handleDataFromChild={params.handleDataFromChild}/>,
            modalOptions: {
                modals: [
                    <GenericDialog key="modal-new" headerText="Novo odsutstvo" onHide={onHideNewRequest}
                                   visible={visibilityNewRequest}
                                   hasFooter={false}
                                   children={<MyAbsenceRequestForm hide={onHideNewRequest} initialValues={initialValues} addNewDataToTable={setDataFromChildCreate}
                                                                   />}/>
                ],
                hideVariables: [
                    visibilityNewRequest,
                ],
                hideSetters: [
                    setVisibilityNewRequest,
                ],
                hideFunctions: [
                    onHideNewRequest,
                ],
                initialValues: {
                    initialValues: initialValues,
                    setInitialValues: setInitialValues
                },
                newValue: dataFromChildCreate
            },
            columnSelection:{
                headers: ["Datum od", "Datum do", "Broj dana", "Tip", "Status"],
                fieldNames: ["dateFrom", "dateTo", "usedDays", "absenceTypeToShowOnList", "absenceRequestStatusTypeName"],
                hideVariables:visibilityColumnSelectionModal,
                hideSetters:setVisibilityColumnSelectionModal,
                hideFunctions:onHideColumnSelectionModal,
                initialValues:{
                    initialValues:initialValuesColumnSelection,
                    setInitialValues:setInitialValuesColumnSelection
                }
            },
            vacationDays:{
                available: employee && employee.currentYearVacationDays && employee.currentYearVacationDays.currentAvailableDays && employee.currentYearVacationDays.daysOff && employee.currentYearRemainingDays ? employee.currentYearVacationDays.currentAvailableDays + employee.currentYearVacationDays.daysOff - employee.currentYearRemainingDays : 0,
                remaining: employee && employee.currentYearRemainingDays ? employee.currentYearRemainingDays : 0
            }
        }
    } as TableOptions;

    return (
        <div>
            <GenericTable options={clientsTableOptions.options}></GenericTable>
        </div>
    );
}

export default MyAbsenceRequestList;