import React, { useEffect, useState } from "react";
import { FormikErrors, useFormik } from "formik";
import { create } from "../../../shared/api/endpoints/CrudApi";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import DialogFooter from "../../../shared/components/generic/dialog/DialogFooter";
import Journal, { makeJournal, makeJournalForInsert } from "../../../models/Journal";
import { Calendar } from "primereact/calendar";
import JournalType,{getAllJournalTypes} from "../../../models/JournalType";
import { InputNumber } from "primereact/inputnumber";
import moment from "moment";

interface JournalsFormProps {
    hide: () => void,
    initialValues: Journal
    addNewDataToTable: React.Dispatch<any>
}
function JournalsForm(props: JournalsFormProps) {

    const [journal, setJournal] = useState(makeJournal(props.initialValues ?? undefined))

    const [journalTypes, setJournalTypes] = useState(Array<JournalType>);


    useEffect(() => {
        (async () => {
            setJournalTypes(await getAllJournalTypes())
        })()
    }, []);


    const formik = useFormik({
        initialValues: journal!,
        validate: (values: Journal) => {
            let errors: FormikErrors<Journal> = {};

            if (!values.name) {
                errors.name = 'Naziv je obavezno polje.';
            }

            if (values.validFromToShowOnForm?.toString() == 'Invalid Date' || !values.validFromToShowOnForm) {
                errors.validFromToShowOnForm = 'Vrijedi od je obavezan.'
            }

            if (!values.journalType) {
                errors.journalType = 'Tip narudžbenice je obavezan.'
            }

            if(values.journalType?.id !== 4 && moment(values.validFromToShowOnForm).isSameOrAfter(values.validToToShowOnForm)) {
                errors.validToToShowOnForm = 'Datum kraja mora biti veći od datuma početka.'
            }

            if(values.journalType?.id !== 4) {
                if(!values.scheduledHours) {
                    errors.scheduledHours = 'Za ovaj tip narudžbenice je potrebno unijeti sate.'
                }
                if(values.validToToShowOnForm?.toString() == 'Invalid Date' || !values.validToToShowOnForm) {
                errors.validToToShowOnForm = 'Za ovaj tip narudžbenice je potrebno unijeti datum isteka.'
                }
            }

            return errors;
        },
        onSubmit:
            async values => {
                values = makeJournalForInsert(values);
                await create(`erv/admin/journal`, values).then(res => res.status === 200 ? props.addNewDataToTable(makeJournal(res.data)) : undefined);
                // await create(`erv/admin/journal`, values).then(res => res.status === 200 ? props.addNewDataToTable(makeJournal(res.data)) : undefined);
                formik.resetForm();
                props.hide();

            },
    });

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    const handleJournalTypes = (e: any) => {
        formik.setFieldValue('journalType',e.value)
        if(e.value.id === 4) {
            formik.setFieldValue('scheduledHours',undefined)
            formik.setFieldValue('validToToShowOnForm',undefined)
        }
        else {
        formik.setFieldValue('scheduledHours',formik.values.scheduledHours)
        formik.setFieldValue('validToToShowOnForm',formik.values.validToToShowOnForm)
        }
    }


    return (
        <form>
            <div>
                <div style={{ border: "transparent" }}>
                    <div className="p-fluid grid" style={{ paddingTop: '20px' }}>
                        <div className="field col-4">
                            <span className="p-float-label">
                                <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange}  />
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>Naziv narudžbenice</label>
                            </span>
                            {getFormErrorMessage('name')}
                        </div>

                        <div className="field col-4">
                            <span className="p-float-label">
                                <InputNumber id="scheduledHours" 
                                             name="scheduledHours" 
                                             value={formik.values.scheduledHours} 
                                             onValueChange={formik.handleChange} 
                                             min={1}
                                             disabled={formik.values.journalType?.id === 4 ? true : false}
                                              
                                             />
                                <label htmlFor="scheduledHours" className={classNames({ 'p-error': isFormFieldValid('scheduledHours') })}>Sati</label>
                            </span>
                            {getFormErrorMessage('scheduledHours')}

                        </div>

                        

                        <div className="field col-6 md:col-6">
                            <span className="p-float-label">
                                <Calendar showButtonBar name="validFromToShowOnForm" value={formik.values.validFromToShowOnForm}
                                            onChange={formik.handleChange}
                                            dateFormat="dd.mm.yy." id="calendarFrom"
                                            />
                                <label htmlFor="calendarFrom" className={classNames({ 'p-error': isFormFieldValid('validFromToShowOnForm') })}>Datum početka</label>
                            </span>
                            {getFormErrorMessage('validFromToShowOnForm')}
                        </div>
                        <div className="field col-6 md:col-6">
                            <span className="p-float-label">
                                    <Calendar showButtonBar name="validToToShowOnForm" value={formik.values.validToToShowOnForm}
                                            onChange={formik.handleChange}
                                            dateFormat="dd.mm.yy." id="calendarTo"
                                            disabled={formik.values.journalType?.id === 4 ? true : false}
                                    />
                                    <label htmlFor="calendarTo" className={classNames({ 'p-error': isFormFieldValid('validToToShowOnForm') })}>Datum kraja</label>
                            </span>
                            {getFormErrorMessage('validToToShowOnForm')}
                        </div>
                        <div className="field col-4">
                            <span className="p-float-label">
                                <Dropdown id="name" filter dataKey="name" name="name" value={formik.values?.journalType} options={journalTypes} onChange={handleJournalTypes} optionLabel="name" placeholder="Odaberi tip narudžbenice" />
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('journalType') })}>Tip narudžbenice</label>
                            </span>
                            {getFormErrorMessage('journalType')}
                        </div>
                    </div>
                </div>
                <DialogFooter hide={props.hide} onClick={formik.handleSubmit} />
            </div>
        </form >
    );
}

export default JournalsForm;