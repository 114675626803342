import ContactPerson from "../../models/ContactPerson";
import {FormikErrors} from "formik";
import Employee from "../../models/Employee";

export function checkEMail(values: ContactPerson | Employee, errors: FormikErrors<ContactPerson>) {
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Email adresa nije dobrog formata. Primjer: luminum@luminumict.hr';
    }
}

export function checkPhoneNumber(values: ContactPerson | Employee, errors: FormikErrors<ContactPerson>) {
    if (values.phoneNumber &&
        (!/^(\+|0{1,2})?[ -]?(\d{2,3})?[ -]?(\d{2,4})?[ -]?(\d{2,3})?[ -]?(\d{1,2})?$/.test(values.phoneNumber))) {
        errors.phoneNumber = 'Broj telefona nije dobrog formata.';
    }
}
