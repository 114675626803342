import React, {useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import SummarizationEmployee from "../../../../models/SummarizationEmployee";
import moment from "moment";

interface SummarizationTemplateYearsProps {
    entity: SummarizationEmployee;
    handleDataFromChild(e: any): void;
}
const SummarizationTemplateYears = (props: SummarizationTemplateYearsProps) => {

    const [expandedRows, setExpandedRows] = useState<any>(null);
    const [expandedData, setExpandedData] = useState<any>();
    const [rowData, setRowData] = useState<any>();
    const [dataFromChild, setDataFromChild] = useState({} as any);
    const [refreshTableData, setRefreshTableData] = useState<boolean | undefined>(undefined);
    const [dataToRefresh, setDataToRefresh] = useState({} as any)
    function handleDataFromChild(dataToUpdate: any) {
        setDataFromChild(dataToUpdate);
        setExpandedData({});
      }

    useEffect(() => {
        if(refreshTableData)
            props.handleDataFromChild(dataToRefresh);
    }, [refreshTableData])

    const formatDate = (dateString:any) => moment(dateString).format('DD.MM.YYYY.');
    const formatType = (typeString:any) => {
        switch(typeString) {
        case 'VACATION': return 'Godišnji odmor'
        case 'SICK_LEAVE': return 'Bolovanje'
        case 'EDUCATION': return 'Edukacija'
        }
    };

    function ExpansionTemplate (e: any, handleDataFromChild: any) {
        setRowData(e);
        return (<div>
            <DataTable value={e.absences}
                            height="100%"
                            emptyMessage="Nema rezultata"
                            >
                    <Column header="" style={{width: '0.5rem'}}/>
                    <Column header="#" headerStyle={{ width: '20%',paddingLeft:'6.1%' }} bodyStyle={{ width: '20%',paddingLeft:'6.1%' }} body={(data, options) => options.rowIndex + 1}></Column>
                    <Column dataType="numeric" header="Datum od" field="dateFrom" body={(dateString) => formatDate(dateString.dateFrom)} headerStyle={{ width: '1rem' }}></Column>
                    <Column dataType="numeric" header="Datum do" field="dateTo" body={(dateString) => formatDate(dateString.dateTo)} headerStyle={{ width: '1rem' }}></Column>
                    <Column dataType="numeric" header="Tip" field="type" body={(typeString) => formatType(typeString.type)} headerStyle={{ width: '1rem' }}></Column>
                    <Column dataType="numeric" header="Broj dana" field="numOfDays" headerStyle={{ width: '1rem' }}></Column>
        </DataTable>
        </div>)
    }
    return (
        <div>
        <DataTable value={props.entity.years}
                   height="100%"
                   rowExpansionTemplate={e => ExpansionTemplate(e, handleDataFromChild)}
                   expandedRows={expandedRows}
                   onRowToggle={(e) => setExpandedRows(e.data)}>
            <Column header="" style={{width: '0.5rem'}}/>
            <Column header="#" headerStyle={{ width: '5%',paddingLeft:'2.4rem' }} bodyStyle={{ width: '0.5rem',paddingLeft:'2.4rem' }} body={(data, options) => options.rowIndex + 1}></Column>
            <Column expander headerStyle={{ width: '5%',paddingLeft:'1rem' }}></Column>
            <Column dataType="numeric" header="Godina" field="year" headerStyle={{ width: '1rem'}}></Column>
            <Column dataType="numeric" header="Godišnji odmor" field="GO" headerStyle={{ width: '1rem' }}></Column>
            <Column dataType="numeric" header="Bolovanje" field="BOL" headerStyle={{ width: '1rem' }}></Column>
            <Column dataType="numeric" header="Edukacija" field="EDU" headerStyle={{ width: '1rem' }}></Column>
        </DataTable>
        </div>
    );



}

export default SummarizationTemplateYears