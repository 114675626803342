import React from "react";
import Employee from "../models/Employee";
import {Sidebar} from "primereact/sidebar";
import GenericButton, {
    EButtonColor,
    EButtonIcons,
    EButtonStyle
} from "../shared/components/generic/button/GenericButton";
import KeycloakService from "../shared/services/KeycloakService";
import {Divider} from "primereact/divider";
import adminManual from "../res/documentation/ERV12_20230213_Admin_Manual.pdf";
import userManual from "../res/documentation/ERV12_20230213_User_Manual.pdf";
import "../styles/layout/_user_sidebar.scss"


interface AppUserSidebarProps {
    visible: boolean,
    hide: () => void,
    employee: Employee | undefined;
}

const AppUserSidebar = (props: AppUserSidebarProps) => {
    return (
        <>
            <Sidebar visible={props.visible} 
                     position="right" 
                     onHide={() => props.hide()} 
                     className="min-w-min" 
                     icons={<div className="flex flex-row justify-content-between sidebar-logout-btn"><GenericButton label="Logout" color={EButtonColor.MILK_BLUE} styled={EButtonStyle.OUTLINED} icon={EButtonIcons.LOGOUT} onClick={() => KeycloakService.doLogout()}/></div>}
                     >
                {props.employee ?
                    <div className="flex flex-column justify-content-between h-full">
                        <div className="border-noround align-items-center">
                            <div className="sidebar-account-info-name">{props.employee.displayName}</div>
                            {/* <Divider style={{ height: '1px', background: EColors.LUMINUM_BLUE}}/> */}
                            <Divider />
                            <Divider />
                            <div>{props.employee.email}</div>
                            <Divider />

                            {KeycloakService.hasRole(['admin']) ?
                                <>
                                </>
                                :
                                <>
                                    <span>{'Ti si tu biciklom.'}</span>
                                    <Divider />
                                </>
                            }
                            <div>Broj dana godišnjeg: {props.employee.currentYearVacationDays?.currentAvailableDays}</div>
                            <Divider />
                            <div>Broj neiskorištenih dana: {props.employee.currentYearRemainingDays}</div>
                        </div>

                        {KeycloakService.hasRole(['admin']) ?
                            <div className="flex flex-column justify-content-end">
                                <Divider />
                                <div className="flex flex-row justify-content-evenly">
                                    <div>
                                        <a href={adminManual} target="_blank" rel="noreferrer" className="p-ripple active-route">
                                            <i className="layout-menuitem-icon pi pi-file-pdf pr-1"></i>
                                            <span className="layout-menuitem-text">Admin upute</span>
                                            <span role="presentation" className="p-ink"></span>
                                        </a>
                                    </div>

                                    <div>
                                        <a href={userManual} target="_blank" rel="noreferrer" className="p-ripple active-route">
                                            <i className="layout-menuitem-icon pi pi-file-pdf pr-1"></i>
                                            <span className="layout-menuitem-text">User upute</span>
                                            <span role="presentation" className="p-ink"></span>
                                        </a>
                                    </div>
                                </div>
                                <Divider />
                                <Divider />
                                <Divider />
                            </div>
                            :
                            <div className="flex flex-column justify-content-end">
                                <Divider />
                                <div className="flex flex-row justify-content-evenly">
                                    <div>
                                        <a href={userManual} target="_blank" rel="noreferrer" className="p-ripple active-route">
                                            <i className="layout-menuitem-icon pi pi-file-pdf pr-1"></i>
                                            <span className="layout-menuitem-text">User upute</span>
                                            <span role="presentation" className="p-ink"></span>
                                        </a>
                                    </div>
                                </div>
                                <Divider />
                                <Divider />
                                <Divider />
                            </div>
                        }
                    </div>
                    :
                    <div>
                    </div>
                }

            </Sidebar>
        </>
    );
}

export default AppUserSidebar;