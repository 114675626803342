import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {LayoutContext} from './context/layoutcontext';
import GenericButton, {EButtonIcons, EButtonStyle} from '../shared/components/generic/button/GenericButton';
import AppUserSidebar from "./AppUserSidebar";
import Employee, {getEmployee, makeEmployee} from "../models/Employee";
import {Image} from "primereact/image";
import LOGO from "../res/luminum-logo.svg";

const AppTopbar = forwardRef((props, ref) => {
    const {layoutState, onMenuToggle, showProfileSidebar} = useContext(LayoutContext);
    const [appUserSidebarVisible, setAppUserSidebarVisible] = useState<boolean>(false);
    const [employee, setEmployee] = useState<Employee>();
    const menubuttonRef = useRef(null);
    const topbarmenuRef = useRef(null);
    const topbarmenubuttonRef = useRef(null);
    const userPanel = useRef({} as any);
    const adminRole = process.env.REACT_APP_KEYCLOAK_ROLE_ADMIN;
    const urlPrefix = process.env.REACT_APP_URL_PREFIX;


    useImperativeHandle(ref, () => ({
        menubutton: menubuttonRef.current,
        topbarmenu: topbarmenuRef.current,
        topbarmenubutton: topbarmenubuttonRef.current
    }));
    useEffect(() => {
        (async () => {
            setEmployee(makeEmployee(await getEmployee()));
        })();
    }, [])
    const getEmployees = () => {
        setAppUserSidebarVisible(true);
    }

    return (
        <div className="layout-topbar flex justify-content-between">
            <div className='flex justify-content-around'>
                <button ref={menubuttonRef} type="button" className="p-link layout-menu-button layout-topbar-button"
                        onClick={onMenuToggle}>
                    <i className="pi pi-bars"/>
                </button>
                <NavLink to={`${urlPrefix}`} className="layout-topbar-logo">
                    <>
                        <span> <Image src={LOGO} alt="Image" title="LuminumICT"/> </span>
                    </>
                </NavLink>
            </div>
            <div ref={topbarmenuRef}>
                {/* TODO: Add calendar when implemented */}
                {/* <button type="button" className="p-link layout-topbar-button">
                        <i className="pi pi-calendar"></i>
                        <span>Kalendar</span>
                    </button> */}

                <GenericButton icon={EButtonIcons.EMPLOYEE} isRounded={true} type={"button"}
                               styled={EButtonStyle.OUTLINED} onClick={() => getEmployees()}/>
                <AppUserSidebar visible={appUserSidebarVisible} hide={() => setAppUserSidebarVisible(false)}
                                employee={employee}/>
            </div>
        </div>
    );
});

export default AppTopbar;
