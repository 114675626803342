import JournalProjectTimeSheet from "./JournalProjectTimeSheet";
import Project from "./Project";
import { convertFromDate } from "../shared/utils/DateUtils";
import { revertEmployee } from "./Employee";

export default class ProjectCommand extends Project{
    newJournalProjectLink?: JournalProjectTimeSheet;
    
}

export function revertProjectCommand(projectCommand: ProjectCommand) {
    projectCommand.startDate = convertFromDate(projectCommand.startDateToShowOnForm);

    projectCommand.endDate = convertFromDate(projectCommand.endDateToShowOnForm);

    projectCommand.employees = projectCommand.employees?.map(x => revertEmployee(x));

    return projectCommand;
}