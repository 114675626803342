import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {FormikErrors, useFormik} from "formik";
import {edit} from "../../../shared/api/endpoints/CrudApi";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {makeProject} from "../../../models/Project";
import ContactPerson, {makeContactPerson} from "../../../models/ContactPerson";
import Client, {makeClient} from "../../../models/Client";
import {
    getAllClients,
    getAllEmployees,
    getAllJournalsNotConnectedToProject,
    getJournalProjectTimesheetByProjectId
} from "../../../shared/api/GetFormData";
import {Dropdown} from "primereact/dropdown";
import {Calendar} from "primereact/calendar";
import Employee from "../../../models/Employee";
import {MultiSelect} from "primereact/multiselect";
import {Divider} from "primereact/divider";
import {Checkbox} from "primereact/checkbox";
import FormFooter from "../../../shared/components/generic/form-inside-table/FormFooter";
import {deleteEntity} from "../../../shared/components/generic/form-inside-table/GenericMethods";
import Journal from "../../../models/Journal";
import ProjectCommand, {revertProjectCommand} from "../../../models/ProjectCommand";
import JournalProjectTimeSheet, {
    makeJournalProjectTimesheetFromProjectAndJournal
} from "../../../models/JournalProjectTimeSheet";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import GenericButton, {
    EButtonColor,
    EButtonIcons,
    EButtonStyle
} from "../../../shared/components/generic/button/GenericButton";
import {convertFromDate, convertToDate} from "../../../shared/utils/DateUtils";
import moment from "moment";

interface ProjectCommandTemplateProps {
    entity: ProjectCommand;
    handleDataFromChild(e: any, action: string): void;
}

function ProjectTemplate(props: ProjectCommandTemplateProps) {

    const [project, setProject] = useState<ProjectCommand>(makeProject(props.entity ?? undefined)); 
    const [selectedEmployees, setSelectedEmployees] = useState<Employee[] | undefined>(props.entity.employees ?? undefined);
    const [allEmployees, setAllEmployees] = useState<Employee[]>();
    const [clients, setClients] = useState<Client[]>();
    const [clientToBill, setClientToBill] = useState<Client>(props.entity.clientToBill ? makeClient(props.entity.clientToBill) : new Client());
    const [clientToShip, setClientToShip] = useState<Client>(props.entity.clientToShip ? makeClient(props.entity.clientToShip) : new Client());
    const [contactPersons, setContactPersons] = useState<ContactPerson[]>();
    const [dateNow, setDateNow] = useState<Date>(new Date());
    const [isContactPersonDisabled, setIsContactPersonDisabled] = useState<boolean>(props.entity.clientToShip === undefined);
    const [allJournalsNotConnectedToProject, setAllJournalsNotConnectedToProject] = useState<Journal[]>();
    const [journal, setJournal] = useState<Journal | undefined>();
    const [journalProjectTimeSheet, setJournalProjectTimeSheet] = useState<JournalProjectTimeSheet[] | any>();
    const [selectedJournal,setSelectedJournal] = useState<JournalProjectTimeSheet | undefined>();
    const [dates, setDates] = useState<Date[] | undefined>(undefined);
    const [datesDefault, setDatesDefault] = useState<Date[] | undefined  | any[]>(undefined);
    const [areDatesDisabled,setAreDatesDisabled] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: project!,
        validate: (values: ProjectCommand) => {
            let errors: FormikErrors<ProjectCommand> = {};

            if (!values.name) {
                errors.name = 'Ime je obavezno polje.';
            }

            if (!values.clientToShip) {
                errors.clientToShip = 'Obavezno je odabrati klijenta.';
            }

            if (!values.clientToBill) {
                errors.clientToBill = 'Obavezno je odabrati klijenta.';
            }

            if (values.endDateToShowOnForm) {
                if (!values.startDateToShowOnForm) {
                    errors.endDateToShowOnForm = 'Datum kraja ne može biti definiran prije nego li se definira početak.';
                } else if (values.endDateToShowOnForm < values.startDateToShowOnForm) {
                    errors.endDateToShowOnForm = 'Datum kraja ne može biti prije datuma početka';
                }
            }

            return errors;
        },

        onSubmit:
            async values => {
                values = revertProjectCommand(values);
                if (values.id) {
                    await edit(`erv/projects`, values.id, values).then(res => res?.status === 200 ? props.handleDataFromChild(makeProject(values), 'edit') : undefined);
                }
            },
    });

    useEffect(() => {
        (async () => {
            setClients(await getAllClients());
        })();

        (async () => {
            setAllEmployees(await getAllEmployees());
        })();
        (async () => {
            setAllJournalsNotConnectedToProject(await getAllJournalsNotConnectedToProject(project.id)); 
        })();
        (async () => {
            setJournalProjectTimeSheet(await getJournalProjectTimesheetByProjectId(project.id)); 
        })();
        
    }, [])


    useEffect(() => {
        if (project.clientToShip !== undefined) {
            formik.setFieldValue('clientToShip', makeClient(clients?.filter((client: Client) => client.id === project.clientToShip?.id)[0]));
        }

        (async () => {
            if (clients !== undefined) {
                setContactPersons(formik.values.clientToShip?.contactPersons?.map(
                    (contactPerson: ContactPerson) => {
                        return makeContactPerson(contactPerson);
                    }));
            }
        })();
    }, [clients])

    useEffect(() => {
        if (formik.values.employees !== undefined) {
            formik.setFieldValue('selectedEmployees', formik.values.employees);
        }
    }, [allEmployees])

    useEffect(() => {
        if (formik.values.clientToShip === undefined) {
            setIsContactPersonDisabled(true);
        } else {
            (async () => {
                if (clients !== undefined) {
                    setContactPersons(formik.values.clientToShip?.contactPersons?.map(
                        (contactPerson: ContactPerson) => {
                            return makeContactPerson(contactPerson);
                        }));
                }
            })();
            setIsContactPersonDisabled(false);
        }
    }, [formik.values.clientToShip])

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    const handleJournalChange = (e: any) => {
        setDates(undefined);
        if(e.value) {
            let journal = e.value;
            setJournal(journal);
            let journalProjectTimeSheet = makeJournalProjectTimesheetFromProjectAndJournal(project, journal);
            formik.setFieldValue('newJournalProjectLink', journalProjectTimeSheet);
            let validFromFormated = convertToDate(convertFromDate(journal.validFromToShowOnForm));
            let validToFormated = convertToDate(convertFromDate(journal.validToToShowOnForm));
            setDatesDefault([validFromFormated, validToFormated]);
        } else {
            setJournal(undefined);
            formik.setFieldValue('newJournalProjectLink', undefined);
            setDatesDefault(undefined);
        }
    }

    const renderButton = (rowData: any) => {
        return (<GenericButton icon={EButtonIcons.IKS} onClick={() => deleteJournalProjectTimeSheet(rowData)} color={EButtonColor.LUMINUM_BLUE} styled={EButtonStyle.TRANSPOSE}/>)
    }

    async function deleteJournalProjectTimeSheet(rowData:any) {
        
        let result = await deleteEntity('erv/admin/journal-project', rowData);

    }

    const handleCalendarChange = (e: any) => {
        setDates(e.value as Date[]);
        
        formik.setFieldValue('newJournalProjectLink.validFrom', convertFromDate(e.value[0]));
        formik.setFieldValue('newJournalProjectLink.validTo', convertFromDate(e.value[1]));
    }

    async function deleteProject() {
        await deleteEntity(`erv/projects`, project).then(res => res ? props.handleDataFromChild(project, 'edit') : undefined);
    }

    function isBeforeToday(input: any): boolean {
        const dateA = new Date(input as Date);
        const today = new Date();
        return moment(dateA).isBefore(today, 'day');
    }

    useEffect(() => {
        if(datesDefault === undefined || datesDefault[1] === undefined){
            setAreDatesDisabled(true);
        } else {
            setAreDatesDisabled(false);
        }
    },[datesDefault])


    return (
        <div className="expanded-div">
            <form>
                <div>
                    <div style={{border: "transparent"}}>
                        <div className="p-fluid grid" style={{paddingTop: '20px'}}>
                            <div className="field col-12 md:col-12">
                                <span className="p-float-label">
                                    <InputText id="name" name="name" value={formik.values.name}
                                               onChange={formik.handleChange}/>
                                    <label htmlFor="name"
                                           className={classNames({'p-error': isFormFieldValid('name')})}>Naziv</label>
                                </span>
                                {getFormErrorMessage('name')}
                            </div>
                            <Divider/>
                            <div className="field col-6 md:col-6">
                                <span className="p-float-label">
                                    <Dropdown id="clientToBill" filter value={formik.values.clientToBill} options={clients}
                                              onChange={(e) => {
                                                  formik.setFieldValue('clientToBill', makeClient(e.value));
                                              }}
                                              dataKey="name" optionLabel="name"/>
                                    <label htmlFor="clientToBill"
                                           className={classNames({'p-error': isFormFieldValid('clientToBill')})}>Naručitelj</label>
                                </span>
                                {getFormErrorMessage('clientToBill')}
                            </div>
                            <div className="field col-6 md:col-6">
                                <span className="p-float-label">
                                    <InputText id="type" name="type" value={formik.values.type}
                                               onChange={formik.handleChange}/>
                                    <label htmlFor="type">Tip</label>
                                </span>
                            </div>
                            <div className="field col-6 md:col-6">
                                <span className="p-float-label">
                                    <Dropdown id="clients" value={clientToShip} options={clients}
                                              onChange={(e) => {
                                                  setClientToShip(e.value);
                                                  formik.setFieldValue('clientToShip', makeClient(e.value));
                                              }} filter name="name"
                                              dataKey="name" optionLabel="name"/>
                                    <label htmlFor="clients"
                                           className={classNames({'p-error': isFormFieldValid('clientToShip')})}>Krajnji
                                                korisnik</label>
                                </span>
                                {getFormErrorMessage('clientToShip')}
                            </div>

                            <div className="field col-6 md:col-6">
                                <span className="p-float-label">
                                    <>
                                            <Dropdown id="contactPerson" value={formik.values.contactPerson}
                                                      options={contactPersons}
                                                      onChange={(e) => {
                                                          formik.setFieldValue('contactPerson', makeContactPerson(e.value));
                                                      }} filter
                                                      disabled={isContactPersonDisabled}
                                                      name="contactPerson" dataKey="name" optionLabel="name"/>
                                            <label htmlFor="contactPerson"
                                                   className={classNames({'p-error': isFormFieldValid('contactPerson')})}>
                                                Kontakt osoba</label>
                                        </>
                                </span>
                                {getFormErrorMessage('contactPerson')}
                            </div>

                            <div className="field col-6 md:col-6">
                                <span className="p-float-label">
                                    <Calendar showButtonBar name="startDateToShowOnForm" value={formik.values.startDateToShowOnForm}
                                              onChange={formik.handleChange}
                                              dateFormat="dd.mm.yy." id="calendarFrom"
                                              disabledDays={[0, 6]}/>
                                    <label htmlFor="calendarFrom">Odaberite datum početka</label>
                                </span>
                                {getFormErrorMessage('startDateToShowOnForm')}
                            </div>
                            <div className="field col-6 md:col-6">
                                <span className="p-float-label">
                                    <Calendar showButtonBar name="endDateToShowOnForm" value={formik.values.endDateToShowOnForm}
                                              onChange={(e) => {
                                                  formik.setFieldValue("endDateToShowOnForm", e.value);
                                                  project.isActive = !isBeforeToday(e.value);
                                              }
                                              }
                                              dateFormat="dd.mm.yy." id="calendarTo"
                                              disabledDays={[0, 6]}/>
                                    <label htmlFor="calendarTo"
                                           className={classNames({'p-error': isFormFieldValid('endDateToShowOnForm')})}>Odaberite datum kraja</label>
                                </span>
                                {getFormErrorMessage('endDateToShowOnForm')}
                            </div>
                            <div className="field col-6">
                                <span className="p-float-label">
                                    <MultiSelect id="employees" value={selectedEmployees}
                                                 options={allEmployees}
                                                 onChange={(e) => {
                                                             let all = formik.values.employees;
                                                             all = [...e.value];
                                                             setSelectedEmployees(all);
                                                             formik.setFieldValue("employees", all);
                                                 }} filter dataKey="displayName"
                                                 optionLabel="displayName"/>
                                           <label htmlFor="employees">Zaposlenici</label>
                                </span>
                            </div>
                            <div className="flex justify-content-start field col-6">
                                <div className="field col-1 md:col-1 align-items-center">
                                    <Checkbox inputId="canAddMultipleTimeSheets" name="pizza"
                                                value="canAddMultipleTimeSheets" style={{marginLeft: "1rem", borderRadius: 0}}
                                                onChange={e => formik.setFieldValue("canAddMultipleTimeSheets", e.checked)}
                                                checked={formik.values.canAddMultipleTimeSheets ?? false}/>

                                </div>
                                <div className="field col-11 md:col-11">
                                    <div className="flex align-items-center">
                                        <label htmlFor="canAddMultipleTimeSheets" className="ml-2">Omogući dodavanje
                                            više evidencija rada za različite datume odjednom.</label>
                                    </div>
                                </div>
                            </div>
                                <Divider/>
                                <div className="field col-6 md:col-6">
                                    <div className="field col-12 md:col-12">
                                    <span className="p-float-label">
                                        <>
                                                <Dropdown id="journal" value={journal} showClear
                                                        options={allJournalsNotConnectedToProject}
                                                        onChange={
                                                            (e) => { handleJournalChange(e) }
                                                        } filter
                                                        name="journal" dataKey="name" optionLabel="name"/>
                                                <label htmlFor="journal"
                                                    className={classNames({'p-error': isFormFieldValid('journal')})}>
                                                    Odaberite novu narudžbenicu</label>
                                            </>
                                    </span>
                                    {getFormErrorMessage('journal')}
                                    </div>
                                    <Divider/>
                                    <div className="field col-12 md:col-12">
                                    <span className="p-float-label">
                                        <Calendar showButtonBar name="journalCalendar" value={dates ? dates : datesDefault}
                                                onChange={(e)=> handleCalendarChange(e)}
                                                selectionMode="range"
                                                dateFormat="dd.mm.yy." id="journalCalendar"
                                                minDate={datesDefault ? datesDefault[0] : undefined}
                                                maxDate={datesDefault ? datesDefault[1] : undefined}
                                                disabledDays={[0, 6]}
                                                disabled={areDatesDisabled}/>
                                        <label htmlFor="journalCalendar">Period narudžbenice</label>
                                    </span>
                                    {getFormErrorMessage('journalCalendar')}
                                    </div>
                                </div>
                            
                            <div className="col-6 md:col-6">
                                <DataTable value={journalProjectTimeSheet} selection={selectedJournal}  selectionMode="single" > 
                                <Column header="Ime narudžbenice" body={(rowData) => (<Link to={`/crm/journals`}>{rowData.journalName}</Link>)}></Column>
                                    <Column field="scheduledHours" header="Ukupni sati"></Column>
                                    <Column field="remainingHours" header="Preostali sati"></Column>
                                    <Column field="validFrom" header="Vrijedi od"></Column>
                                    <Column field="validTo" header="Vrijedi do"></Column>
                                    <Column header="Brisanje" body={renderButton}/>
                                </DataTable>
                            </div>
                            {formik.values.endDateToShowOnForm ? formik.values.endDateToShowOnForm < dateNow ?
                                    <div className="field col-12">
                                        <div className="flex justify-content-center">
                                            Projekt je završen. Nije moguće zatvoriti projekt koji je završio.
                                        </div>
                                    </div>
                                    :
                                    <></>
                                : <></>
                            }
                        </div>
                    </div>
                    
                    <FormFooter onClickUpdate={formik.handleSubmit}
                                onClickDelete={deleteProject}
                                labelOnDelete="Završi"
                                isDisabledOnDelete={formik.values.endDateToShowOnForm ? formik.values.endDateToShowOnForm < dateNow : false}/>
                </div>
            </form>
        </div>
    );
}

export default ProjectTemplate;