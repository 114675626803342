import {get} from "../shared/api/endpoints/CrudApi";

export default class AbsenceRequestStatusType {
    id!: number;
    enumName: string;
    name?: string;
    version?: number;


    constructor(id: number, enumName: string, name: string, version: number) {
        this.id = id;
        this.enumName = enumName;
        this.name = name;
        this.version = version;
    }
}

export const CAbsenceRequestStatusTypes: AbsenceRequestStatusType[] = [
    {name: "Zahtjev poslan", id: 1, enumName: "INITIAL"},
    {name: "Zahtjev odobren", id: 2, enumName: "APPROVED"},
    {name: "Zahtjev odbijen", id: 3, enumName: "DENIED"},
    {name: "Zahtjev otkazan", id: 4, enumName: "CANCELLED"},
    {name: "Zahtjev čeka na odobrenje", id: 5, enumName: "APPROVALREQUESTED"},
];

export const AbsenceRequestStatusType_INITIAL:AbsenceRequestStatusType = CAbsenceRequestStatusTypes[0];
export const AbsenceRequestStatusType_APPROVED:AbsenceRequestStatusType = CAbsenceRequestStatusTypes[1];
export const AbsenceRequestStatusType_DENIED:AbsenceRequestStatusType = CAbsenceRequestStatusTypes[2];
export const AbsenceRequestStatusType_CANCEL:AbsenceRequestStatusType = CAbsenceRequestStatusTypes[3];

export function makeAbsenceRequestStatusType(absenceRequestStatusType: AbsenceRequestStatusType) {
    if (absenceRequestStatusType.enumName) {
        for (let type of CAbsenceRequestStatusTypes) {
            if (absenceRequestStatusType.id === type.id) {
                absenceRequestStatusType.name = type.name;
                break;
            }
        }
    }
    return absenceRequestStatusType;
}

export async function getAllAbsenceRequestStatusTypes() {
    let res = await get('ego/absenceRequestStatusType')
        .then((response: any) => {
                if (response.status === 200) {
                    response.data.absenceRequestStatusTypes.map(
                        (absenceRequestStatusType: AbsenceRequestStatusType) => {
                            return makeAbsenceRequestStatusType(absenceRequestStatusType);
                        }
                    )
                    return response;
                } else {
                    return undefined;
                }
            }
        )
    let data;
    if (res !== undefined) {
        data = await res.data.absenceRequestStatusTypes;
    }
    return data;
}