import React from 'react';
import { HashRouter } from 'react-router-dom';
import { LayoutProvider } from '../layout/context/layoutcontext';
import Layout from '../layout/layout';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '../styles/themes/luminum-theme/theme.css';
import '../styles/layout/layout.scss';
import RenderOnAnonymous from '../shared/login-render-logic/RenderOnAnonymous';
import Welcome from '../components/login-render-components/Welcome';
import RenderOnAuthenticated from '../shared/login-render-logic/RenderOnAuthenticated';

const AppRouter = () => {
    return (
        <HashRouter >
            <RenderOnAnonymous >
                <Welcome />
            </RenderOnAnonymous >
            <RenderOnAuthenticated >
                <LayoutProvider >
                    <Layout />
                </LayoutProvider >
            </RenderOnAuthenticated >
        </HashRouter>
    );
};

export default AppRouter;