import React from "react";
import GenericPanel, {DataViewItemProp} from "./GenericPanel";
import {EButtonIcons} from "../../shared/components/generic/button/GenericButton";

const Overview = () => {

    const products = [
        {
            id: 1,
            name: "Moj profil",
            url: `/my_info`,
            icon: EButtonIcons.EMPLOYEE
        },
        {
            id: 2,
            name: "Narudžbenice",
            url: `/crm/journals`,
            icon: EButtonIcons.MY_ABSENCE_TIME
        },
        {
            id: 3,
            name: "Klijenti",
            url: `/crm/clients`,
            icon: EButtonIcons.EMPLOYEES
        },
        {
            id: 4,
            name: "Kont. osobe",
            url: `/crm/contactpersons`,
            icon: EButtonIcons.LINK
        },
        {
            id: 5,
            name: "Projekti",
            url: `/projects`,
            icon: EButtonIcons.MY_ABSENCE_TIME
        },
        {
            id: 6,
            name: "Evidencija radnog vremena",
            url: `/summoftimesheets`,
            icon: EButtonIcons.LIST
        },
        {
            id: 7,
            name: "Unos radnog vremena",
            url: `/timesheetentry`,
            icon: EButtonIcons.UPDATE
        },
        {
            id: 8,
            name: "Evidencija odsutstva",
            url: `/summofabsence`,
            icon: EButtonIcons.LIST
        },
        {
            id: 9,
            name: "Unos odsutstva",
            url: `/absenceentry`,
            icon: EButtonIcons.UPDATE
        },
        {
            id: 10,
            name: "Knjiga djelatnika",
            url: `/admin/workers`,
            icon: EButtonIcons.BOOK
        },
        {
            id: 11,
            name: "Blagdani",
            url: '/settings/holidays',
            icon: EButtonIcons.CALENDAR
        },
        {
            id: 12,
            name: "Tipovi podataka",
            url: '/settings/datatypes',
            icon: EButtonIcons.SEARCH
        }
    ] as DataViewItemProp[];


    return (
        <>
            <GenericPanel data={products}></GenericPanel>
        </>
    )
}

export default Overview;