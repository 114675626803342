import axios from "axios";
import { toast } from "react-toastify";
import { getConfigApiKeys } from "./GetConfigApiKeys";

export async function getClientInfoSudregApi(identificator: number, apiKey: string) {

    const identificatorType = 'oib';

    const headers = {
        headers:
        {
            "Ocp-Apim-Subscription-Key": apiKey ?? ''
        }
    }

    const res = getConfigApiKeys().then((res: any) => axios.get(`https://sudreg-api.pravosudje.hr/javni/subjekt_detalji?tipIdentifikatora=${identificatorType}&identifikator=${identificator}`, headers)
        .then((response) => {
            return response;
        }
        ))

    let data = await (await res).data;

    if (await data !== null) {
        toast.success('Uspješno!')
    } else if(await data === null){
        toast.error('Klijent nije pronađen')
    } else {
        toast.error('Dogodila se pogreška prilikom poziva sudskog registra.')
    }
    return data;
}