import React, {useState} from "react";
import GenericTable, {TableOptions} from "../table/GenericTable";
import GenericDialog from "../dialog/GenericDialog";
import SmallForm from "./SmallForm";
import Project from "../../../../models/Project";
import SmallTemplate from "./SmallTemplate";

interface SmallGenericTableProp {
    fieldTypes: string[];
    fetchAll:  Promise<any>,
    modalText: string,
    headers: string[],
    fieldNames: string[],
    isDataMutable: boolean,
    initialValues?: any,
    path?: string,
    updatePath?: string,
    child?: JSX.Element,
    isAbsenceType?: boolean,
}

const SmallGenericTable = (props: SmallGenericTableProp) => {
    const [visibilityNewRequest, setVisibilityNewRequest] = useState(false);
    const [initialValues, setInitialValues] = useState<any | undefined>(undefined);
    const [dataFromChildCreate, setDataFromChildCreate] = useState<any | undefined>(undefined);
    const [visibilityColumnSelectionModal, setVisibilityColumnSelectionModal] = useState<boolean>(false);
    const [initialValuesColumnSelection, setInitialValuesColumnSelection] = useState<any | undefined>(undefined);

    const onHideColumnSelectionModal = () => {
        if (visibilityColumnSelectionModal) {
            setVisibilityColumnSelectionModal(false);
        }
    };

    const onHideNewRequest = () => {
        if (visibilityNewRequest) {
            setVisibilityNewRequest(false);
        }
    };

    const clientsTableOptions = {
        options: {
            title: props.isDataMutable ? props.modalText : "It is not mutable",
            toFetch: [props.fetchAll],
            columns: {
                headers: props.headers,
                fieldNames: props.fieldNames,
                fieldTypes: props.fieldTypes,
                searchByFieldNames: props.fieldNames,
            },
            hasHeader: false,
            isDataMutable: props.isDataMutable,
            expansionTemplate: (params: {e: Project, handleDataFromChild: () => void}) => props.updatePath !== undefined ?
                <SmallTemplate initialValues={params.e} id={params.e.id?.toFixed(0) ?? ""} isAbsenceType={props.isAbsenceType} hide={onHideColumnSelectionModal} path={props.updatePath} handleDataFromChild={params.handleDataFromChild}/>
                :
                undefined,
            modalOptions: {
                modals: [
                    <GenericDialog key="modal-new" headerText={props.modalText} onHide={onHideNewRequest}
                                   visible={visibilityNewRequest}
                                   hasFooter={false}
                                   children={props.path && props.initialValues ?
                                       <SmallForm initialValues={props.initialValues} addNewDataToTable={setDataFromChildCreate} isAbsenceType={props.isAbsenceType} hide={onHideNewRequest} path={props.path}/>
                                       :
                                       props.child ? props.child : <></>}/>,
                ],
                hideVariables: [
                    visibilityNewRequest,
                ],
                hideSetters: [
                    setVisibilityNewRequest,
                ],
                hideFunctions: [
                    onHideNewRequest,
                ],
                initialValues: {
                    initialValues: initialValues,
                    setInitialValues: setInitialValues
                },
                newValue: dataFromChildCreate
            },
            columnSelection: {
                headers: props.headers,
                fieldNames: props.fieldNames,
                hideVariables: visibilityColumnSelectionModal,
                hideSetters: setVisibilityColumnSelectionModal,
                hideFunctions: onHideColumnSelectionModal,
                initialValues: {
                    initialValues: initialValuesColumnSelection,
                    setInitialValues: setInitialValuesColumnSelection
                }
            }
        }
    } as TableOptions;

    return (
            <GenericTable options={clientsTableOptions.options}></GenericTable>
    );
}

export default SmallGenericTable;