import {get} from "../shared/api/endpoints/CrudApi";

export default class TimeSheetType {
    idInFuture!: number;
    name?: string;
    enumName: string;


    constructor(idInFuture: number, enumName: string, name: string) {
        this.idInFuture = idInFuture;
        this.enumName = enumName;
        this.name = name;
    }
}

export async function getAllTimeSheetTypes() {
    let res = await get('erv/timeSheet/type')
        .then((response: any) => {
                if (response.status === 200) {
                    return response;
                } else {
                    return undefined;
                }
            }
        )
    let data;
    if (res !== undefined) {
        data = await res.data;
    }
    return data;
}