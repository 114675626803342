import React, {useEffect, useState} from "react";
import {FormikErrors, useFormik} from "formik";
import {edit} from "../../../shared/api/endpoints/CrudApi";
import {InputText} from "primereact/inputtext";
import ContactPerson, {makeContactPerson, revertContactPerson} from "../../../models/ContactPerson";
import {classNames} from "primereact/utils";
import {getAllClients} from "../../../shared/api/GetFormData";
import Client from "../../../models/Client";
import {Dropdown} from "primereact/dropdown";
import GenericButton, {EButtonIcons, EButtonStyle} from "../../../shared/components/generic/button/GenericButton";
import FormFooter from "../../../shared/components/generic/form-inside-table/FormFooter";
import {Divider} from "primereact/divider";
import {InputTextarea} from "primereact/inputtextarea";
import {checkEMail, checkPhoneNumber} from "../../../shared/utils/ValidationHelpers";

interface ContactPersonTemplateProps {
    entity: ContactPerson;
    handleDataFromChild(e: any, action: string): void;
}

function ContactPersonTemplate(props: ContactPersonTemplateProps) {

    const [contactPerson, setContactPerson] = useState(makeContactPerson(props.entity ?? undefined))
    const [clients, setClients] = useState(Array<Client>);

    useEffect(() => {
        (async () => {
            setClients(await getAllClients())
        })()
    }, []);

    const formik = useFormik({
        initialValues: contactPerson!,
        validate: (values: ContactPerson) => {
            let errors: FormikErrors<ContactPerson> = {};

            if (!values.firstName) {
                errors.firstName = 'Ime je obavezno polje.';
            }

            if (!values.lastName) {
                errors.lastName = 'Prezime je obavezno polje.'
            }

            if (!values.client) {
                errors.client = 'Klijent je obavezno polje.'
            }
            checkEMail(values, errors);
            checkPhoneNumber(values, errors);

            return errors;
        },
        onSubmit:
            async values => {
                values = revertContactPerson(values);

                if (contactPerson.id) {
                    await edit(`erv/admin/contactPersons`, contactPerson.id, values).then(res => res?.status === 200 ? props.handleDataFromChild(makeContactPerson(values), 'edit') : undefined);
                }
            },
    });

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    const setSelected = (e: Client) => {
        formik.setFieldValue('client', e);
        formik.errors.client = '';
    }

    function enableContactPerson() {
        if (contactPerson.id) {
            formik.values.isActive = true;
            edit(`erv/admin/contactPersons`, contactPerson.id, formik.values).then(res => res?.status === 200 ? props.handleDataFromChild(makeContactPerson(formik.values), 'edit') : undefined);
        }
    }

    function deleteContactPerson() {
        if (formik.values.id) {
            formik.values.isActive = false;
            edit(`erv/admin/contactPersons`, contactPerson.id!, formik.values).then(res => res?.status === 200 ? props.handleDataFromChild(makeContactPerson(formik.values), 'edit') : undefined);
        }
    }

    return (
        <div className="expanded-div">
            <form className="max-w-full">
                <div className="p-fluid grid pt-3">
                    <div className="p-field col-2">
                        <span className="p-float-label">
                            <InputText id="firstName" name="firstName" value={formik.values.firstName}
                                    onChange={formik.handleChange}/>
                            <label htmlFor="firstName"
                                className={classNames({'p-error': isFormFieldValid('firstName')})}>Ime</label>
                        </span>
                        {getFormErrorMessage('firstName')}
                    </div>

                    <div className="p-field col">
                        <span className="p-float-label">
                            <InputText id="lastName" name="lastName" value={formik.values.lastName}
                                    onChange={formik.handleChange}/>
                            <label htmlFor="lastName"
                                className={classNames({'p-error': isFormFieldValid('lastName')})}>Prezime</label>
                        </span>
                        {getFormErrorMessage('lastName')}
                    </div>

                    <div className="p-field col-2">
                        <span className="p-float-label">
                            <>
                                <Dropdown id="name" filter dataKey="name" name="name"
                                        value={formik.values?.client} options={clients}
                                        onChange={e => setSelected(e.value)} optionLabel="name"
                                        placeholder="Odaberi klijenta"/>
                                <label htmlFor="name"
                                    className={classNames({'p-error': isFormFieldValid('client')})}>Klijent</label>
                            </>
                        </span>
                        {getFormErrorMessage('client')}
                        </div>
                    <div className="p-field col-3">
                        <span className="p-float-label">
                            <InputText id="email" name="email" value={formik.values.email}
                                    onChange={formik.handleChange}/>
                            <label htmlFor="email"
                                className={classNames({'p-error': isFormFieldValid('email')})}>Email</label>
                        </span>
                        {getFormErrorMessage('email')}
                    </div>
                    <div className="p-field col-3">
                        <span className="p-float-label">
                            <InputText id="phoneNumber" name="phoneNumber" value={formik.values.phoneNumber}
                                    onChange={formik.handleChange}/>
                            <label htmlFor="phoneNumber"
                                className={classNames({'p-error': isFormFieldValid('phoneNumber')})}>Broj mobitela</label>
                        </span>
                        {getFormErrorMessage('phoneNumber')}
                    </div>
                    <Divider/>
                    <Divider/>
                    <div className="p-field col-12">
                        <span className="p-float-label">
                            <InputTextarea id="note" name="note" value={formik.values.note}
                                    onChange={formik.handleChange}/>
                            <label htmlFor="note"
                                className={classNames({'p-error': isFormFieldValid('note')})}>Bilješka</label>
                        </span>
                        {getFormErrorMessage('note')}
                    </div>

                    {!formik.values.isActive ?
                        <>
                            <div className="field col-12 md:col-12">
                                <div className="flex justify-content-center">
                                    Pritiskom na gumb možete aktivirati kontaktnu osobu
                                </div>
                            </div>
                            <div className="field col-4 md:col-4 col-offset-4 md:col-offset-4">
                                <div className="flex justify-content-center">
                                    <GenericButton onClick={enableContactPerson} icon={EButtonIcons.PLUS}
                                                    styled={EButtonStyle.OUTLINED} label={"Aktiviraj"}/>
                                </div>
                            </div>
                        </>
                        :
                        <></>
                    }

                </div>
            <FormFooter onClickUpdate={formik.handleSubmit} onClickDelete={deleteContactPerson}
                        isDisabledOnDelete={!formik.values.isActive}
                        isDisabledOnUpdate={!formik.values.isActive}/>
            </form>
        </div>
    );
}

export default ContactPersonTemplate;