import React, {useEffect, useState} from "react";
import AbsenceRequestStatusType, {getAllAbsenceRequestStatusTypes} from "../../../models/AbsenceRequestStatusType";
import AbsenceType, {getAllAbsenceTypes} from "../../../models/AbsenceType";
import TimeSheetType, {getAllTimeSheetTypes} from "../../../models/TimeSheetType";
import {Divider} from "primereact/divider";
import {get} from "../../../shared/api/endpoints/CrudApi";
import {toast} from "react-toastify";
import GenericButton, {
    EButtonColor,
    EButtonIcons,
    EButtonStyle
} from "../../../shared/components/generic/button/GenericButton";
import SmallGenericTable from "../../../shared/components/generic/smallTable/SmallGenericTable";

const ContactList = () => {
    const [absenceRequestStatusTypes, setAbsenceRequestStatusTypes] = useState<AbsenceRequestStatusType[]>();
    const [absenceTypes, setAbsenceTypes] = useState<AbsenceType[]>();
    const [timeSheetTypes, setTimeSheetTypes] = useState<TimeSheetType[]>();

    useEffect(() => {
        getAllAbsenceTypes().then((res: AbsenceType[]) => {
            setAbsenceTypes(res);
        });
        getAllAbsenceRequestStatusTypes().then((res: AbsenceRequestStatusType[]) => {
            setAbsenceRequestStatusTypes(res);
        });
        getAllTimeSheetTypes().then((res: TimeSheetType[]) => {
            setTimeSheetTypes(res);
        });
    }, [])

    const absenceType = () => {
        if (absenceTypes) {

            return <div>
                {getTable(getAllAbsenceTypes(), "Novi tip odsustva",
                    "ego/absenceType",
                    "ego/absenceType", new AbsenceType(-1, "", ""), true)}
            </div>;
        }
    }
    const absenceRequestStatusType = () => {
        if (absenceRequestStatusTypes) {
            return getTableWithChild(getAllAbsenceRequestStatusTypes(), "Lista tipova stanja zahtjeva",
                <div>Helo</div>);
        }
    }

    const timeSheetType = () => {
        if (timeSheetTypes) {
            return getTableWithChild(getAllTimeSheetTypes(), "Lista tipova radnog vremena",
                <div>Helo</div>);
        }
    }

    function updateEmployees() {
        const response = get("admin/employee/setUp");

        toast.promise(
            response,
            {
                pending: 'Učitavanje...',
                success: 'Zaposlenici su uspješno ažurirani.',
                error: 'Dogodila se pogreška, kontaktirajte administratora.'
            }
        )

    }

    function sendAbsenceReport() {
        const response = get("ego/accountingreport");

        toast.promise(
            response,
            {
                pending: 'Učitavanje...',
                success: 'Izvještaj odsutstava uspješno poslan.',
                error: 'Dogodila se pogreška, kontaktirajte administratora.'
            }
        )
    }

    function getTable(fetchAll: any, modalText: string, path: string, updatePath: string, initialValues: any, isAbsenceType: boolean) {
        let options: string[] = ["Naziv"];
        let fieldTypes = ["string"];
        let fields: string[] = ["name"];
        return <SmallGenericTable fetchAll={fetchAll} modalText={modalText} headers={options}
                                  isAbsenceType={isAbsenceType}
                                  fieldTypes={fieldTypes} isDataMutable={true}
                                  fieldNames={fields} path={path} initialValues={initialValues}
                                  updatePath={updatePath}/>;
    }

    function getTableWithChild(fetchAll: any, modalText: string, children: any) {
        let options: string[] = ["Name"];
        let fieldTypes = ["string"];
        const fields = ["name"];
        return <SmallGenericTable fetchAll={fetchAll} modalText={modalText} headers={options}
                                  fieldTypes={fieldTypes} isDataMutable={false}
                                  fieldNames={fields} child={children}/>;
    }

    return (
        <>
            <div className="flex flex-column justify-content-center" style={{border: "transparent"}}>

                <div className="flex flex-column justify-content-center">
                    <Divider/>
                    <div className="flex flex-row justify-content-start pl-8">
                        <div className="pt-3">
                            <GenericButton label="Ažuriraj zaposlenike" icon={EButtonIcons.EMPLOYEES}
                                           styled={EButtonStyle.OUTLINED} color={EButtonColor.MILK_BLUE}
                                           onClick={() => updateEmployees()}/>
                            <GenericButton label="Pošalji izvještaj odsutstva" icon={EButtonIcons.EMPLOYEES}
                                           styled={EButtonStyle.OUTLINED} color={EButtonColor.MILK_BLUE}
                                           onClick={() => sendAbsenceReport()}/>
                        </div>
                    </div>
                    <Divider className="h-2rem w-full"/>
                    <div className="flex flex-column justify-content-center w-full">
                        <div className="w-full pl-8 pr-8">
                            {absenceType()}
                        </div>
                        <div className="h-4rem w-full"></div>
                        <div className="flex flex-row justify-content-center w-full">
                            <div className="w-6 pl-8 pr-8">
                                {absenceRequestStatusType()}
                            </div>
                            <div className="w-6 pl-8 pr-8">
                                {timeSheetType()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactList;