import ContactPerson, {makeContactPerson} from "./ContactPerson";

export default class Client {
    id?: number;
    name?: string;
    identifier?: string;
    country?: string;
    city?: string;
    streetName?: string;
    zip?: string;
    type?: number;
    streetNumber?: string;
    isActive?: boolean;
    hasActiveProject?: boolean;
    contactPersons?: ContactPerson[];
    address?: string;
    countryCode?: string;
    fullVat?: string;

    constructor(id?: number, name?: string, identifier?: string, country?: string, city?: string, streetName?: string, zip?: string, type?: number, streetNumber?: string, isActive?: boolean, hasActiveProject?: boolean, contactPersons?: ContactPerson[], address?: string, countryCode?: string, fullVat?: string) {
        this.id = id ?? undefined;
        this.name = name ?? undefined;
        this.identifier = identifier ?? undefined;
        this.country = country ?? undefined;
        this.city = city ?? undefined;
        this.streetName = streetName ?? undefined;
        this.zip = zip ?? undefined;
        this.type = type ?? undefined;
        this.streetNumber = streetNumber ?? undefined;
        this.isActive = isActive ?? true;//otherwise ClientForm for CREATE is disabled
        this.hasActiveProject = hasActiveProject ?? true;
        this.contactPersons = contactPersons ?? undefined;
        this.address = address ?? undefined;
        this.countryCode = countryCode ?? undefined;
        this.fullVat = fullVat ?? undefined;
    }
}

export function makeClient(client: Client | undefined) {
    if (client === undefined) {
        return new Client();
    }

    if (client.contactPersons) {
        client.contactPersons = client.contactPersons.map((contactPerson: ContactPerson) => {
            return makeContactPerson(contactPerson);
        })
    }
    if (client.countryCode === undefined) {
        client.countryCode = '';
    }
    if (client.name === undefined) {
        client.name = '';
    }
    if (client.identifier === undefined) {
        client.identifier = '';
    }

    saveAddress(client);

    client.fullVat = (client.countryCode + client.identifier);
    return client;
}

function saveAddress(client: Client) {
    const addressParts: string[] = [
        client.country ?? '',
        client.zip || client.city ? (`${client.zip ?? ''} ${client.city ?? ''}` ?? '') : '',
        client.streetName || client.streetNumber ? (`${client.streetName ?? ''} ${client.streetNumber ?? ''}` ?? '') : ''
    ];
    client.address = addressParts.filter(part => part != null && part.trim() !== '').join(', ') || "Ne postoje informacije o adresi.";
}

export function revertClient(client: Client) {
    // FIXME: Type currently fixed, solve when we implement types
    client.type = 1;
    return client;
}

export function convertFromSudregApiToClient(data: any) {
    let client: Client = new Client;

    if (data) {
        client.identifier = data.potpuni_oib ?? '';
        client.name = data.skracene_tvrtke[0]?.ime ?? '';
        client.country = data.sjedista[0]?.drzava?.naziv ?? '';
        client.city = data.sjedista[0]?.naziv_naselja ?? '';
        client.streetName = data.sjedista[0]?.ulica ?? '';
        client.streetNumber = data.sjedista[0]?.kucni_broj ?? '' + data.sjedista[0]?.kucni_podbroj ?? '';
        client.countryCode = "HR";

        return makeClient(client);
    } else return undefined;
}

export function convertFromEUViesApiToClient(data: any) {
    let client: Client = new Client;

    if (data) {
        client.identifier = data.VatNumber ?? '';
        client.name = data.BusinessName ?? '';
        client.country = data.BusinessCountry ?? '';
        client.city = data.BusinessCity ?? '';
        client.streetName = data.BusinessStreet ?? '';
        client.streetNumber = data.BusinessStreetNumber ?? '';
        client.countryCode = data.CountryCode ?? '';
        client.zip = data.BusinessPostalCode ?? '';

        return makeClient(client);
    } else return undefined;
}