import axios from 'axios';
import { toast } from 'react-toastify';

const getClientVIESCode = async (countryCode: any, vatNumber: string, apiKey: string) => {

    const headers = {
        headers:
        {
            "Apikey": apiKey ?? ''
        }
    }

    const body = {
        "vatCode": `${countryCode}${vatNumber}` ?? ''
    }

    const response = axios.post('https://api.cloudmersive.com/validate/vat/lookup', body, headers);

    let data: any = (await response).data;


    if (await data.IsValid === true) {
      toast.success('Uspješno!')
    } else {
        toast.error('Klijent nije pronađen')
    }

    if (await data.IsValid) {
        return data;
    } else return undefined
}



export default getClientVIESCode;