import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {update} from "../../../api/endpoints/CrudApi";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import GenericButton, {EButtonColor, EButtonIcons, EButtonStyle} from "../button/GenericButton";
import LdapAttribute from "../../../../models/LdapAttribute";
import {getAllValuesForAttribute} from "../../../api/endpoints/CustomApi";
import AbsenceRequestType from "../../../../models/AbsenceType";
import {MultiSelect} from "primereact/multiselect";

interface ISmallTemplateProps {
    id: string,
    initialValues: any,
    path: string,
    hide: () => void,
    handleDataFromChild: any,
    isAbsenceType?: boolean,
}

/**
 * Only for object with name and enumName as objects
 * @param props
 * @constructor
 */
const SmallTemplate = (props: ISmallTemplateProps) => {
    const [path, setPath] = useState<string>(props.path + "/" + props.id);
    const [absenceTypeRolePossibilities, setAbsenceTypeRolePossibilities] = useState<LdapAttribute>();
    const [absenceTypeRoleSelection, setAbsenceTypeRoleSelection] = useState<any[]>();

    useEffect(() => {
        if (props.isAbsenceType) {
            (async () => {
                let values = await getAllValuesForAttribute('description');

                setAbsenceTypeRolePossibilities(values);
                if (props.initialValues.roles !== undefined && values !== undefined) {
                    const initialSelection = values.attributeValuesEnum?.filter(attribute =>
                        props.initialValues.roles.split(', ').includes(attribute.enumName)
                    );
                    setAbsenceTypeRoleSelection(initialSelection);
                }
            })();
        }
    }, []);

    const formik = useFormik({
        initialValues: props.isAbsenceType ? props.initialValues as AbsenceRequestType : props.initialValues,

        onSubmit:
            async values => {
                await update(path, values).then(res => res?.status === 200 ? props.handleDataFromChild(values, 'edit') : undefined);
            },
    });

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    return <>
        <form>
            <div>
                <div style={{border: "transparent"}}>
                    <div className="p-fluid grid" style={{paddingTop: '20px'}}>
                        <div className="field col-6  md:col-6">
                            <span className="p-float-label">
                                <InputText id="name" name="name" value={formik.values.name}
                                           onChange={formik.handleChange}/>
                                <label htmlFor="name"
                                       className={classNames({'p-error': isFormFieldValid('name')})}>Naziv</label>
                            </span>
                            {getFormErrorMessage('name')}
                        </div>
                        {
                            props.isAbsenceType !== undefined && props.isAbsenceType ?
                                <>
                                    <div className="field col-6">
                                        <span className="p-float-label">
                                                <MultiSelect id="absenceTypeRoleSelection" value={absenceTypeRoleSelection}
                                                             options={absenceTypeRolePossibilities?.attributeValuesEnum}
                                                             onChange={(e) => {
                                                                 setAbsenceTypeRoleSelection(e.value);
                                                                 if(e.value !== undefined) {
                                                                     let i: any[] = e.value;
                                                                     let roles = i.map(attribute => attribute.enumName).join(", ");
                                                                     formik.setFieldValue("roles", roles);
                                                                 }
                                                             }}
                                                             dataKey="name" optionLabel="name"/>
                                            <label htmlFor="absenceTypeRoleSelection">Tipovi zaposlenika sa pravom izabira</label>
                                        </span>
                                    </div>
                                </>
                                :
                                <></>
                        }
                    </div>
                </div>
                <div className="flex flex-row-reverse">
                    <GenericButton type="submit" label="Ažuriraj" icon={EButtonIcons.CHECK} styled={EButtonStyle.OUTLINED}
                                   onClick={formik.handleSubmit}
                                   color={EButtonColor.DARK_BLUE}></GenericButton>
                </div>
            </div>
        </form>
    </>
}
export default SmallTemplate;