import React from "react";
import {TabPanel, TabView} from "primereact/tabview";
import AbsenceRequestList from "../../../../components/admin/absence-request/AbsenceRequestList";
import TimeSheetList from "../../../../components/admin/time-sheet/TimeSheetList";
import MyAbsenceRequestList from "../../../../components/user/my-absence-request/MyAbsenceRequestList";
import MyTimeSheetList from "../../../../components/user/my-time-sheet/MyTimeSheetList";
import HolidayList from "../../../../components/holidays/HolidayList";
import ConstantList from "../../../../components/admin/settings/ConstantList";
import EmployeeList from "../../../../components/admin/employees/EmployeeList";
import JournalsList from "../../../../components/admin/journals/JournalsList";
import ClientList from "../../../../components/admin/clients/ClientList";
import ContactPersonList from "../../../../components/admin/contact-persons/ContactPersonList";
import ProjectList from "../../../../components/admin/projects/ProjectList";
import SummarizationList from "../../../../components/admin/absence-request/summarization/SummarizationList";
import SummarizationTimeSheetTable
    from "../../../../components/admin/time-sheet/summarizationtimesheet/SummarizationTimeSheetTable";

interface GenericTabMenuProps {
    items: TabMenuItem[];
    activeTab: number;
}
export interface TabMenuItem {
    id: string;
    label: string;
    component: string;
}
export interface TabMenuTab {
    activeTab: number;
}

const renderTab = (component: string) => {
    switch(component) {
        case "AbsenceRequestList":
            return <AbsenceRequestList filter="all"/>
        case "EmployeeAbsenceRequestList":
            return <AbsenceRequestList filter="employee"/>
        case "ExternalAbsenceRequestList":
            return <AbsenceRequestList filter="external"/>
        case "StudentAbsenceRequestList":
            return <AbsenceRequestList filter="student"/>
        case "TimeSheetList":
            return <TimeSheetList/>
        case "MyAbsenceRequestList":
            return <MyAbsenceRequestList/>
        case "MyTimeSheetList":
            return <MyTimeSheetList/>
        case "HolidayList":
            return <HolidayList/>
        case "ConstantList":
            return <ConstantList/>
        case "EmployeeList":
            return <EmployeeList filter={undefined}/>
        case "EmployeeList-employee":
            return <EmployeeList filter="employee"/>
        case "EmployeeList-student":
            return <EmployeeList filter="student"/>
        case "EmployeeList-external":
            return <EmployeeList filter="external"/>
        case "JournalsList":
            return <JournalsList/>
        case "ClientList":
            return <ClientList/>    
        case "ContactPersonList":
            return <ContactPersonList/>
        case "ProjectList":
            return <ProjectList/>
        case "SummarizationList":
            return <SummarizationList/>
        case "SummarizationTimeSheetTable":
            return <SummarizationTimeSheetTable/>
    }
}


const GenericTabMenu = (props: GenericTabMenuProps) => {
    return (
        <>
            <div style={{width: "100%", height: "100%"}}>
                <div className="card">
                    <TabView renderActiveOnly={true} activeIndex={props.activeTab} >
                        {props.items.map((tabItem) => {
                                return <TabPanel header={tabItem.label}>
                                    {renderTab(tabItem.component)}
                                </TabPanel>
                            })}
                    </TabView>
                </div>
            </div>
        </>
    )
}

export default GenericTabMenu;