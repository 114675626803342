import React, {useState} from "react";
import TimeSheet from "../../../models/TimeSheet";
import {getMyTimeSheets} from "../../../shared/api/GetFormData";
import GenericDialog from "../../../shared/components/generic/dialog/GenericDialog";
import GenericTable, {TableOptions} from "../../../shared/components/generic/table/GenericTable";
import MyTimeSheetForm from "./MyTimeSheetForm";
import MyTimeSheetTemplate from "./MyTimeSheetTemplate";
import ExportToExcelForUser from "../../excel/ExportToExcelForUser";

const MyTimeSheetList = () => {

    const [visibilityNewRequest, setVisibilityNewRequest] = useState(false);
    const [visibilityExportExcel, setVisibilityExportExcel] = useState(false);
    const [initialValues, setInitialValues] = useState<any | undefined>(undefined);
    const [dataFromChildCreate, setDataFromChildCreate] = useState<any | undefined>(undefined);
    const [visibilityColumnSelectionModal, setVisibilityColumnSelectionModal] = useState<boolean>(false);
    const [initialValuesColumnSelection, setInitialValuesColumnSelection] = useState<any | undefined>(undefined);

    const onHideColumnSelectionModal = () => {
        if (visibilityColumnSelectionModal) {
            setVisibilityColumnSelectionModal(false);
        }
    };

    const onHideNewRequest = () => {
        if (visibilityNewRequest) {
            setVisibilityNewRequest(false);
        }
    };

    const onHideExportExcel = () => {
        if (visibilityExportExcel) {
            setVisibilityExportExcel(false);
        }
    };

    const clientsTableOptions = {
        options: {
            title: "Pregled mojih evidencija radnog vremena",
            toFetch: [getMyTimeSheets()],
            columns: {
                headers: ["Ime projekta", "Tip", "Datum", "Broj sati"],
                fieldNames: ["project.name", "type.name", "date", "numberOfHoursoShowOnForm"],
                fieldTypes: ["string", "string", "date", "numeric"],
                searchByFieldNames: ["project.name", "date", "numberOfHoursoShowOnForm"],
            },
            hasHeader: false,
            isDataMutable: true,
            expansionTemplate: (params: { e: TimeSheet, handleDataFromChild: () => void }) => <MyTimeSheetTemplate
                entity={params.e} handleDataFromChild={params.handleDataFromChild}/>,
            modalOptions: {
                modals: [
                    <GenericDialog key="modal-new" headerText="Nova evidencija radnog vremena" onHide={onHideNewRequest}
                                   visible={visibilityNewRequest}
                                   hasFooter={false}
                                   children={<MyTimeSheetForm hide={onHideNewRequest} initialValues={initialValues}
                                                              addNewDataToTable={setDataFromChildCreate}
                                   />}/>,
                ],
                hideVariables: [
                    visibilityNewRequest,
                ],
                hideSetters: [
                    setVisibilityNewRequest,
                ],
                hideFunctions: [
                    onHideNewRequest,
                ],
                initialValues: {
                    initialValues: initialValues,
                    setInitialValues: setInitialValues
                },
                newValue: dataFromChildCreate
            },
            columnSelection: {
                headers: ["Ime projekta", "Tip", "Datum", "Broj sati"],
                fieldNames: ["project.name", "type.name", "date", "numberOfHours"],
                hideVariables: visibilityColumnSelectionModal,
                hideSetters: setVisibilityColumnSelectionModal,
                hideFunctions: onHideColumnSelectionModal,
                initialValues: {
                    initialValues: initialValuesColumnSelection,
                    setInitialValues: setInitialValuesColumnSelection
                }
            },
            pagination: {
                hasPageable: true,
                onChange: getMyTimeSheets
            },
            excelExportModalOption: {
                modals: [
                    <GenericDialog key="modal-new-export" headerText="Export to excel" onHide={onHideExportExcel}
                                   visible={visibilityExportExcel}
                                   hasFooter={false}
                                   children={<ExportToExcelForUser hide={onHideExportExcel}/>}/>
                ],
                hideVariables: [
                    visibilityExportExcel,
                ],
                hideSetters: [
                    setVisibilityExportExcel,
                ],
                hideFunctions: [
                    onHideExportExcel,
                ]
            },
            timeSheet: {
                isUkupno: true,
            },
        }
    } as TableOptions;

    return (
        <div>
            <GenericTable options={clientsTableOptions.options}></GenericTable>
        </div>
    );
}

export default MyTimeSheetList;