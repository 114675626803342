import React, {useState} from "react";
import GenericExport, {GenericExcelProp} from "./generic/GenericExport";
import {Card} from "primereact/card";
import EricssonExportForUser from "./ericsson/EricssonExportForUser";
import {EButtonColor} from "../../shared/components/generic/button/GenericButton";

interface ITimeSheetFormProps {
    hide: () => void
}

function ExportToExcelForUser(props: ITimeSheetFormProps) {
    const [isGeneric, setIsGeneric] = useState<boolean>(false);
    const [isEricsson, setIsEricsson] = useState<boolean>(false);

    const genericExcelConfiguration: GenericExcelProp[] = [
        {key: "datum", value: "Datum"},
        {key: "imeProjekta", value: "Ime projekta"},
        {key: "zaposlenik", value: "Ime i prezime zaposlenika"},
        {key: "brojSati", value: "Broj sati"},
        {key: "opis", value: "Opis"}
    ];

    return (
        <>
            {!isGeneric && !isEricsson ?
                <div className="flex flex-row justify-content-around">


                    <>
                        <Card onClick={() => setIsEricsson(true)}
                              style={{background: EButtonColor.DARK_BLUE, color: EButtonColor.LUMINUM_BLUE}}
                        >
                            <div>
                                <div
                                    className="flex justify-content-center  align-content-center align-items-center w-15rem h-15rem">
                                    Ericsson template
                                </div>
                            </div>
                        </Card>
                        <Card onClick={() => setIsGeneric(true)}>
                            <div
                                className="flex justify-content-center  align-content-center align-items-center w-15rem h-15rem">
                                Generički template
                            </div>
                        </Card>
                    </>
                </div>
                :
                (isGeneric ?
                    <GenericExport hide={props.hide} goBack={() => setIsGeneric(false)}
                                   url="user/excel/generic/download"
                                   values={genericExcelConfiguration}></GenericExport>
                    :
                    <EricssonExportForUser goBack={() => setIsEricsson(false)}
                                           hide={props.hide}></EricssonExportForUser>)
            }
        </>
    );
}

export default ExportToExcelForUser;