import {FormikErrors, useFormik} from "formik";
import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";
import {InputNumber} from "primereact/inputnumber";
import {InputTextarea} from "primereact/inputtextarea";
import {classNames} from "primereact/utils";
import {useEffect, useState} from "react";
import Holiday from "../../../models/Holiday";
import Project from "../../../models/Project";
import TimeSheet, {makeTimeSheet, revertTimeSheet} from "../../../models/TimeSheet";
import TimeSheetType, {getAllTimeSheetTypes} from "../../../models/TimeSheetType";
import {getAllHolidays, getAllProjectsForUser} from "../../../shared/api/GetFormData";
import {create} from "../../../shared/api/endpoints/CrudApi";
import GenericButton, {EButtonIcons, EButtonStyle} from "../../../shared/components/generic/button/GenericButton";
import DialogFooter from "../../../shared/components/generic/dialog/DialogFooter";
import {convertFromDate} from "../../../shared/utils/DateUtils";
import {dateTemplate} from "../../admin/absence-request/AbsenceRequestAssistance";

interface ITimeSheetFormProps {
    hide: () => void,
    initialValues: TimeSheet
    addNewDataToTable: React.Dispatch<any>
}

function MyTimeSheetForm(props: ITimeSheetFormProps) {
    const [timeSheet, setTimeSheet] = useState<TimeSheet>(createTimeSheet(props.initialValues ?? undefined))
    const [project, setProject] = useState<Project>();
    const [projects, setProjects] = useState<Project[]>();

    const [type, setType] = useState<TimeSheetType>();
    const [types, setTypes] = useState<TimeSheetType[]>();

    const [holidays, setHolidays] = useState<Holiday[]>();

    const [dates, setDates] = useState<Date[]>([new Date()]);
    const [wantMultiple, setWantMultiple] = useState<boolean>(false);

    function createTimeSheet(timeSheet: TimeSheet | undefined) {
        let ts = makeTimeSheet(timeSheet);
        if(ts.numberOfHours === undefined || ts.numberOfHours === 0) {
            ts.numberOfHours = 8;
        }
        return ts;
    }

    const formik = useFormik({
        initialValues: timeSheet!,
        validate: (values: TimeSheet) => {
            let errors: FormikErrors<TimeSheet> = {};
            if (!values.dateToShowOnForm) {
                errors.dateToShowOnForm = "Datum je obavezno polje."
            }
            if (values.endDate) {
                if (!values.date) {
                    errors.endDate = "Nužno je izabrati oba datuma."
                }
            }
            if (!values.project) {
                errors.project = "Projekt je obavezno polje."
            }
            if (!values.type) {
                errors.type = "Tip je obavezno polje."
            }
            if (!values.numberOfHours) {
                errors.numberOfHours = "Broj sati je obavezno polje."
            }

            return errors;
        },

        onSubmit:
            async values => {

                values = revertTimeSheet(values);

                values.id = undefined;
                await create(`erv/user/timeSheet`, values).then(res => {
                    if(res.status === 201){
                        let newTS = res.data.timeSheets.map((data:any)=> {return makeTimeSheet(data)});
                        props.addNewDataToTable(newTS);
                    }
                });

                formik.resetForm();

                props.hide();
            },
    });


    useEffect(() => {
        getAllProjectsForUser().then((res: Project[]) => {
            setProjects(res);
        });
        getAllTimeSheetTypes().then((res: TimeSheetType[]) => {
            setTypesAndType(res);
        });
        getAllHolidays().then((res: Holiday[]) => {
            setHolidays(res);
        });

    }, [])

    useEffect(() => {
        if(projects && projects.length === 1){
            formik.setFieldValue("project", projects[0]);
        }
    }, [projects]);

    const setTypesAndType = (timeSheetTypes: TimeSheetType[]) => {
        if(timeSheetTypes){
            setTypes(timeSheetTypes);
            formik.setFieldValue("type", timeSheetTypes.at(0));
        }
    }

    useEffect(() => {
        if (formik.values.project && !formik.values.project.canAddMultipleTimeSheets) {
            formik.setFieldValue("date", new Date())
        }
    }, [formik.values.project])

    function setDatesFromCalendar(dates: Date[]) {
        formik.setFieldValue("date", convertFromDate(dates[0]));
        formik.setFieldValue("dateToShowOnForm", dates[0]);
        formik.setFieldValue("endDate", convertFromDate(dates[1]));
        formik.setFieldValue("endDateToShowOnForm", dates[1]);
    }

    useEffect(() => {
        if (dates) {
            setDatesFromCalendar(dates);
        }
    }, [dates])

    const setDate = (date: Date) => {
        formik.setFieldValue("date", convertFromDate(date));
        formik.setFieldValue("dateToShowOnForm", date);
    }


    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    const handleValueChange = (e: any) => {
        const value = e.target.value;
        if (value % 0.5 !== 0) {
            e.target.value = Math.round(e.target.value / 0.5) * 0.5;
            formik.setFieldValue('numberOfHours', e.target.value);
        }
        else {
            formik.setFieldValue('numberOfHours', e.target.value);
        }
    };

    const calendarForMultiple = (
        <>
            {wantMultiple ?
                <>
                    <div className="field col-4 md:col-4">
                        <span className="p-float-label">
                            <Calendar showButtonBar name="calendar" value={dates}
                                      onChange={(e) => setDates(e.value as Date[])}
                                      dateFormat="dd.mm.yy." selectionMode="range" id="calendar"
                                      dateTemplate={(e) => dateTemplate(e, holidays)}
                                      disabledDays={[0, 6]}/>
                            <label htmlFor="calendar" className={classNames({'p-error': isFormFieldValid('calendar')})}>
                                Vremenski interval</label>
                        </span>
                        {getFormErrorMessage('date')}
                    </div>
                    <div className="field col-2 md:col-2">
                        <GenericButton icon={EButtonIcons.UNDO} styled={EButtonStyle.TRANSPOSE}
                                       onClick={() => setWantMultiple(false)} isRounded={true}/>
                    </div>
                </>
                :
                <>
                    <div className="field col-4 md:col-4">
                        <span className="p-float-label">
                            <Calendar showButtonBar name="calendar" value={formik.values.dateToShowOnForm}
                                      onChange={(e) => setDate(e.value as Date)}
                                      dateFormat="dd.mm.yy." id="calendar1"
                                      dateTemplate={(e) => dateTemplate(e, holidays)}/>
                            <label className={classNames({'p-error': isFormFieldValid('calendar')})}
                                   htmlFor="calendar1">Datum</label>
                        </span>
                        {getFormErrorMessage('date')}
                    </div>
                    <div className="field col-2 md:col-2">
                        <GenericButton icon={EButtonIcons.CALENDAR_PLUS} styled={EButtonStyle.TRANSPOSE}
                                       onClick={() => setWantMultiple(true)} isRounded={true}/>
                    </div>
                </>
            }
        </>
    )

    return (

        <form>
            <div>
                <div style={{ border: "transparent" }}>
                    <div className="p-fluid grid" style={{ paddingTop: '20px' }}>
                        <div className="field col-12 md:col-12">
                            <span className="p-float-label">
                            <Dropdown id="project" optionLabel="name" name="name"
                                        dataKey="name" filter
                                        value={formik.values.project}
                                        options={projects}
                                        onChange={(e) => {
                                            setProject(e.value);
                                            formik.setFieldValue('project', e.value);
                                        }}/>
                                <label htmlFor="project" className={classNames({ 'p-error': isFormFieldValid('project') })}>Projekt</label>
                            </span>
                            {getFormErrorMessage('project')}
                        </div>

                        {formik.values.project !== undefined &&
                                                formik.values.project.canAddMultipleTimeSheets ?
                            calendarForMultiple
                            :
                            <>
                                <div className="field col-4 md:col-4">
                                            <span className="p-float-label">
                                                <Calendar showButtonBar name="dateToShowOnForm" value={formik.values.dateToShowOnForm}
                                                          onChange={formik.handleChange}
                                                          dateFormat="dd.mm.yy." id="calendarFrom"/>
                                                <label htmlFor="calendarFrom" className={classNames({ 'p-error': isFormFieldValid('dateToShowOnForm') })}>Datum</label>
                                            </span>
                                    {getFormErrorMessage('dateToShowOnForm')}
                                </div>
                                <div className="field col-2 md:col-2"/>
                            </>
                        }

                        <div className="field col-4 md:col-4">
                            <span className="p-float-label">
                            <Dropdown id="type" optionLabel="name" name="name"
                                        value={formik.values.type}
                                        options={types}
                                        onChange={(e) => {
                                            setType(e.value);
                                            formik.setFieldValue('type', e.value);
                                        }}/>
                                   
                                <label htmlFor="type">Tip</label>
                            </span>
                            {getFormErrorMessage('type')}
                        </div>
                        <div className="field col-2 md:col-2">
                            <span className="p-float-label">
                                <InputNumber id="numberOfHours"
                                    value={formik.values.numberOfHours}
                                    onValueChange={handleValueChange}
                                    maxFractionDigits={1}
                                    step={0.5}
                                    min={0} max={24}
                                />
                                <label htmlFor="numberOfHours" className={classNames({ 'p-error': isFormFieldValid('numberOfHours') })}>Broj sati</label>
                            </span>
                            {getFormErrorMessage('numberOfHours')}

                        </div>
                        <div className="field col-12 md:col-12">
                            <span className="p-float-label">
                                <InputTextarea id="activity" value={formik.values.activity}
                                    onChange={formik.handleChange} />
                                <label htmlFor="activity">Opis aktivnosti</label>
                            </span>
                        </div>
                    </div>
                </div>

                <DialogFooter hide={props.hide} onClick={formik.handleSubmit} />
            </div>
        </form>
    );
}

export default MyTimeSheetForm;