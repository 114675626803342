import React from "react";
import GenericButton, {EButtonColor, EButtonIcons, EButtonStyle} from "../button/GenericButton";

interface IFormFooterProps {
    onClickClose: () => void,
    onClickCancel: () => void,
    onClickDeny: () => void,
    onClickApprove: () => void,
    isDisabledOnClose?: boolean,
    isDisabledOnCancel?: boolean,
    isDisabledOnDeny?: boolean,
    isDisabledOnApprove?: boolean,
}

const FormFooter = (props: IFormFooterProps) => {
    return (
        <div className="flex justify-content-between flex-row ">
            <div>
                {/*<GenericButton label={"Zatvori"} type="submit"
                                icon={EButtonIcons.MINUS}
                                onClick={props.onClickClose}
                                disabled={props.isDisabledOnClose ?? false}
                                color={EButtonColor.MILK_BLUE}
                                styled={EButtonStyle.TRANSPOSE}/>
                                TODO add button so that closes expand on table*/}
            </div>
            <div className="flex justify-content-between flex-row ">
                <GenericButton label={"Otkaži"} type="submit"
                               icon={EButtonIcons.IKS}
                               onClick={props.onClickCancel}
                               disabled={props.isDisabledOnCancel ?? false}
                               color={EButtonColor.DARK_BLUE}
                               styled={EButtonStyle.TRANSPOSE}/>

                <GenericButton label={"Odbij"} type="submit"
                               icon={EButtonIcons.IKS}
                               onClick={props.onClickDeny}
                               disabled={props.isDisabledOnDeny ?? false}
                               color={EButtonColor.DARK_BLUE}
                               styled={EButtonStyle.TRANSPOSE}/>

                <GenericButton label={"Prihvati"} type="submit"
                               icon={EButtonIcons.CHECK}
                               onClick={props.onClickApprove}
                               disabled={props.isDisabledOnApprove ?? false}
                               color={EButtonColor.LUMINUM_BLUE} styled={EButtonStyle.TRANSPOSE}/>
            </div>
        </div>
    );
}
export default FormFooter;