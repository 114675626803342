import React from "react";
import GenericPanel, {DataViewItemProp} from "./GenericPanel";
import {EButtonIcons} from "../../shared/components/generic/button/GenericButton";

const UserOverview = () => {

    const products = [
        {
            id: 1,
            name: "Moj profil",
            url: `/user/my_info`,
            icon: EButtonIcons.ROBOTS_HEAD
        },
        {
            id: 2,
            name: "Odsustva",
            url: '/user/myabsencerequests',
            icon: EButtonIcons.SEND
        },
        {
            id: 2,
            name: "Evidencija",
            url: '/user/mytimesheet',
            icon: EButtonIcons.CLOCK
        },

    ] as DataViewItemProp[];


    return (
        <>
            <GenericPanel data={products}></GenericPanel>
        </>
    )
}

export default UserOverview;