import VATData from '../../../res/VATData.json';

const validateClientVIESFormat = (countryCode: string, vatNumber: string) => {
    const errors: any = {};

    const data: any = VATData;

    const countryData = data[countryCode];
    if (!countryData) {
        return errors;
    }

    // validate the VAT number against the regex defined in the JSON
    const regex = new RegExp(countryData.regex);
    if (vatNumber !== '' && !regex.test(vatNumber)) {
        errors.viesError = countryData.errorsHR;
    }

    return errors;
};

const getAllVIESCountryCodes = () => {
    return Object.keys(VATData).map(key => { return key }).sort();
}

export { getAllVIESCountryCodes, validateClientVIESFormat };
