import Employee from "./Employee";

export default class LdapAttribute {
    name?: string;
    enumName?: string;
    attributeValues?: Array<string>
    attributeValuesToShow?: Array<string | undefined>
    attributeValuesEnum?: CLdapAttributes[]

    constructor(name: string, enumName: string, attributeValues: Array<string>, attributeValuesToShow: Array<string>, attributeValuesEnum: CLdapAttributes[]) {
        this.name = name;
        this.enumName = enumName;
        this.attributeValues = attributeValues;
        this.attributeValuesToShow = attributeValuesToShow;
        this.attributeValuesEnum = attributeValuesEnum;
    }
}

export class CLdapAttributes {
    name?: string;
    enumName?: string

    constructor(name: string, enumName: string) {
        this.name = name;
        this.enumName = enumName;
    }
}

export const CLdapAttributesDepartment: CLdapAttributes[] = [
    { name: "Razvojni inženjer", enumName: "software_engineer" },
    { name: "DevOps inženjer", enumName: "devops_engineer" },
    { name: "Voditelj", enumName: "manager" },
    { name: "Direktor", enumName: "director" }
];

export const CLdapAttributesEmployeeDescription: CLdapAttributes[] = [
    { name: "Zaposlenik", enumName: "employee" },
    { name: "Student", enumName: "student" },
    { name: "Vanjski", enumName: "external" },
    { name: "Kandidat", enumName: "candidate" },
];

export function makeLdapAttribute(ldapAttribute: LdapAttribute) {

    if (ldapAttribute.name === 'description') {
        ldapAttribute.attributeValuesToShow = CLdapAttributesEmployeeDescription.map(attr => { return attr.name });
        ldapAttribute.attributeValuesEnum = CLdapAttributesEmployeeDescription;
        const filteredValues = ldapAttribute.attributeValues?.filter(str => {
            return !CLdapAttributesEmployeeDescription.some(obj => obj.enumName === str);
        });
        filteredValues?.forEach(str => ldapAttribute.attributeValuesToShow?.push(str))
        filteredValues?.forEach(str => ldapAttribute.attributeValuesEnum?.push({ name: str, enumName: str }))
    }
    else if (ldapAttribute.name === 'departmentNumber') {
        ldapAttribute.attributeValuesToShow = CLdapAttributesDepartment.map(attr => { return attr.name });
        ldapAttribute.attributeValuesEnum = CLdapAttributesDepartment;
        const filteredValues = ldapAttribute.attributeValues?.filter(str => {
            return !CLdapAttributesDepartment.some(obj => obj.enumName === str);
        });
        filteredValues?.forEach(str => ldapAttribute.attributeValuesToShow?.push(str));
        filteredValues?.forEach(str => ldapAttribute.attributeValuesEnum?.push({ name: str, enumName: str }))
    }
    return ldapAttribute;
}

export function makeEmployeeLdapAttributes(employee: Employee) {
    employee.descriptionAttribute = CLdapAttributesEmployeeDescription.find(existing => {
        return existing.enumName?.toLowerCase() === employee.employeeLdap?.description?.toLowerCase()
    });

    employee.departmentNumberAttribute = CLdapAttributesDepartment.find(existing => {
        return existing.enumName?.toLowerCase() === employee.employeeLdap?.departmentNumber?.toLowerCase()
    });
    // employee.descriptionAttribute = {
    //     name: CLdapAttributesDepartment.find(attr => attr.enumName === employee.employeeLdap?.departmentNumber) ? CLdapAttributesDepartment.find(attr => attr.enumName === employee.employeeLdap?.departmentNumber)?.name : (employee?.employeeLdap?.departmentNumber !== '' ? employee?.employeeLdap?.departmentNumber?.charAt(0)?.toUpperCase()?.toString()! + employee.employeeLdap?.departmentNumber?.slice(1).toString() : 'Razvojni inženjer'),
    //     enumName: employee.employeeLdap?.departmentNumber !== null ? employee.employeeLdap?.departmentNumber : employee.departmentNumberAttribute?.name !== undefined ? employee.departmentNumberAttribute?.name?.toLowerCase() : 'developer'
    // }
    // employee.departmentNumberAttribute = {
    //     name: CLdapAttributesEmployeeDescription.find(attr => attr.enumName === employee.employeeLdap?.description) ? CLdapAttributesEmployeeDescription.find(attr => attr.enumName === employee.employeeLdap?.description)?.name : (employee?.employeeLdap?.description !== '' ? employee?.employeeLdap?.description?.charAt(0)?.toUpperCase()?.toString()! + employee.employeeLdap?.description?.slice(1).toString() : 'Zaposlenik'),
    //     enumName: employee.employeeLdap?.description !== null ? employee.employeeLdap?.description : employee.descriptionAttribute?.name !== undefined ? employee.descriptionAttribute?.name?.toLowerCase() : 'developer'
    // }

    return employee;
}