import React, {useEffect, useState} from "react";
import {FormikErrors, useFormik} from "formik";
import {edit} from "../../../shared/api/endpoints/CrudApi";
import {InputText} from "primereact/inputtext";
import AbsenceRequest, {makeAbsenceRequest, revertAbsenceRequest} from "../../../models/AbsenceRequest";
import {classNames} from "primereact/utils";
import {AbsenceType_SickLeave, AbsenceType_Vacation} from "../../../models/AbsenceType";
import AbsenceRequestStatusType, {
    AbsenceRequestStatusType_APPROVED,
    AbsenceRequestStatusType_CANCEL,
    AbsenceRequestStatusType_DENIED,
    getAllAbsenceRequestStatusTypes
} from "../../../models/AbsenceRequestStatusType";
import {dateTemplate} from "./AbsenceRequestAssistance";
import {getAllEmployees, getAllHolidays} from "../../../shared/api/GetFormData";
import Holiday from "../../../models/Holiday";
import {Calendar} from "primereact/calendar";
import {InputTextarea} from "primereact/inputtextarea";
import Employee from "../../../models/Employee";
import moment from "moment";
import {
    absenceRequestApprovingAdmin,
    absenceRequestCancellationAdmin,
    absenceRequestDenyingAdmin
} from "../../../shared/api/endpoints/CustomApi";
import FormFooterOnAbsenceRequest
    from "../../../shared/components/generic/form-inside-table/FormFooterOnAbsenceRequest";
import {isDateBeforeToday} from "../../../shared/utils/DateUtils";

interface AbsenceRequestTemplateProps {
    entity: AbsenceRequest;

    handleDataFromChild(e: any, action: string): void;
}

function AbsenceRequestTemplate(props: AbsenceRequestTemplateProps) {
    const [dates, setDates] = useState<Date[] | undefined>();
    const dateSetup = (date: Date[]) => {
        if (date) {
            setDates(date);
        }
    }
    const [absenceRequest, setAbsenceRequest] = useState(makeAbsenceRequest(props.entity ?? undefined))
    const [requestStatus, setRequestStatus] = useState<AbsenceRequestStatusType>();
    const [employees, setEmployees] = useState<Employee[]>();
    const [absenceRequestStatusTypes, setAbsenceRequestStatusTypes] = useState<AbsenceRequestStatusType[]>();
    const [holidays, setHolidays] = useState<Holiday[]>();

    useEffect(() => {
        getAllAbsenceRequestStatusTypes().then((res: AbsenceRequestStatusType[]) => {
            setAbsenceRequestStatusTypes(res);
        });
        getAllEmployees().then((res: Employee[]) => {
            setEmployees(res);
        });
        getAllHolidays().then((res: Holiday[]) => {
            setHolidays(res);
        });
        if (props.entity.dateFromToShowOnForm && props.entity.dateToToShowOnForm) {
            dateSetup([props.entity.dateFromToShowOnForm, props.entity.dateToToShowOnForm])
        } else if (props.entity.dateFromToShowOnForm) {
            dateSetup([props.entity.dateFromToShowOnForm])
        }
    }, [])

    const formik = useFormik({
        initialValues: absenceRequest!,
        validate: (values: AbsenceRequest) => {
            let errors: FormikErrors<AbsenceRequest> = {};

            if (!values.absenceType) {
                errors.absenceType = 'Tip zahtjeva je obavezno polje.';
            }
            if (!values.dateFrom) {
                errors.dateFrom = 'Datum od do je obavezno polje.';
            } else if (!values.dateTo) {
                errors.dateTo = 'Datum od do je obavezno polje.';
            }

            return errors;
        },

        onSubmit:
            async values => {
                values = revertAbsenceRequest(values);

                if (absenceRequest.id) {
                    await edit(`ego/admin/absenceRequest`, absenceRequest.id, values).then(res => res?.status === 200 ? props.handleDataFromChild(makeAbsenceRequest(values), 'edit') : undefined);
                }
            },
    });

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    async function denyAbsenceRequestFromAdmin() {
        await absenceRequestDenyingAdmin(absenceRequest.id)?.then(res => res?.status === 200 ? props.handleDataFromChild(makeAbsenceRequest(res.data), 'edit') : undefined);
    }

    async function approveAbsenceRequestFromAdmin() {
        await absenceRequestApprovingAdmin(absenceRequest.id)?.then(res => res?.status === 200 ? props.handleDataFromChild(makeAbsenceRequest(res.data), 'edit') : undefined);
    }

    async function cancelAbsenceRequestFromAdmin() {
        await absenceRequestCancellationAdmin(absenceRequest.id)?.then(res => res?.status === 200 ? props.handleDataFromChild(makeAbsenceRequest(res.data), 'edit') : undefined);
    }

    function canICancel() {
        return (formik.values.absenceRequestStatusType &&
            formik.values.absenceRequestStatusType.enumName === AbsenceRequestStatusType_CANCEL.enumName) ||
            (formik.values.accountingSubmissionDateToShowOnForm !== undefined &&
            isDateBeforeToday(formik.values.accountingSubmissionDateToShowOnForm))
    }

    return (
        <div className="expanded-div">
            <form>
                <div>
                    <div style={{border: "transparent"}}>
                        <div className="p-fluid grid" style={{paddingTop: '20px'}}>

                            <div className="field col-2">
                            <span className="p-float-label">
                                <InputText id="absenceType" name="absenceTypeName"
                                           readOnly={true}
                                           value={formik.values.absenceType?.name}/>
                                <label htmlFor="absenceType">Tip zahtjeva</label>
                            </span>
                                {getFormErrorMessage('absenceType')}
                            </div>

                            <div className="field col-2">
                            <span className="p-float-label">
                                <InputText id="employee" name="employee"
                                           readOnly={true} value={formik.values.employee?.displayName}/>
                                <label htmlFor="employee">Zaposlenik</label>
                            </span>
                            </div>

                            <div className="field col-3">
                                <span className="p-float-label">
                                    <InputText id="absenceRequestStatusType"
                                               name="absenceRequestStatusType"
                                               value={formik.values.absenceRequestStatusType?.name}
                                               style={{
                                                   textAlign: "center",
                                                   border: "transparent",
                                                   boxShadow: "none"
                                               }}
                                               readOnly={true}></InputText>
                                    <label htmlFor="absenceRequestStatusType">Status zahtjeva</label>
                                    </span>
                            </div>

                            <div className="field col-2">
                            <span className="p-float-label">
                                <Calendar showButtonBar name="calendar" value={dates}
                                          dateFormat="dd.mm.yy." selectionMode="range" id="calendar"
                                          dateTemplate={(e) => dateTemplate(e, holidays)}
                                          disabledDays={[0, 6]}
                                          readOnlyInput={true}
                                          minDate={moment().startOf('month').toDate()}/>
                                <label htmlFor="calendar"
                                       className={classNames({'p-error': isFormFieldValid('calendar')})}>Datum od do</label>
                            </span>
                                {getFormErrorMessage('dateFrom')}
                                {getFormErrorMessage('dateTo')}

                            </div>

                            <div className="field col-3">
                                {formik.values.absenceType && formik.values.absenceType.id === AbsenceType_Vacation.id &&
                                formik.values.absenceRequestStatusType && formik.values.absenceRequestStatusType.id === 2 ?
                                    <>
                                        <InputText readOnly={true}
                                                   style={{
                                                       border: "transparent",
                                                       boxShadow: "none",
                                                       background: "transparent",
                                                       textAlign: "end",
                                                       padding: 0
                                                   }}
                                                   value={"Broj iskorištenih dana: " + (formik.values.usedDays?.toString().length === 1 ? ' ' : '') + formik.values.usedDays?.toString()}/>

                                        {formik.values.year !== moment(new Date()).year() ??
                                            <InputText readOnly={true} style={{
                                                border: "transparent",
                                                boxShadow: "none",
                                                background: "transparent",
                                                textAlign: "end",
                                                padding: 0
                                            }}
                                                       value={"Preostali broj dana godišnjeg za " + moment(new Date()).year() + ": " + (formik.values.employee?.currentYearRemainingDays?.toString().length === 1 ? ' ' : '') + formik.values.employee?.currentYearRemainingDays?.toString()}/>
                                        }
                                    </>
                                    :
                                    <>
                                        <InputText readOnly={true}
                                                   style={{
                                                       border: "transparent",
                                                       boxShadow: "none",
                                                       background: "transparent",
                                                       textAlign: "end"
                                                   }}
                                                   value={"Broj iskorištenih dana: " + (formik.values.usedDays?.toString().length === 1 ? ' ' : '') + formik.values.usedDays?.toString()}/>
                                    </>
                                }
                            </div>

                            <div className="field col-12">
                            <span className="p-float-label">
                                <InputTextarea id="textarea" value={formik.values.comment} rows={3}
                                               onChange={formik.handleChange} name="comment"/>
                                <label htmlFor="textarea">Poruka</label>
                            </span>
                            </div>

                            {formik.values.absenceRequestStatusType && formik.values.absenceRequestStatusType.enumName === AbsenceRequestStatusType_CANCEL.enumName &&
                                <div className="field col-12">
                                    <div className="flex justify-content-center">
                                        {formik.values.absenceType ?
                                            formik.values.absenceType.id === AbsenceType_Vacation.id ?
                                                formik.values.absenceType?.name + ' je otkazan.'
                                                :
                                                formik.values.absenceType.id === AbsenceType_SickLeave.id ?
                                                    formik.values.absenceType?.name + ' je otkazano.'
                                                    :
                                                    formik.values.absenceType?.name + ' je otkazana.'
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <FormFooterOnAbsenceRequest onClickDeny={() => denyAbsenceRequestFromAdmin()}
                                                onClickClose={() => {
                                                }}
                                                onClickApprove={() => approveAbsenceRequestFromAdmin()}
                                                onClickCancel={() => cancelAbsenceRequestFromAdmin()}
                                                isDisabledOnApprove={formik.values.absenceRequestStatusType &&
                                                    (formik.values.absenceRequestStatusType.enumName === AbsenceRequestStatusType_CANCEL.enumName || formik.values.absenceRequestStatusType.enumName === AbsenceRequestStatusType_APPROVED.enumName || formik.values.absenceRequestStatusType?.enumName === AbsenceRequestStatusType_DENIED.enumName)}
                                                isDisabledOnCancel={canICancel()}
                                                isDisabledOnDeny={formik.values.absenceRequestStatusType &&
                                                    (formik.values.absenceRequestStatusType.enumName === AbsenceRequestStatusType_CANCEL.enumName || formik.values.absenceRequestStatusType?.enumName === AbsenceRequestStatusType_DENIED.enumName || formik.values.absenceRequestStatusType.enumName === AbsenceRequestStatusType_APPROVED.enumName)}
                    />
                </div>
            </form>
        </div>
    );
}

export default AbsenceRequestTemplate;