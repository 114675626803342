import React, {useEffect, useState} from "react";
import {FormikErrors, useFormik} from "formik";
import {edit} from "../../../shared/api/endpoints/CrudApi";
import TimeSheet, {makeTimeSheet, revertTimeSheet} from "../../../models/TimeSheet";
import {getAllHolidays, getAllProjectsForUser} from "../../../shared/api/GetFormData";
import Holiday from "../../../models/Holiday";
import Project from "../../../models/Project";
import {Dropdown} from "primereact/dropdown";
import {Calendar} from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";
import {InputTextarea} from "primereact/inputtextarea";
import TimeSheetType, {getAllTimeSheetTypes} from "../../../models/TimeSheetType";
import {classNames} from "primereact/utils";
import FormFooter from "../../../shared/components/generic/form-inside-table/FormFooter";
import {deleteEntity} from "../../../shared/components/generic/form-inside-table/GenericMethods";
import { InputText } from "primereact/inputtext";

interface MyTimeSheetTemplateProps {
    entity: TimeSheet;
    handleDataFromChild(e: any, action: string): void;
}

function MyTimeSheetTemplate(props: MyTimeSheetTemplateProps) {
    const [timeSheet, setTimeSheet] = useState<TimeSheet>(makeTimeSheet(props.entity ?? undefined))
    const [projects, setProjects] = useState<Project[]>();

    const [type, setType] = useState<TimeSheetType>();
    const [types, setTypes] = useState<TimeSheetType[]>();

    const [holidays, setHolidays] = useState<Holiday[]>();

    const formik = useFormik({
        initialValues: timeSheet!,
        validate: (values: TimeSheet) => {
            let errors: FormikErrors<TimeSheet> = {};
            if (!values.dateToShowOnForm) {
                errors.dateToShowOnForm = "Datum je obavezno polje."
            }
            if (values.endDate) {
                if (!values.date) {
                    errors.endDate = "Nužno je izabrati oba datuma."
                }
            }
            if (!values.project) {
                errors.project = "Projekt je obavezno polje."
            }
            if (!values.type) {
                errors.type = "Tip je obavezno polje."
            }
            if (!values.numberOfHours) {
                errors.numberOfHours = "Broj sati je obavezno polje."
            }

            return errors;
        },

        onSubmit:
            async values => {
                values = revertTimeSheet(values);
                if (values.id) {
                    await edit(`erv/user/timeSheet`, values.id, values).then(res => res?.status === 200 ? props.handleDataFromChild(values, 'edit') : undefined);
                }
            },
    });


    useEffect(() => {
        getAllProjectsForUser().then((res: Project[]) => {
            setProjects(res);
        });
        getAllTimeSheetTypes().then((res: TimeSheetType[]) => {
            setTypes(res);
        });
        getAllHolidays().then((res: Holiday[]) => {
            setHolidays(res);
        });
    }, [])

    useEffect(() => {
        if (formik.values.project && !formik.values.project.canAddMultipleTimeSheets) {
            formik.setFieldValue("date", new Date())
        }
    }, [formik.values.project])

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    const handleValueChange = (e: any) => {
        const value = e.target.value;
        if (value % 0.5 !== 0) {
            e.target.value = Math.round(e.target.value / 0.5) * 0.5;
            formik.setFieldValue('numberOfHours', e.target.value);
        } else {
            formik.setFieldValue('numberOfHours', e.target.value);
        }
    };

    async function deleteTimeSheet() {
        await deleteEntity(`erv/user/timeSheet`, formik.values).then(res => res ? props.handleDataFromChild(formik.values, 'delete') : undefined);
    }

    return (
        <div className="expanded-div">
            <form>
                <div>
                    <div style={{border: "transparent"}}>
                        <div className="p-fluid grid" style={{paddingTop: '20px'}}>
                            <div className="field col-3">
                            <span className="p-float-label">
                                <InputText id="project"  name="name"
                                                    value={formik.values.project?.name}
                                                    disabled={true}
                                                    />
                                <label htmlFor="project"
                                       className={classNames({'p-error': isFormFieldValid('project')})}>Projekt</label>
                            </span>
                                {getFormErrorMessage('project')}
                            </div>
                            <div className="field col-2 col-offset-3">
                            <span className="p-float-label">
                                <Dropdown id="type" optionLabel="name" name="name"
                                          value={formik.values.type}
                                          options={types}
                                          onChange={(e) => {
                                              setType(e.value);
                                              formik.setFieldValue('type', e.value);
                                          }}/>
                                <label htmlFor="type">Tip</label>
                            </span>
                                {getFormErrorMessage('type')}
                            </div>
                            <div className="field col-2">
                                    <span className="p-float-label">
                                        <Calendar showButtonBar name="dateToShowOnForm"
                                                    value={formik.values.dateToShowOnForm}
                                                    onChange={formik.handleChange}
                                                    dateFormat="dd.mm.yy." id="calendarFrom"
                                                    disabledDays={[0, 6]}/>
                                        <label htmlFor="calendarFrom"
                                                className={classNames({'p-error': isFormFieldValid('dateToShowOnForm')})}>Datum</label>
                                    </span>
                                {getFormErrorMessage('dateToShowOnForm')}
                            </div>
                            <div className="field col-1"/>
                            <div className="field col-1">
                            <span className="p-float-label">
                                <InputNumber id="numberOfHours"
                                             value={formik.values.numberOfHours}
                                             onValueChange={handleValueChange}
                                             maxFractionDigits={1}
                                             step={0.5}
                                             min={0} max={24}
                                />
                                <label htmlFor="numberOfHours"
                                       className={classNames({'p-error': isFormFieldValid('numberOfHours')})}>Broj sati</label>
                            </span>
                                {getFormErrorMessage('numberOfHours')}

                            </div>
                            <div className="field col-12 md:col-12">
                            <span className="p-float-label">
                                <InputTextarea id="activity" value={formik.values.activity}
                                               onChange={formik.handleChange}/>
                                <label htmlFor="activity">Opis aktivnosti</label>
                            </span>
                            </div>
                        </div>
                    </div>

                    <FormFooter onClickUpdate={formik.handleSubmit}
                                onClickDelete={deleteTimeSheet}/>
                </div>
            </form>
        </div>
    );
}

export default MyTimeSheetTemplate;