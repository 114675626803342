import Project from "./Project";
import Journal from "./Journal";

export default class JournalProjectTimeSheet {

    id?: number;
    journalId?: number;
    journalName?: string;
    projectId?: number;
    validFrom?: string;
    validTo?: string;
    isEditable?: boolean;
    scheduledHours?: number;
    loggedHours?: number;
    remainingHours?: number;


    constructor(id?: number, journalId?: number, journalName?: string, projectId?: number, validFrom?: string, validTo?: string, isEditable?: boolean, scheduledHours?: number, loggedHours?: number, remainingHours?: number) {
        this.id = id ?? undefined;
        this.journalId = journalId ?? undefined;
        this.journalName = journalName ?? '';
        this.projectId = projectId ?? undefined;
        this.validFrom = validFrom ?? '';
        this.validTo = validTo ?? '';
        this.isEditable = isEditable ?? false;
        this.scheduledHours = scheduledHours ?? undefined;
        this.loggedHours = loggedHours ?? undefined;
        this.remainingHours = remainingHours ?? undefined;
    }

}

export function makeJournalProjectTimeSheet(journalProjectTimeSheet: JournalProjectTimeSheet | undefined) {
    if (journalProjectTimeSheet === undefined) {
        return new JournalProjectTimeSheet();
    }

    if(journalProjectTimeSheet.loggedHours === null && journalProjectTimeSheet.remainingHours === null){
        journalProjectTimeSheet.loggedHours = 0.0;
        journalProjectTimeSheet.remainingHours = journalProjectTimeSheet.scheduledHours ?? 0.0;
    }
    if(journalProjectTimeSheet.scheduledHours === null){
        journalProjectTimeSheet.scheduledHours = 0.0;
    }

    return journalProjectTimeSheet;
}

export function makeJournalProjectTimesheetFromProjectAndJournal(project: Project | undefined, journal: Journal | undefined) {
    let journalProjectTimeSheet: JournalProjectTimeSheet = new JournalProjectTimeSheet(
        undefined,
        journal?.id,
        journal?.name,
        project?.id,
        journal?.validFrom,
        journal?.validTo,
        true,
        journal?.scheduledHours,
        journal?.loggedHours,
        journal?.remainingHours
    );
    
    if(journal != null && journal.loggedHours === null && journal.remainingHours === null){
        journal.loggedHours = 0;
        journal.remainingHours = journal.scheduledHours ?? 0.0;
    }
    if(journal != null && journal.scheduledHours === null){
        journal.scheduledHours = 0;
    }

    return journalProjectTimeSheet;
}
