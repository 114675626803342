import React, {useState} from "react";
import GenericTable, {TableOptions} from "../../../shared/components/generic/table/GenericTable";
import GenericDialog from "../../../shared/components/generic/dialog/GenericDialog";
import {getAllJournals} from "../../../shared/api/GetFormData";
import JournalsForm from "./JournalsForm";
import Journal from "../../../models/Journal";
import JournalsTemplate from "./JournalsTemplate";

const JournalList = () => {

    const [visibilityNewRequest, setVisibilityNewRequest] = useState(false);
    const [initialValues, setInitialValues] = useState<any | undefined>(undefined);
    const [dataFromChildCreate, setDataFromChildCreate] = useState<any | undefined>(undefined);
    const [visibilityColumnSelectionModal, setVisibilityColumnSelectionModal] = useState<boolean>(false);
    const [initialValuesColumnSelection, setInitialValuesColumnSelection] = useState<any | undefined>(undefined);

    const onHideColumnSelectionModal = () => {
        if (visibilityColumnSelectionModal) {
            setVisibilityColumnSelectionModal(false);
        }
    };

    const onHideNewRequest = () => {
        if (visibilityNewRequest) {
            setVisibilityNewRequest(false);
        }
    };

    const journalTableOptions = {
        options: {
            title: "Pregled narudžbenica",
            toFetch: [getAllJournals()],
            columns: {
                headers: ["Ime", "Ugovoreni sati","Uneseni sati","Preostali sati", "Vrijedi od", "Vrijedo do", "Tip", "Aktivan"],
                fieldNames: ["name", "scheduledHours","loggedHours","remainingHours", "validFrom", "validTo", "journalType.name","active"],
                fieldTypes: ["string", "numeric","numeric","numeric", "string", "string", "string","boolean"],
                searchByFieldNames: ["name", "scheduledHours", "validFrom", "validTo", "journalType"]
            },
            isDataMutable: true,
            expansionTemplate: (params: {e: Journal, handleDataFromChild: () => void}) => <JournalsTemplate entity={params.e} handleDataFromChild={params.handleDataFromChild}/>,
            modalOptions: {
                modals: [
                    <GenericDialog key="modal-new" headerText="Nova narudžbenica" onHide={onHideNewRequest}
                                   visible={visibilityNewRequest}
                                   hasFooter={false}
                                   children={<JournalsForm hide={onHideNewRequest} initialValues={initialValues} addNewDataToTable={setDataFromChildCreate}
                                                                />}/>,
                ],
                hideVariables: [
                    visibilityNewRequest,
                ],
                hideSetters: [
                    setVisibilityNewRequest,
                ],
                hideFunctions: [
                    onHideNewRequest,
                ],
                initialValues: {
                    initialValues: initialValues,
                    setInitialValues: setInitialValues
                },
                newValue: dataFromChildCreate
            },
            columnSelection:{
                headers: ["Ime narudžbenice", "Ugovoreni sati","Uneseni sati","Preostali sati", "Vrijedi od", "Vrijedo do", "Tip", "Aktivan"],
                fieldNames: ["name", "scheduledHours","loggedHours","remainingHours", "validFrom", "validTo", "journalType.name","active"],
                hideVariables:visibilityColumnSelectionModal,
                hideSetters:setVisibilityColumnSelectionModal,
                hideFunctions:onHideColumnSelectionModal,
                initialValues:{
                    initialValues:initialValuesColumnSelection,
                    setInitialValues:setInitialValuesColumnSelection
                }
            }
        }
    } as TableOptions;

    return (
        <div>
            <GenericTable options={journalTableOptions.options}></GenericTable>
        </div>
    );
}

export default JournalList;