import React, {useState} from "react";
import {Card} from "primereact/card";
import GenericExport, {GenericExcelProp} from "./generic/GenericExport";
import EricssonExport from "./ericsson/EricssonExport";

interface ITimeSheetFormProps {
    hide: () => void
}

function ExportToExcel(props: ITimeSheetFormProps) {
    const [isGeneric, setIsGeneric] = useState<boolean>(false);
    const [isEricsson, setIsEricsson] = useState<boolean>(false);

    const genericExcelConfiguration: GenericExcelProp[] = [
        {key: "datum", value: "Datum"},
        {key: "imeProjekta", value: "Ime projekta"},
        {key: "zaposlenik", value: "Ime i prezime zaposlenika"},
        {key: "brojSati", value: "Broj sati"},
        {key: "opis", value: "Opis"}
    ];

    return (
        <>
            {!isGeneric && !isEricsson ?
                <div className="flex flex-row justify-content-around">
                    <>
                        <Card onClick={() => setIsEricsson(true)}>
                            <div
                                className="flex justify-content-center  align-content-center align-items-center w-15rem h-15rem">
                                Ericsson template
                            </div>
                        </Card>
                        <Card onClick={() => setIsGeneric(true)}>
                            <div
                                className="flex justify-content-center  align-content-center align-items-center w-15rem h-15rem">
                                Generički template
                            </div>
                        </Card>
                    </>
                </div>
                :
                (isGeneric ?
                    <GenericExport hide={props.hide} goBack={() => setIsGeneric(false)}
                                   url="admin/excel/generic/download"
                                   values={genericExcelConfiguration}></GenericExport>
                    :
                    <EricssonExport hide={props.hide} goBack={() => setIsEricsson(false)}></EricssonExport>)
            }
        </>
    );
}

export default ExportToExcel;