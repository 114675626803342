import SummarizationTimeSheet from "./SummarizationTimeSheet";

export default class PivotTableDashboard {
    employeeId?:number
    pivotTableDashboardData?:string
    name?:string
    dashboardVisibilityTypeId?:number

    constructor(employeeId?:number,pivotTableDashboardData?:string,name?:string,dashboardVisibilityTypeId?:number) {
        this.employeeId = employeeId;
        this.pivotTableDashboardData = pivotTableDashboardData;
        this.name = name;
        this.dashboardVisibilityTypeId = dashboardVisibilityTypeId;
    }
}

export function makePivotTableDashboard(pivotTableDashboard: PivotTableDashboard | undefined) {
    if(pivotTableDashboard === undefined) {
        return new PivotTableDashboard();
    }
    return pivotTableDashboard;
}