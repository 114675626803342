import {convertFromDate, convertToDate} from "../shared/utils/DateUtils";
import ContactPerson, {makeContactPerson} from "./ContactPerson";
import Client, {makeClient} from "./Client";
import Employee, {makeEmployee, revertEmployee} from "./Employee";
import moment from "moment";

export default class Project {
    id?: number;
    name?: string;
    type?: string;
    clientToShip?: Client;
    clientToBill?: Client;
    startDate?: string;
    endDate?: string;
    contactPerson?: ContactPerson;
    startDateToShowOnForm?: Date;
    endDateToShowOnForm?: Date;
    clientToBillName?: string;
    clientToShipName?: string;
    contactPersonName?: string;
    employees?: Employee[];
    canAddMultipleTimeSheets?: boolean;
    isActive?: boolean;


    constructor(id?: number, name?: string, type?: string, clientToShip?: Client, clientToBill?: Client, startDate?: string, endDate?: string, contactPerson?: ContactPerson, startDateToShowOnForm?: Date, endDateToShowOnForm?: Date, clientName?: string, employees?: Employee[], canAddMultipleTimeSheets?: boolean) {
        this.id = id ?? undefined;
        this.name = name ?? undefined;
        this.type = type ?? undefined;
        this.clientToShip = clientToShip ?? undefined;
        this.clientToBill = clientToBill ?? undefined;
        this.startDate = startDate ?? undefined;
        this.endDate = endDate ?? undefined;
        this.contactPerson = contactPerson ?? undefined;
        this.startDateToShowOnForm = startDateToShowOnForm ?? undefined;
        this.endDateToShowOnForm = endDateToShowOnForm ?? undefined;
        this.clientToBillName = clientName ?? undefined;
        this.employees = employees ?? undefined;
        this.canAddMultipleTimeSheets = canAddMultipleTimeSheets ?? false;
        this.isActive = true;
    }
}

export function makeProject(project: Project | undefined) {
    if (project === undefined) {
        return new Project();
    }

    if (project.contactPerson) {
        project.contactPerson = makeContactPerson(project.contactPerson);
        project.contactPersonName = project.contactPerson.name;
    }

    if (project.clientToShip) {
        project.clientToShip = makeClient(project.clientToShip);
        project.clientToShipName = project.clientToShip.name;
    }

    if (project.clientToBill) {
        project.clientToBill = makeClient(project.clientToBill);
        project.clientToBillName = project.clientToBill.name;
    }

    if (project.employees) {
        project.employees = project.employees.map((x) => {
            return makeEmployee(x);
        });
    }

    if (!project.type) {
        project.type = '';
    }

    project.startDateToShowOnForm = convertToDate(project.startDate);
    project.endDateToShowOnForm = convertToDate(project.endDate);

    project.isActive = !(project.endDateToShowOnForm && moment(project.endDateToShowOnForm).isBefore(new Date()));

    return project;
}

export function makeProjects(projects: Project[] | undefined) {
    if (projects === undefined) {
        return new Array<Project>();
    }
    let size = projects.length;
    let list: Project[] = new Array(size);
    for (let i = 0; i < size; i++) {
        list[i] = makeProject(projects[i]);
    }
    return list;
}

export function revertProject(project: Project) {
    project.startDate = convertFromDate(project.startDateToShowOnForm);

    project.endDate = convertFromDate(project.endDateToShowOnForm);

    project.employees = project.employees?.map(x => revertEmployee(x));

    return project;
}