import SummarizationYear from './SummarizationYear';

export default class SummarizationEmployee {
    firstName?: string;
    lastName?: string;
    email?: string;
    employeedFrom?: Date;
    employeeNumber?: number;
    years?: SummarizationYear[];
    constructor(firstName?: string, lastName?: string, email?: string, employeedFrom?: Date, employeeNumber?: number, years?: SummarizationYear[]) {
        this.firstName = firstName ?? undefined;
        this.lastName = lastName ?? undefined;
        this.email = email ?? undefined;
        this.employeedFrom = employeedFrom ?? undefined;
        this.employeeNumber = employeeNumber ?? undefined;
        this.years = years ?? undefined;
    }
}
export function makeSummarizationEmployee(summarizationEmployee: SummarizationEmployee | undefined) {
    if(summarizationEmployee === undefined) {
        return new SummarizationEmployee();
    }
    return summarizationEmployee;
}